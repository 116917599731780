import { vfsRoutes } from "@/constants/routes.constants";
import { BaseLayout } from "./Layout/BaseLayout";
import PlanChangeBanner from "../Components/Banners/PlanChangeBanner";
import { BlockStack, Button, InlineStack, Spinner } from "@shopify/polaris";
import { analyticsEventStore, billingStore, shopStore } from "@/stores";
import CustomPlanAlertBanner from "../Components/Banners/CustomPlanBanner";
import CurrentPlanCard from "../Components/SubscriptionPage/CurrentPlanCard";
import PopclipsPlanCard from "../Components/SubscriptionPage/PopclipsPlanCard";
import MobileAppPlanCard from "../Components/SubscriptionPage/MobileAppPlanCard";
import LiveSellingPlanCard from "../Components/SubscriptionPage/LiveSellingPlanCard";
import '../../assets/css/SubscriptionPage.scss';
import { useSnapshot } from "valtio";
import { useEffect, useState } from "react";
import PlanDiscountInput from "../Components/PlanSelection/PlanDiscountInput";
import { useNavigate } from "react-router-dom";
import MobileAppPlanModal from "../Components/SubscriptionPage/MobileAppPlanModal";
import PopclipsPlanModal from "../Components/SubscriptionPage/PopclipsPlanModal";
import LiveSellingPlanModal from "../Components/SubscriptionPage/LiveSellingPlanModal";
import PlanConfirmationModal from "../Components/SubscriptionPage/PlanConfirmationModal";
import AlertBannerComponent from "../Components/AlertBannerComponent";
import { useStorefrontGraphQL } from "@/hooks/useStorefrontGraphQL";

type SelectPlanState = {
    planId: string;
    type: string;
    skuCode: string;
} | undefined;

type DataToRender = {
    mobile: any[];
    popclips: any[];
    live: any[]
} | undefined;

export default function SubscriptionPage() {
    const navigate = useNavigate();
    const { makeQuery } = useStorefrontGraphQL();
    const { shouldShowFeatureCards } = useSnapshot(billingStore);
    const { activePlan, subscriptionActive, subscription, ownerName } = useSnapshot(shopStore);

    const [isApiLoading, setIsApiLoading] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showPlanModal, setShowPlanModal] = useState('');
    const [shouldShowDiscountInput, setShouldShowDiscountInput] = useState(false);
    const [dataToRender, setDataToRender] = useState<DataToRender>();

    const [selectedPopclipsPlan, setSelectedPopclipsPlan] = useState<SelectPlanState>();
    const [selectedMobilePlan, setSelectedMobilePlan] = useState<SelectPlanState>();
    const [selectedLivePlan, setSelectedLivePlan] = useState<SelectPlanState>();
    const [hasModifiedPlan, setHasModifiedPlan] = useState(false);

    useEffect(() => {
        billingStore.removeAppliedDiscountCode();
        billingStore.featureHasAddon = [];
        billingStore.selectedAddonsIds = [];
        billingStore.selectedAddonsIdsForEvent = [];
    }, []);

    useEffect(() => {
        getDataToRender();
    }, [subscription]);

    const hasSelectedAddon =
        hasModifiedPlan && (
            selectedPopclipsPlan ||
            selectedMobilePlan ||
            selectedLivePlan
        );

    const addAddonsToStore = () => {
        const selectedPlanIds = [];
        const selectedPlanIdsForEvent = [];

        if (
            selectedPopclipsPlan &&
            !billingStore.isAddonAlreadyInBasePlan(selectedPopclipsPlan.skuCode)
        ) {
            selectedPlanIds.push(selectedPopclipsPlan.planId);
        }
        if (
            selectedMobilePlan &&
            !billingStore.isAddonAlreadyInBasePlan(selectedMobilePlan.skuCode)
        ) {
            selectedPlanIds.push(selectedMobilePlan.planId);
        }
        if (
            selectedLivePlan &&
            !billingStore.isAddonAlreadyInBasePlan(selectedLivePlan.skuCode)
        ) {
            selectedPlanIds.push(selectedLivePlan.planId);
        }

        if (selectedPlanIds.length === 0) {
            shopify.toast.show('Please select at least one plan.', {
                isError: true,
                duration: 5000,
            });
            return false;
        }

        if (selectedPopclipsPlan) selectedPlanIdsForEvent.push(selectedPopclipsPlan.planId);
        if (selectedMobilePlan) selectedPlanIdsForEvent.push(selectedMobilePlan.planId);
        if (selectedLivePlan) selectedPlanIdsForEvent.push(selectedLivePlan.planId);

        billingStore.selectedAddonsIdsForEvent = selectedPlanIdsForEvent;
        billingStore.selectedAddonsIds = selectedPlanIds;
        return true;
    }

    const onCheckOutClicked = async () => {
        try {
            if (!addAddonsToStore()) return;

            setIsApiLoading(true);
            analyticsEventStore.accountConfirmSubscriptionPlan('Checkout', '//*[@id="app"]/div[1]/div/div/div[1]/div/div[1]/div/div/div/div[2]/div/div[2]/button');
            const response = await billingStore.getPlanSubscription();
            setIsApiLoading(false);
            response && setShowConfirmationModal(true);
        } catch (error) {
            setIsApiLoading(false);
        }
    };

    const onConfirmCheckout = async () => {
        setIsApiLoading(true);
        await analyticsEventStore.accountCheckoutSubscriptionPlan('UPGRADE_SPONTANEOUS', 'Checkout', '//*[@id="app"]/div[1]/div/div/div[1]/div/div[2]/div/div[3]/div/div[3]/button');
        const response = await billingStore.getPlanPurchaseUrl();

        if (response) {
            setIsApiLoading(false);
            if (response.confirmationUrl) {
                open(decodeURIComponent(response.confirmationUrl), '_top');
            } else if (response.subscription.isStubSubscription) {
                await shopStore.getShop(0, makeQuery);
                navigate(vfsRoutes.landing);
            }
        } else {
            setIsApiLoading(false);
            shopify.toast.show('Failed to checkout. Please contact support.', {
                isError: true,
                duration: 5000,
            });
        }
    };
    
    const removeIncludedAddonWhenHasExtra = (includedAddons, extraAddons) => {
        return includedAddons.filter((includedAddon) => {
            return !extraAddons.some(
                (extraAddon) => extraAddon.type === includedAddon.type,
            );
        });
    };
    
    const getDataToRender = () => {
        try {
            const subData: any = subscription;
            if (!subData && subData.lineItems) throw new Error('Subscription data not found');

            const plan = subData.lineItems.filter(({ type }: any) => type === 'plan')[0].item;
            const includedAddons = plan.addons && plan.addons.length > 0 ? billingStore.getFormattedAddonsForCart(plan.addons) : [];
            const extraAddons = subData.lineItems.filter((item: any) => item.type === 'addon').map((row: any) => row.item);
            const formattedExtraAddons = extraAddons.length > 0 ? billingStore.getFormattedAddonsForCart(extraAddons) : [];
            const formattedIncludedAddons =
                formattedExtraAddons.length > 0
                    ? removeIncludedAddonWhenHasExtra(
                          includedAddons,
                          formattedExtraAddons,
                      )
                    : includedAddons;

            const featureAddonData = [
                ...formattedIncludedAddons,
                ...formattedExtraAddons,
            ].reduce(
                (acc, addon) => {
                    if (addon.type == 'mobile') {
                        acc.mobile.push(addon);
                    } else if (addon.type == 'popclips') {
                        acc.popclips.push(addon);
                    } else if (addon.type == 'live') {
                        acc.live.push(addon);
                    }

                    return acc;
                },
                { mobile: [], popclips: [], live: [] },
            );

            if (featureAddonData.mobile.length > 0) {
                setSelectedMobilePlan({
                    planId: featureAddonData.mobile[0].id,
                    type: featureAddonData.mobile[0].addonName.toLowerCase(),
                    skuCode: featureAddonData.mobile[0].skuCode,
                });
            }
            if (featureAddonData.popclips.length > 0) {
                setSelectedPopclipsPlan({
                    planId: featureAddonData.popclips[0].id,
                    type: featureAddonData.popclips[0].addonName.toLowerCase() === 'free 2025' ? 'free' : featureAddonData.popclips[0].addonName.toLowerCase(),
                    skuCode: featureAddonData.popclips[0].skuCode,
                });
            }
            if (featureAddonData.live.length > 0) {
                setSelectedLivePlan({
                    planId: featureAddonData.live[0].id,
                    type: featureAddonData.live[0].addonName.toLowerCase(),
                    skuCode: featureAddonData.live[0].skuCode,
                });
            }

            setDataToRender(featureAddonData);
        } catch (error) {
            shopify.toast.show('Failed to get subscription data. Please contact support.', {
                isError: true,
                duration: 5000,
            });
            navigate(vfsRoutes.landing);
        }
    };

    const handleAddonModalClose = (planData: SelectPlanState) => {
        if (showPlanModal === 'popclips') {
            !hasModifiedPlan && setHasModifiedPlan(selectedPopclipsPlan?.planId != planData?.planId);
            setSelectedPopclipsPlan(planData);
        }
        
        if (showPlanModal === 'mobileApp') {
            !hasModifiedPlan && setHasModifiedPlan(selectedMobilePlan?.planId != planData?.planId);
            setSelectedMobilePlan(planData);
        }
        
        if (showPlanModal === 'liveSelling') {
            !hasModifiedPlan && setHasModifiedPlan(selectedLivePlan?.planId != planData?.planId);
            setSelectedLivePlan(planData);
        }

        setShowPlanModal('');
    };

    return (
        <BaseLayout
            title="Subscription"
            backToLink={vfsRoutes.landing}
            headerAction={
                <div>
                    {subscriptionActive &&
                    activePlan?.skuCode !=
                        billingStore.selectedPlanSku ? null : (
                        <InlineStack blockAlign="center" gap="600">
                            <PlanDiscountInput
                                shouldShowDiscountInput={
                                    shouldShowDiscountInput
                                }
                                isDisabled={isApiLoading}
                                toggleDiscountInput={(value) =>
                                    setShouldShowDiscountInput(value)
                                }
                            />
                            <InlineStack blockAlign="end" gap="200">
                                {isApiLoading && <Spinner size="small" />}
                                <Button
                                    variant="primary"
                                    size="large"
                                    onClick={onCheckOutClicked}
                                    disabled={
                                        !shouldShowFeatureCards ||
                                        !hasSelectedAddon ||
                                        isApiLoading
                                    }
                                >
                                    Checkout
                                </Button>
                            </InlineStack>
                        </InlineStack>
                    )}
                </div>
            }
            bodyIsLoading={!shouldShowFeatureCards}
        >
            <div style={{ marginBottom: '25px' }}>
                <PlanChangeBanner isDisabled={isApiLoading} />
                <CustomPlanAlertBanner isDisabled={isApiLoading} />
                {hasSelectedAddon && (
                    <AlertBannerComponent
                        type="info"
                        title="Confirm Plan Change"
                        message={`You recently made changes to your current plan. Please click on "Checkout" to upgrade your plan successfully.`}
                        size="large"
                    />
                )}
            </div>
            <div className="subscription-page__container">
                <p className="subscription-page__welcome-text">
                    Welcome {ownerName}, please find your plan details.
                </p>
                <BlockStack gap="1000">
                    <InlineStack align="space-between">
                        <CurrentPlanCard subscriptionData={subscription} />
                        <PopclipsPlanCard
                            planData={
                                dataToRender?.popclips.length > 0
                                    ? dataToRender?.popclips
                                    : false
                            }
                            isCustomPlan={
                                subscriptionActive &&
                                activePlan?.skuCode !=
                                    billingStore.selectedPlanSku
                            }
                            isDisabled={isApiLoading}
                            handleClick={() => setShowPlanModal('popclips')}
                            isSelected={selectedPopclipsPlan ? true : false}
                        />
                    </InlineStack>
                    <InlineStack align="space-between">
                        <MobileAppPlanCard
                            planData={
                                dataToRender?.mobile.length > 0
                                    ? dataToRender?.mobile
                                    : false
                            }
                            isCustomPlan={
                                subscriptionActive &&
                                activePlan?.skuCode !=
                                    billingStore.selectedPlanSku
                            }
                            isDisabled={isApiLoading}
                            handleClick={() => setShowPlanModal('mobileApp')}
                            isSelected={selectedMobilePlan ? true : false}
                        />
                        <LiveSellingPlanCard
                            planData={
                                dataToRender?.live.length > 0
                                    ? dataToRender?.live
                                    : false
                            }
                            isCustomPlan={
                                subscriptionActive &&
                                activePlan?.skuCode !=
                                    billingStore.selectedPlanSku
                            }
                            isDisabled={isApiLoading}
                            handleClick={() => setShowPlanModal('liveSelling')}
                            isSelected={selectedLivePlan ? true : false}
                        />
                    </InlineStack>
                </BlockStack>
            </div>
            {showPlanModal === 'popclips' && (
                <PopclipsPlanModal
                    selectedPlan={selectedPopclipsPlan}
                    onHidePlanModal={handleAddonModalClose}
                />
            )}
            {showPlanModal === 'mobileApp' && (
                <MobileAppPlanModal
                    selectedPlan={selectedMobilePlan}
                    onHidePlanModal={handleAddonModalClose}
                />
            )}
            {showPlanModal === 'liveSelling' && (
                <LiveSellingPlanModal
                    selectedPlan={selectedLivePlan}
                    onHidePlanModal={handleAddonModalClose}
                />
            )}
            {showConfirmationModal && (
                <PlanConfirmationModal
                    onConfirmCheckout={onConfirmCheckout}
                    hideModal={() => setShowConfirmationModal(false)}
                />
            )}
        </BaseLayout>
    );
}