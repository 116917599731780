import { useNavigate } from 'react-router-dom';
import VfsModal from '../../v2/Components/VfsModal';
import { vfsRoutes } from '@/constants/routes.constants';
import { BlockStack, Box, Text } from '@shopify/polaris';
import { analyticsEventStore } from '@/stores';

type PopclipViewsLimitModalProps = {
    hideModal: () => void;
    planType: string;
};
export default function PopclipViewsLimitModal({ hideModal, planType }: PopclipViewsLimitModalProps) {
    const navigate = useNavigate();

    const handleUpgradeNow = () => {
        navigate(vfsRoutes.managePlans);
        analyticsEventStore.popclipClickButtonInViewLimitModal(
            'account_react_popclipsquotareminder',
            'notification_reaction',
            'Upgrade Now',
            [
                `${
                    planType === 'free'
                        ? 'addon-popclips-basic'
                        : 'addon-popclips-premium'
                }`,
            ],
            planType === 'free' ? 29.99 : 149.99,
            `//*[@id="app"]/div[1]/div/div/div/div/div[2]/div/div[2]/div/div[3]/button[2]`,
            true,
        );
    };

    const handleCancel = () => {
        hideModal();
        analyticsEventStore.popclipClickButtonInViewLimitModal(
            'account_react_popclipsquotareminder',
            'notification_reaction',
            'Cancel',
            [
                `${
                    planType === 'free'
                        ? 'addon-popclips-basic'
                        : 'addon-popclips-premium'
                }`,
            ],
            planType === 'free' ? 29.99 : 149.99,
            '//*[@id="app"]/div[1]/div/div/div/div/div[2]/div/div[2]/div/div[3]/button[1]',
            false,
        );
    };

    return (
        <VfsModal
            title=""
            modalWidth="30rem"
            closeable={false}
            crossIcon={false}
            primaryAction={handleUpgradeNow}
            primaryActionText="Upgrade Now"
            secondaryAction={handleCancel}
            primaryActionTone='success'
            secondaryActionText="Cancel"
            header="secondary"
        >
            <Box>
                <Box paddingInline="200" paddingBlockEnd={'200'}>
                    <BlockStack inlineAlign="center" gap={'200'}>
                        <Text variant="headingLg" as="h5" alignment="center">
                            {planType === 'free'
                                ? 'Your Popclips are Taking Off! 🚀'
                                : 'Don’t Let Your Popclips Slow Down!'}
                        </Text>
                        <Text as="p" alignment="center" variant="bodyLg">
                            {planType === 'free'
                                ? 'Shoppers love your Popclips—you’re almost at your view limit! Upgrade now to keep sharing videos without interruptions and keep your audience engaged.'
                                : 'Your Popclips are driving amazing engagement, but you’re reaching the view limit for your $29.99 plan. Upgrade to share more videos, style custom carousels for each page, and import content directly from Instagram and TikTok.'}
                        </Text>
                    </BlockStack>
                </Box>
            </Box>
        </VfsModal>
    );
}
