import { useEffect, useState } from 'react';
import {
    BlockStack,
    Button,
    Icon,
    InlineStack,
    Spinner,
    Text,
} from '@shopify/polaris';
import { ArrowRightIcon } from '@shopify/polaris-icons';
import { analyticsEventStore, billingStore, shopStore } from '@/stores';
import { ATTRIBUTE_IDS } from '@/constants/planFeatureCard.constants';
import { BaseLayout } from './Layout/BaseLayout';
import { useNavigate } from 'react-router-dom';
import { vfsRoutes } from '@/constants/routes.constants';
import { useSnapshot } from 'valtio';
import AddonStyledComponent from '../Components/PlanSelection/AddonStyledComponent';
import PlanDiscountInput from '../Components/PlanSelection/PlanDiscountInput';
import '../../assets/css/SelectPopclipsAddonPage.css';
import PlanConfirmationModal from '../Components/SubscriptionPage/PlanConfirmationModal';
import { useStorefrontGraphQL } from '@/hooks/useStorefrontGraphQL';

const featureType = 'popclips';

export default function SelectPopclipsAddonPage() {
    const navigate = useNavigate();
    const { makeQuery } = useStorefrontGraphQL();
    const [selectedPlan, setSelectedPlan] = useState('');
    const [shouldShowDiscountInput, setShouldShowDiscountInput] = useState(false);
    const [isApiLoading, setIsApiLoading] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const { shouldShowFeatureCards } = useSnapshot(billingStore);

    const addons = billingStore.getAddonsByModule(featureType, ATTRIBUTE_IDS[featureType]);
    const addonsPlansValues =
        addons && addons.length > 0
            ? billingStore.getAddonButtonGroupValues(addons, ATTRIBUTE_IDS[featureType])
            : [];

    useEffect(() => {
        billingStore.selectedAddonsIds = [];
        billingStore.selectedAddonsIdsForEvent = [];
        billingStore.removeAppliedDiscountCode();
    }, []);

    const handleAddonSelection = (addon: any) => {
        billingStore.selectedAddonsIdsForEvent = [addon.id];
        billingStore.selectedAddonsIds = !billingStore.isAddonAlreadyInBasePlan(addon.skuCode) ? [addon.id] : [];
        setSelectedPlan(getPopclipsCardType(addon.name.toLowerCase()));

        const basePlan = billingStore.plans.find(({ skuCode }) => skuCode === billingStore.selectedPlanSku);
        analyticsEventStore.accountSelectSubscriptionPlan(
            addon.skuCode,
            `${basePlan.skuCode}, ${addon.skuCode}`,
            `${basePlan.price[0].amount}, ${addon.price[0].amount}`,
            `${basePlan.price[0].currencyCode}, ${addon.price[0].currencyCode}`,
        );
    }

    const onCheckOutClicked = async () => {
        try {
            setIsApiLoading(true);
            analyticsEventStore.accountConfirmSubscriptionPlan('Continue', '//*[@id="app"]/div[1]/div/div/div/div/div[1]/div/div/div/div[2]/div/div[2]/button');
            const response = await billingStore.getPlanSubscription();
            setIsApiLoading(false);
            response && setShowConfirmationModal(true);
        } catch (error) {
            setIsApiLoading(false);
        }
    };

    const onConfirmCheckout = async () => {
        setIsApiLoading(true);
        await analyticsEventStore.accountCheckoutSubscriptionPlan('FIRST_EVER', 'Checkout', '//*[@id="app"]/div[1]/div/div/div/div/div[2]/div/div[2]/div/div[3]/button');
        const response = await billingStore.getPlanPurchaseUrl();

        if (response) {
            setIsApiLoading(false);
            if (response.confirmationUrl) {
                open(decodeURIComponent(response.confirmationUrl), '_top');
            } else if (response.subscription.isStubSubscription) {
                await shopStore.getShop(0, makeQuery);
                navigate(vfsRoutes.landing);
            }
        } else {
            setIsApiLoading(false);
            shopify.toast.show('Failed to checkout. Please contact support.', {
                isError: true,
                duration: 5000,
            });
        }
    };

    const getPopclipsCardType = (type: string) => {
        switch (type) {
            case 'free':
            case 'free 2025':
                return 'free';
            case 'basic':
                return 'basic';
            case 'premium':
                return 'premium';
            default:
                return '';
        }
    };

    return (
        <BaseLayout
            title="Select Plan"
            subtitle={
                <InlineStack gap="100" blockAlign="center" wrap={false}>
                    <Text as="p" variant="bodyMd">
                    Questions? Please reach out to our Support team at 
                    </Text>
                    <div className="schedule-free-onboarding-call">
                        <a href="mailto:support@videeo.live" target="_blank" rel="noreferrer">
                        <InlineStack gap="100" blockAlign="center" wrap={false}>
                            <Text
                                as="span"
                                fontWeight="semibold"
                                tone="magic"
                            >
                                support@videeo.live
                            </Text>
                            <Icon source={ArrowRightIcon} tone="magic" />
                            </InlineStack>
                        </a>
                    </div>
                </InlineStack>
            }
            headerAction={
                <div>
                    <InlineStack blockAlign="center" gap="600">
                        <PlanDiscountInput
                            shouldShowDiscountInput={shouldShowDiscountInput}
                            toggleDiscountInput={(value) =>
                                setShouldShowDiscountInput(value)
                            }
                            isDisabled={isApiLoading}
                        />
                        <InlineStack blockAlign="end" gap="200">
                            {isApiLoading && <Spinner size="small" />}
                            <Button
                                variant="primary"
                                size="large"
                                onClick={onCheckOutClicked}
                                disabled={
                                    !shouldShowFeatureCards ||
                                    !selectedPlan ||
                                    isApiLoading
                                }
                            >
                                Continue
                            </Button>
                        </InlineStack>
                    </InlineStack>
                </div>
            }
            bodyIsLoading={!shouldShowFeatureCards}
        >
            <BlockStack gap="800">
                <div className="content card-highlight-container">
                    <div style={{ display: 'flex', gap: '2rem', width: '90%' }}>
                        {addons.map((addon, i) => (
                            <AddonStyledComponent
                                key={addon.id}
                                addonData={addon}
                                isDisabled={isApiLoading}
                                addonValue={addonsPlansValues[i].value}
                                type={getPopclipsCardType(addon.name.toLowerCase())}
                                selectedPlan={selectedPlan}
                                onSelectPlan={() => handleAddonSelection(addon)}
                            />
                        ))}
                    </div>
                </div>
            </BlockStack>
            {showConfirmationModal && (
                <PlanConfirmationModal
                    onConfirmCheckout={onConfirmCheckout}
                    hideModal={() => setShowConfirmationModal(false)}
                />
            )}
        </BaseLayout>
    );
}