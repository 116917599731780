import { BlockStack, Box, InlineStack, Text } from '@shopify/polaris';
import VfsModal from '../../v2/Components/VfsModal';
import { Dispatch, SetStateAction } from 'react';
import { vfsRoutes } from '@/constants/routes.constants';
import { useNavigate } from 'react-router-dom';
import upgradePlan from '../../assets/images/upgradePlan.svg';
import {
    CUSTOM_CAROUSAL,
    INSTAGRAM,
    TIKTOK,
} from '@/constants/planFeatureCard.constants';

type SocialMediaPlanUpdateModalProps = {
    setShowSocialPlanUpdate: Dispatch<SetStateAction<boolean>>;
    socialButtonType: string;
};
export default function SocialMediaPlanUpdateModal({
    setShowSocialPlanUpdate,
    socialButtonType,
}: SocialMediaPlanUpdateModalProps) {
    const navigate = useNavigate();

    const tikTokModalList = [
        {
            icon: '📸',
            heading: 'Instagram and TikTok Video Import - ',
            detail: 'Upload your top videos and user-generated content effortlessly.',
        },
        {
            icon: '🎠',
            heading: 'Custom Popclip Carousels - ',
            detail: 'Build and showcase carousels anywhere.',
        },
        {
            icon: '🛠️',
            heading: 'Manage Your Carousels - ',
            detail: 'Control your video displays with ease',
        },
        {
            icon: '👀',
            heading: 'Show Off Video View Counts - ',
            detail: 'Boost confidence with real engagement stats',
        },
    ];
    const instaModalList = [
        {
            icon: '🌟',
            heading: 'Instagram and TikTok Video Import - ',
            detail: 'Upload your top videos and user-generated content effortlessly.',
        },
        {
            icon: '🌟',
            heading: 'Publish Custom Carousels Anywhere - ',
            detail: 'Highlight your favorite Popclips where they matter most.',
        },
        {
            icon: '🌟',
            heading: 'Manage and Customize Your Carousels - ',
            detail: 'Your site, your vibe, your rules.',
        },
        {
            icon: '🌟',
            heading: 'Show Off Your View Counts - ',
            detail: 'Let the numbers do the talking and build trust with every view.',
        },
    ];
    const carouselModalList = [
        {
            icon: '🌟',
            heading: 'Publish Custom Carousels Anywhere - ',
            detail: 'Highlight your favorite Popclips where they matter most.',
        },
        {
            icon: '🌟',
            heading: 'Instagram Video Import - ',
            detail: 'Upload your top videos and user-generated content effortlessly.',
        },
        {
            icon: '🌟',
            heading: 'TikTok Import - ',
            detail: 'Your top TikTok videos, now on your website.',
        },
        {
            icon: '🌟',
            heading: 'Manage and Customize Your Carousels - ',
            detail: 'Your site, your vibe, your rules.',
        },
        {
            icon: '🌟',
            heading: 'Show Off Your View Counts - ',
            detail: 'Let the numbers do the talking and build trust with every view.',
        },
    ];
    const customCarouselModalList = [
        {
            icon: '📂',
            heading: 'Custom Carousels by Collection or Category -',
            detail: 'Hands-off, smart video showcases.',
        },
        {
            icon: '🛠️',
            heading: "No More 'Powered by videeo.live' -",
            detail: 'Your site, your branding.',
        },
        {
            icon: '👍',
            heading: 'Show Likes and View Counts on Popclips -',
            detail: 'Highlight the love and build instant trust.',
        },
        {
            icon: '✨',
            heading: 'Instagram and TikTok Video Import -',
            detail: 'Upload your top videos and user-generated content effortlessly.',
        },
    ];
    const handleUpgradeNow = () => {
        navigate(vfsRoutes.managePlans);
    };
    const descriptionComponent = () => {
        switch (socialButtonType) {
            case TIKTOK:
                return (
                    <Text as="p" alignment="center" variant="bodyLg">
                        Why let your TikTok videos stay on TikTok? Import your
                        favorite clips—including UGC—directly into Popclips and
                        make your website the ultimate video showcase.
                    </Text>
                );
            case INSTAGRAM:
                return (
                    <p style={{ textAlign: 'center', fontSize: '14px' }}>
                        Keep your website buzzing with{' '}
                        <span style={{ fontStyle: 'italic' }}>
                            zero extra effort
                        </span>
                        ! Our Instagram Import tool lets you pull in your top
                        Instagram videos—including UGC—so your site always looks
                        fresh, dynamic, and engaging.
                    </p>
                );
            case CUSTOM_CAROUSAL:
                return (
                    <Text as="p" alignment="center" variant="bodyLg">
                        Why spend time sorting videos manually when we can do
                        the work for you? Automatically pull clips by product
                        category or collection and wow your visitors with
                        next-level Popclips.
                    </Text>
                );

            default:
                return (
                    <Text as="p" alignment="center" variant="bodyLg">
                        Pick and choose the Popclips for your custom carousels,
                        sprinkle them across your site, and spotlight the
                        Popclips that make your products pop!
                    </Text>
                );
        }
    };
    return (
        <VfsModal
            onCloseModal={() => setShowSocialPlanUpdate(false)}
            title=""
            modalWidth="30rem"
            closeable={false}
            crossIcon={false}
            primaryAction={handleUpgradeNow}
            primaryActionText={
                socialButtonType === CUSTOM_CAROUSAL
                    ? 'Upgrade to Premium'
                    : 'Upgrade for only $29.99 / month'
            }
            primaryActionTone='success'
            secondaryAction={() => setShowSocialPlanUpdate(false)}
            secondaryActionText="Cancel"
        >
            <Box>
                <Box paddingInline="200" paddingBlockEnd={'200'}>
                    <BlockStack inlineAlign="center" gap={'200'}>
                        <img
                            alt=""
                            width="90px"
                            height="120px"
                            style={{
                                objectFit: 'cover',
                                objectPosition: 'center',
                            }}
                            src={upgradePlan}
                        />
                        <Text variant="headingLg" as="h5" alignment="center">
                            {socialButtonType === TIKTOK
                                ? 'Bring TikTok Magic to Your Website 🎥✨'
                                : socialButtonType === INSTAGRAM
                                  ? 'Bring Your Instagram Videos to Life 🌟'
                                  : socialButtonType === CUSTOM_CAROUSAL
                                    ? 'Smarter Carousels, Bigger Impact 🌟'
                                    : 'Take Your Popclips to the Next Level 🚀'}
                        </Text>
                        {descriptionComponent()}
                    </BlockStack>
                </Box>
                <Box
                    borderRadius="200"
                    background="bg-fill-info-secondary"
                    padding="200"
                >
                    {socialButtonType === INSTAGRAM && (
                        <Box paddingBlockEnd="200">
                            <Text as="p" fontWeight="medium" variant="bodyMd">
                                Upgrade today and level up with these
                                game-changing features:
                            </Text>
                        </Box>
                    )}
                    {socialButtonType === TIKTOK && (
                        <Box paddingBlockEnd="200">
                            <Text as="p" fontWeight="medium" variant="bodyMd">
                                Upgrade and unlock these game-changing features:
                            </Text>
                        </Box>
                    )}
                    {socialButtonType === CUSTOM_CAROUSAL && (
                        <Box paddingBlockEnd="200">
                            <Text as="p" fontWeight="medium" variant="bodyMd">
                                Upgrade your plan and get these additional
                                features:
                            </Text>
                        </Box>
                    )}
                    {socialButtonType === TIKTOK
                        ? tikTokModalList.map((item, index) => (
                              <Box paddingBlockEnd={'100'} key={index}>
                                  <InlineStack
                                      key={index}
                                      gap="200"
                                      blockAlign="start"
                                      wrap={false}
                                  >
                                      <Text as="p" variant="bodyMd" key={index}>
                                          {item.icon}
                                      </Text>
                                      <InlineStack
                                          key={index}
                                          gap="100"
                                          wrap={true}
                                          blockAlign="center"
                                      >
                                          <Text
                                              as="p"
                                              fontWeight="medium"
                                              variant="bodyMd"
                                              key={index}
                                          >
                                              {item.heading}
                                              <Text
                                                  fontWeight="regular"
                                                  variant="bodyMd"
                                                  as="span"
                                                  key={index}
                                              >
                                                  {item.detail}
                                              </Text>
                                          </Text>
                                      </InlineStack>
                                  </InlineStack>
                              </Box>
                          ))
                        : socialButtonType === INSTAGRAM
                          ? instaModalList.map((item, index) => (
                                <Box paddingBlockEnd={'100'} key={index}>
                                    <InlineStack
                                        key={index}
                                        gap="200"
                                        blockAlign="start"
                                        wrap={false}
                                    >
                                        <Text
                                            as="p"
                                            variant="bodyMd"
                                            key={index}
                                        >
                                            {item.icon}
                                        </Text>
                                        <InlineStack
                                            key={index}
                                            gap="100"
                                            wrap={true}
                                            blockAlign="center"
                                        >
                                            <Text
                                                as="p"
                                                fontWeight="medium"
                                                variant="bodyMd"
                                                key={index}
                                            >
                                                {item.heading}
                                                <Text
                                                    fontWeight="regular"
                                                    variant="bodyMd"
                                                    as="span"
                                                    key={index}
                                                >
                                                    {item.detail}
                                                </Text>
                                            </Text>
                                        </InlineStack>
                                    </InlineStack>
                                </Box>
                            ))
                          : socialButtonType === CUSTOM_CAROUSAL
                            ? customCarouselModalList.map((item, index) => (
                                  <Box paddingBlockEnd={'100'} key={index}>
                                      <InlineStack
                                          key={index}
                                          gap="200"
                                          blockAlign="start"
                                          wrap={false}
                                      >
                                          <Text
                                              as="p"
                                              variant="bodyMd"
                                              key={index}
                                          >
                                              {item.icon}
                                          </Text>
                                          <InlineStack
                                              key={index}
                                              gap="100"
                                              wrap={true}
                                              blockAlign="center"
                                          >
                                              <Text
                                                  as="p"
                                                  fontWeight="medium"
                                                  variant="bodyMd"
                                                  key={index}
                                              >
                                                  {item.heading}
                                                  <Text
                                                      fontWeight="regular"
                                                      variant="bodyMd"
                                                      as="span"
                                                      key={index}
                                                  >
                                                      {item.detail}
                                                  </Text>
                                              </Text>
                                          </InlineStack>
                                      </InlineStack>
                                  </Box>
                              ))
                            : carouselModalList.map((item, index) => (
                                  <Box paddingBlockEnd={'100'} key={index}>
                                      <InlineStack
                                          key={index}
                                          gap="200"
                                          blockAlign="start"
                                          wrap={false}
                                      >
                                          <Text
                                              as="p"
                                              variant="bodyMd"
                                              key={index}
                                          >
                                              {item.icon}
                                          </Text>
                                          <InlineStack
                                              key={index}
                                              gap="100"
                                              wrap={true}
                                              blockAlign="center"
                                          >
                                              <Text
                                                  as="p"
                                                  fontWeight="medium"
                                                  variant="bodyMd"
                                                  key={index}
                                              >
                                                  {item.heading}
                                                  <Text
                                                      fontWeight="regular"
                                                      variant="bodyMd"
                                                      as="span"
                                                      key={index}
                                                  >
                                                      {item.detail}
                                                  </Text>
                                              </Text>
                                          </InlineStack>
                                      </InlineStack>
                                  </Box>
                              ))}
                </Box>
            </Box>
        </VfsModal>
    );
}
