import { BrowserRouter, Link } from 'react-router-dom';
import Routes from './Routes';
import { PolarisProvider } from './components/providers/PolarisProvider';
import { NavMenu } from '@shopify/app-bridge-react';
import { buildMobileAppStore, shopStore } from './stores';
import { useEffect } from 'react';
import { useGetAppRequest } from './hooks/useGetAppRequest';
import { useSnapshot } from 'valtio';
import { PageNotFound } from './pages';

export default function App() {
    const { getAppRequests } = useGetAppRequest();
    const { iosLink, androidLink } = useSnapshot(buildMobileAppStore);
    const { enabledFeatures, featureFlags } = useSnapshot(shopStore);

    useEffect(() => {
        getAppRequests();
    }, []);

    return featureFlags?.deactivate_shop ? (
        <PageNotFound accessDenied />
    ) : (
        <PolarisProvider>
            <BrowserRouter>
                <NavMenu>
                    <Link to="/" rel="home">
                        VFS Home
                    </Link>
                    <Link to="/popclips/list">Popclips: Shoppable Videos</Link>
                    {enabledFeatures.includes('mobile') && (
                        <Link to={iosLink || androidLink ? '/mobile/notifications-banners' : '/mobile/setup'}>
                            Mobile App Builder
                        </Link>
                    )}
                    {enabledFeatures.includes('live') && (
                        <Link to="/live">Live Stream Selling</Link>
                    )}
                    <Link to="/insights">Insights</Link>
                    <Link to="/resources">Resources</Link>
                    <Link to="/manage/plans">Subscription</Link>
                </NavMenu>
                <Routes />
            </BrowserRouter>
        </PolarisProvider>
    );
}
