import { Box, Text, BlockStack, Button, InlineStack } from '@shopify/polaris';
import { useSnapshot } from 'valtio';
import { analyticsEventStore, buildMobileAppStore, shopCollectionStore } from '@/stores';

import { CollapsibleCardWithTitleSection } from '@/components/common';
import { useSendAppRequest } from '@/hooks/useSendAppRequest';
import { useUpdateAppConfig } from '@/hooks/useUpdateAppConfig';
import { useState } from 'react';

const SubmitCard = () => {
    const buildMobileAppSnapshot = useSnapshot(buildMobileAppStore, {
        sync: true,
    });
    const shopCollectionSnapshot = useSnapshot(shopCollectionStore, {
        sync: true,
    });
    const {
        dunsCompletedAt,
        iosDevCompletedAt,
        addDevEmailCompletedAt,
        appleDevTeamId,
        desiredAppName,
        desiredAppNameSecond,
        desiredAppNameThird,
        appLogoUrl,
        launchScreenImageUrl,
        supportURL,
        appLogoBgColor,
        launchScreenBgColor,
    } = buildMobileAppStore;
    const isButtonDisabled =
        dunsCompletedAt &&
        iosDevCompletedAt &&
        addDevEmailCompletedAt &&
        appleDevTeamId &&
        desiredAppName &&
        desiredAppNameSecond &&
        desiredAppNameThird &&
        appLogoUrl &&
        launchScreenImageUrl &&
        supportURL &&
        appLogoBgColor &&
        launchScreenBgColor &&
        shopCollectionSnapshot.selectedCollection
            ? false
            : true;
    const { updateAppConfig } = useUpdateAppConfig();
    const { sendAppRequest } = useSendAppRequest();
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async () => {
        if (!shopCollectionStore.selectedCollection) {
            buildMobileAppStore.setIsFeaturedCollectionCardOpen(true);
            shopify.toast.show('Please select a collection', {
                isError: true,
                duration: 5000,
            });
            return;
        }
        if (!appLogoBgColor || !launchScreenBgColor) {
            buildMobileAppStore.setIsAppIconLaunchScreenCardOpen(true);
            shopify.toast.show('Please fill out all required fields', {
                isError: true,
                duration: 5000,
            });
            return;
        } else if (
            !dunsCompletedAt ||
            !iosDevCompletedAt ||
            !addDevEmailCompletedAt
        ) {
            buildMobileAppStore.setIsIOSChecklistCardOpen(true);
            shopify.toast.show('Please complete all required steps', {
                isError: true,
                duration: 5000,
            });
            return;
        } else if (
            !appleDevTeamId ||
            !desiredAppName ||
            !desiredAppNameSecond ||
            !desiredAppNameThird
        ) {
            buildMobileAppStore.setIsAppNameCardOpen(true);
            buildMobileAppStore.hasAppleDevTeamIdErr();
            buildMobileAppStore.hasDesiredAppNameErr();
            buildMobileAppStore.hasDesiredAppNameSecondErr();
            buildMobileAppStore.hasDesiredAppNameThirdErr();
            shopify.toast.show('Please fill out all required fields',
                {
                    isError: true,
                    duration: 5000,
                },
            );
            return;
        } else if (!launchScreenImageUrl || !appLogoUrl) {
            buildMobileAppStore.setIsAppIconLaunchScreenCardOpen(true);
            shopify.toast.show('Please fill out all required fields',
                {
                    isError: true,
                    duration: 5000,
                },
            );
            return;
        } else if (!supportURL || !buildMobileAppStore.isSupportURLValid) {
            buildMobileAppStore.setIsSupportInfoCardOpen(true);
            shopify.toast.show('Please fill out all required fields',
                {
                    isError: true,
                    duration: 5000,
                },
            );
            return;
        }
        const startTime = new Date().getTime();
        try {
            if (shopCollectionStore.selectedCollection !== null) {
                setIsLoading(true)
                const response = await updateAppConfig({
                    collectionId: shopCollectionStore.selectedCollection,
                    primaryColor: appLogoBgColor,
                    bannerColor: launchScreenBgColor,
                    secondaryColor: launchScreenBgColor,
                    liveBannerColor: appLogoBgColor,
                });
                if (response?.data) {
                    shopify.toast.show('Successfully submitted the app request.', {
                        isError: false,
                        duration: 5000,
                    });
                } else {
                    shopify.toast.show('Failed to submit app request. Please try again or contact support.', {
                        isError: true,
                        duration: 5000,
                    });
                }
            }
        } catch (e) {
            console.error(e);
            shopify.toast.show(
                'Failed to submit app request. Please try again or contact support.',
                {
                    isError: true,
                    duration: 5000,
                },
            );
            return;
        }
        try {
            const response = await sendAppRequest();
            const endTime = new Date().getTime();
            const timeTaken = endTime - startTime;
            analyticsEventStore.submitMobileAppEvent({
                duration: Math.floor(timeTaken / 1000),
                ...response.data
            })
            buildMobileAppStore.isAppRequestSubmitted = true;
        } catch (e) {
            console.error(e);
            shopify.toast.show(
                'Failed to submit app request. Please try again or contact support.',
                {
                    isError: true,
                    duration: 5000,
                },
            );
        }
        setIsLoading(false)
    };
    return (
        <CollapsibleCardWithTitleSection
            title="Submit"
            subtitle="Submit your app to the app store. Your app will be ready within 2-3 weeks after submission."
            showCheck={false}
            open={buildMobileAppSnapshot.isAppSubmissionCardOpen}
            onToggleOpen={buildMobileAppStore.setIsAppSubmissionCardOpen}
            hideSubtitleWhenClosed={true}
        >
            <Box width="56rem">
                <BlockStack gap="600">
                    <Text as="h4" variant="bodySm">
                        Make sure to stop back to see progress of your app
                        approval.
                    </Text>
                    <Text as="h4" variant="bodySm">
                        Once approved you will be able to make sure your
                        customers never miss you going live again.
                    </Text>
                    <InlineStack align="start">
                        {buildMobileAppSnapshot.isAppRequestSubmitted ? (
                            <Text fontWeight="bold" tone="success">
                                Your app has been submitted
                            </Text>
                        ) : (
                            <Button
                                variant="primary"
                                size="large"
                                accessibilityLabel="Submit Your App"
                                onClick={handleSubmit}
                                disabled={isButtonDisabled}
                                loading={isLoading}
                            >
                                Submit Your App
                            </Button>
                        )}
                    </InlineStack>
                </BlockStack>
            </Box>
        </CollapsibleCardWithTitleSection>
    );
};

export default SubmitCard;
