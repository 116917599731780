import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { shopStore } from "@/stores";
import { vfsRoutes } from "@/constants/routes.constants";
import { isDateBeforeNewUI } from '@/utils/helpers';

type ComponentProps = { children: React.ReactNode };

const RequireGetStartedSteps = ({ children }: ComponentProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const { isVideeoSdkAndLivePageEnabled, isProductsPublishedToSalesChannel, isFirstPopclipCreated, getStartedStatus, onBoardedOn, embedEnabled, livePageCreated, publishedProductsToSalesChannelCount } = useSnapshot(shopStore);
    const redirectTo = vfsRoutes.getStarted;

    useEffect(() => {
        if (!isVideeoSdkAndLivePageEnabled && pathname !== redirectTo) {
            navigate(redirectTo);
            return;
        }

        if (!isProductsPublishedToSalesChannel && pathname !== redirectTo) {
            navigate(redirectTo);
            return;
        }

        if (!isDateBeforeNewUI(new Date(onBoardedOn || '')) && !isFirstPopclipCreated && pathname !== redirectTo) {
            navigate(redirectTo);
            return;
        }
    }, [getStartedStatus, embedEnabled, livePageCreated, publishedProductsToSalesChannelCount, onBoardedOn]);

    return <>{children}</>;
};

export default RequireGetStartedSteps;
