import { useEffect, useMemo, useState } from "react";
import { BlockStack, Box, Divider, Icon, InlineStack, Link, Text } from "@shopify/polaris";
import WizardStep1 from "../Components/OnboardingWizard/WizardStep1";
import WizardStep2 from "../Components/OnboardingWizard/WizardStep2";
import WizardStep3 from "../Components/OnboardingWizard/WizardStep3";
import WizardStep4 from "../Components/OnboardingWizard/WizardStep4";
import { useSnapshot } from "valtio";
import { analyticsEventStore, shopStore } from "@/stores";
import { AlertCircleIcon, ArrowRightIcon } from "@shopify/polaris-icons";
import '../../assets/css/GetStartedStepsPage.css';
import OnboardingWizardHeader from "../Components/OnboardingWizard/OnboardingWizardHeader";
import { useNavigate } from "react-router-dom";
import { vfsRoutes } from "@/constants/routes.constants";
import { isDateBeforeNewUI } from "@/utils/helpers";

export default function GetStartedStepsPage() {
    const navigate = useNavigate();
    const { progressPercentage, isVideeoSdkAndLivePageEnabled, isProductsPublishedToSalesChannel, isFirstPopclipCreated, isFirstPopclipCarouselPublished, getStartedStatus, publishedProductsToSalesChannelCount, onBoardedOn, embedEnabled, livePageCreated } = useSnapshot(shopStore);
    const [stepExpanded, setStepExpanded] = useState(0);
    const [tooltipExpanded, setTooltipExpanded] = useState(0);

    useEffect(() => {
        (async () => {
            if (!isVideeoSdkAndLivePageEnabled) {
                setStepExpanded(1);
            } else if (!isProductsPublishedToSalesChannel) {
                setStepExpanded(2);
            } else if (!isFirstPopclipCreated) {
                if (isDateBeforeNewUI(new Date(onBoardedOn || ''))) {
                    shopStore.updateGetStartedStatus('STEP_ADD_YOUR_FIRST_POPCLIP');
                    navigate(vfsRoutes.landing);
                } else {
                    setStepExpanded(3);
                }
            } else if (!isFirstPopclipCarouselPublished) {
                navigate(vfsRoutes.landing);
            }
        })();
    }, [getStartedStatus, embedEnabled, livePageCreated, publishedProductsToSalesChannelCount, onBoardedOn]);

    // STEP 1: Enable Videeo SDK
    useEffect(() => {
        if (embedEnabled && livePageCreated && !getStartedStatus.events.STEP_ENABLE_VIDEEO_SDK) {
            analyticsEventStore.onboardingCompleteVideoSDK();
            shopStore.updateGetStartedStatus('STEP_ENABLE_VIDEEO_SDK');
        }
    }, [embedEnabled, livePageCreated, getStartedStatus]);

    // STEP 2: Publish products to Videeo sales channel
    useEffect(() => {
        if (publishedProductsToSalesChannelCount > 0 && !getStartedStatus.events.STEP_PUBLISH_PRODUCTS_TO_VIDEEO_SC) {
            analyticsEventStore.onboardingCompleteVideoSalesChannel();
            shopStore.updateGetStartedStatus('STEP_PUBLISH_PRODUCTS_TO_VIDEEO_SC');
        }
    }, [publishedProductsToSalesChannelCount, getStartedStatus]);

    const shouldShowRedirectButton = useMemo(() => {
        return progressPercentage === 100 || (
            isVideeoSdkAndLivePageEnabled &&
            isProductsPublishedToSalesChannel &&
            isFirstPopclipCreated
        );
    }, [getStartedStatus, publishedProductsToSalesChannelCount, embedEnabled, livePageCreated]);
    
    return (
        <div className="onboarding-wizard-page-body">
            <div style={{ minHeight: '38px', width: '100%' }}>
                <h1 className="page-heading">👋 Welcome to Videeo!</h1>
                <Box paddingBlockStart="100">
                    <InlineStack gap="200">
                        <Text as="p" variant="bodyMd">
                            Questions?
                        </Text>
                        <div className="schedule-free-onboarding-call">
                            <Link
                                url="https://calendly.com/d/cmqk-5kn-95h"
                                removeUnderline
                                target="_blank"
                            >
                                <InlineStack gap="100" blockAlign="center">
                                    <Text
                                        as="span"
                                        fontWeight="semibold"
                                        tone="magic"
                                    >
                                        Schedule a free call
                                    </Text>
                                    <Icon
                                        source={ArrowRightIcon}
                                        tone="magic"
                                    />
                                </InlineStack>
                            </Link>
                        </div>
                    </InlineStack>
                </Box>
            </div>
            <div className="onboarding-wizard-modal">
                <BlockStack gap="400">
                    <OnboardingWizardHeader />
                    <Divider />
                    <BlockStack gap="600">
                        <div
                            className={`${isVideeoSdkAndLivePageEnabled && 'onboarding-wizard-modal__step-completed'}`}
                        >
                            <WizardStep1
                                stepExpanded={stepExpanded === 1}
                                isCompleted={isVideeoSdkAndLivePageEnabled}
                            />
                        </div>
                        <div
                            className={`
                                ${isProductsPublishedToSalesChannel && 'onboarding-wizard-modal__step-completed'}
                                ${
                                    stepExpanded < 2 &&
                                    'onboarding-wizard-modal__step-disabled'
                                }
                            `}
                            style={{ position: 'relative' }}
                            onMouseEnter={() => setTooltipExpanded(2)}
                            onMouseLeave={() => setTooltipExpanded(0)}
                        >
                            <WizardStep2
                                stepExpanded={stepExpanded === 2}
                                isCompleted={isProductsPublishedToSalesChannel}
                            />
                            {!isVideeoSdkAndLivePageEnabled &&
                                tooltipExpanded === 2 && (
                                    <div className="onboarding-wizard-modal__tooltip">
                                        <InlineStack
                                            blockAlign="start"
                                            gap="200"
                                        >
                                            <div>
                                                <Icon
                                                    source={AlertCircleIcon}
                                                />
                                            </div>
                                            <BlockStack gap="100">
                                                <p className="onboarding-wizard-modal__tooltip-title">
                                                    Complete previous steps
                                                </p>
                                                <p className="onboarding-wizard-modal__tooltip-text">
                                                    Please complete the previous
                                                    steps to access this one
                                                </p>
                                            </BlockStack>
                                        </InlineStack>
                                    </div>
                                )}
                        </div>
                        <div
                            className={`
                                ${isFirstPopclipCreated && 'onboarding-wizard-modal__step-completed'}
                                ${
                                    stepExpanded < 3 &&
                                    'onboarding-wizard-modal__step-disabled'
                                }
                            `}
                            style={{ position: 'relative' }}
                            onMouseEnter={() => setTooltipExpanded(3)}
                            onMouseLeave={() => setTooltipExpanded(0)}
                        >
                            <WizardStep3
                                stepExpanded={stepExpanded === 3}
                                isCompleted={isFirstPopclipCreated}
                            />
                            {!isProductsPublishedToSalesChannel && tooltipExpanded === 3 && (
                                    <div className="onboarding-wizard-modal__tooltip">
                                        <InlineStack
                                            blockAlign="start"
                                            gap="200"
                                        >
                                            <div>
                                                <Icon
                                                    source={AlertCircleIcon}
                                                />
                                            </div>
                                            <BlockStack gap="100">
                                                <p className="onboarding-wizard-modal__tooltip-title">
                                                    Complete previous steps
                                                </p>
                                                <p className="onboarding-wizard-modal__tooltip-text">
                                                    Please complete the previous
                                                    steps to access this one
                                                </p>
                                            </BlockStack>
                                        </InlineStack>
                                    </div>
                                )}
                        </div>
                        <div
                            className={`
                                ${isFirstPopclipCarouselPublished && 'onboarding-wizard-modal__step-completed'}
                                ${
                                    stepExpanded < 4 &&
                                    'onboarding-wizard-modal__step-disabled'
                                }
                            `}
                            style={{ position: 'relative' }}
                            onMouseEnter={() => setTooltipExpanded(4)}
                            onMouseLeave={() => setTooltipExpanded(0)}
                        >
                            <WizardStep4
                                stepExpanded={stepExpanded === 4}
                                isCompleted={isFirstPopclipCarouselPublished}
                            />
                            {!isFirstPopclipCreated &&
                                tooltipExpanded === 4 && (
                                    <div className="onboarding-wizard-modal__tooltip">
                                        <InlineStack
                                            blockAlign="start"
                                            gap="200"
                                        >
                                            <div>
                                                <Icon
                                                    source={AlertCircleIcon}
                                                />
                                            </div>
                                            <BlockStack gap="100">
                                                <p className="onboarding-wizard-modal__tooltip-title">
                                                    Complete previous steps
                                                </p>
                                                <p className="onboarding-wizard-modal__tooltip-text">
                                                    Please complete the previous
                                                    steps to access this one
                                                </p>
                                            </BlockStack>
                                        </InlineStack>
                                    </div>
                                )}
                        </div>
                    </BlockStack>
                    {shouldShowRedirectButton && (
                        <>
                            <Divider />
                            <BlockStack gap="200" align="center" inlineAlign="end">
                                <Text as="p" variant="bodyMd">You're all set! 🎉</Text>
                                <Link url={vfsRoutes.landing} removeUnderline>
                                    <InlineStack gap="100" blockAlign="center">
                                        <Text as="span" fontWeight="semibold" tone="magic">Continue</Text>
                                        <Icon source={ArrowRightIcon} tone="magic" />
                                    </InlineStack>
                                </Link>
                            </BlockStack>
                        </>
                    )}
                </BlockStack>
            </div>
            <p className="vertical-watermark">Videeo</p>
        </div>
    );
}