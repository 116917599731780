import { BlockStack, Icon, InlineStack, Spinner } from "@shopify/polaris";
import { AlertCircleIcon, AlertTriangleIcon } from "@shopify/polaris-icons";
import '../../assets/css/AlertBannerComponent.scss';

type AlertComponentProps = {
    closeable?: boolean;
    onClose?: () => void;
    type: 'info' | 'warning';
    title: string;
    message?: string;
    htmlBody?: string;
    buttonText?: string;
    primaryAction?: () => void;
    secondaryAction?: () => void;
    secondaryButtonText?: string;
    size: 'small' | 'large';
    loading?: boolean;
};

export default function AlertBannerComponent({
    closeable,
    onClose,
    type,
    title,
    message,
    htmlBody,
    primaryAction,
    secondaryAction,
    buttonText,
    secondaryButtonText,
    size,
    loading,
}: AlertComponentProps) {
    return (
        <div className="alert-component">
            <InlineStack align="space-between" gap="400">
                <div style={{flex:1}}>
                    <InlineStack gap="300" blockAlign="start">
                        {size === 'large' && <AlertBannerIcon type={type} />}
                        <div className="alert-component__content">
                            <BlockStack gap={size == 'large' ? '050' : '300'}>
                                {size === 'small' && (
                                    <InlineStack gap="200" align="start">
                                        <AlertBannerIcon type={type} />
                                        <p className="alert-component__heading">
                                            {title}
                                        </p>
                                    </InlineStack>
                                )}
                                {size === 'large' && (
                                    <p className="alert-component__heading">
                                        {title}
                                    </p>
                                )}
                                {message && (
                                    <p className="alert-component__sub-heading">
                                        {message}
                                    </p>
                                )}
                                {htmlBody && (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: `<div>${htmlBody}</div>`,
                                        }}
                                    ></div>
                                )}
                            </BlockStack>
                        </div>
                    </InlineStack>
                </div>
                <InlineStack gap="400">
                    {buttonText && primaryAction && (
                        <InlineStack gap="200" align="center">
                            <div style={{ alignSelf: 'center' }}>
                                <button
                                    className="alert-component__action"
                                    onClick={primaryAction}
                                    disabled={loading}
                                >
                                    {loading && <Spinner size="small" />}
                                    {buttonText}
                                </button>
                            </div>
                        </InlineStack>
                    )}
                    {secondaryButtonText && secondaryAction && (
                        <button
                            className="alert-component__secondary-action"
                            onClick={secondaryAction}
                            disabled={loading}
                        >
                            {secondaryButtonText}
                        </button>
                    )}
                </InlineStack>
                {closeable && (
                    <button
                        className="alert-component__close"
                        onClick={onClose}
                        disabled={loading}
                    >
                        X
                    </button>
                )}
            </InlineStack>
        </div>
    );
}

const AlertBannerIcon = ({ type }) => (
    <div>
        {type === 'info' && <Icon source={AlertCircleIcon} />}
        {type === 'warning' && <Icon source={AlertTriangleIcon} />}
    </div>
);