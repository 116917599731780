import {
    BlockStack,
    Box,
    Text,
    Card,
    Bleed,
    Image,
    Checkbox,
    Tooltip,
    InlineStack,
} from '@shopify/polaris';
import { videoLiveSellingStore } from '@/stores';
import { useSnapshot } from 'valtio';
import { shopifyCurrencyFormatter } from '@/utils/MoneyFormatter'
type ShopifyProductCardProps = {
    id: string;
    title: string;
    description: string;
    amount: string;
    currencyCode: string;
    imgSrc: string;
    imgAlt: string;
    selectedProducts: string[];
    handleProductSelect: () => void;
    isColumnLayout: boolean;
};

const ShopifyProductCard = ({
    id,
    title,
    description,
    amount,
    currencyCode,
    imgSrc,
    imgAlt,
    selectedProducts,
    handleProductSelect,
    isColumnLayout,
}: ShopifyProductCardProps) => {
    const { savingPopclip } = useSnapshot(videoLiveSellingStore);

    return (
        <Card roundedAbove="sm">
            <Bleed marginInline="400" marginBlock="400">
                <ShopifyProductCardLayout isColumnLayout={isColumnLayout}>
                    <div
                        style={{
                            width: '122px',
                            height: '78px',
                            textAlign: 'center',
                        }}
                    >
                        <Image
                            source={imgSrc}
                            alt={imgAlt}
                            style={!isColumnLayout ? { width: '100%' } : {}}
                        />
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                        }}
                    >
                        <Checkbox
                            label="checkbox"
                            tone={savingPopclip ? undefined : "magic"}
                            checked={selectedProducts.includes(id)}
                            onChange={handleProductSelect}
                            labelHidden
                            disabled={savingPopclip}
                        />
                    </div>
                    <Box
                        background="bg-fill"
                        paddingInline="200"
                        paddingBlock="200"
                    >
                        <Box width={isColumnLayout ? "106px" : '100%'}>
                            <BlockStack
                                gap="200"
                                align="space-around"
                                inlineAlign="start"
                            >
                                <Box minHeight="60px">
                                    {isColumnLayout ? (
                                        <Tooltip dismissOnMouseOut content={title}>
                                            <Text as="h3" variant="headingSm">
                                                {title.length > 25
                                                    ? title.substring(0, 25) + '...'
                                                    : title}
                                            </Text>
                                        </Tooltip>
                                    ) : (
                                        <BlockStack gap="100">
                                            <Text as="h3" variant="headingSm">
                                                {title}
                                            </Text>
                                            <Text as="h3" variant="headingSm">
                                                {description}
                                            </Text>
                                        </BlockStack>
                                    )}
                                </Box>
                                <Text as="p" variant="bodyMd" fontWeight='semibold'>
                                    {shopifyCurrencyFormatter(
                                        amount,
                                        currencyCode,
                                    )}
                                </Text>
                            </BlockStack>
                        </Box>
                    </Box>
                </ShopifyProductCardLayout>
            </Bleed>
        </Card>
    );
};

const ShopifyProductCardLayout = ({ isColumnLayout, children }) => {
    return <>{isColumnLayout ? (
        <BlockStack gap="200" align="space-around" inlineAlign="center">
            {children}
        </BlockStack>
    ) : (
        <InlineStack gap="200" align="start">
            {children}
        </InlineStack>
    )}</>;
};

export default ShopifyProductCard;
