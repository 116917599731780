import { vfsRoutes } from "@/constants/routes.constants";
import { analyticsEventStore, shopStore } from "@/stores";
import { Icon, Link } from "@shopify/polaris";
import { ArrowDiagonalIcon } from "@shopify/polaris-icons";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { liveSellCard } from "../../../assets/images";

const LEARN_MORE_LINK = 'https://help.videeo.live/hc/en-us/categories/17326415181076';

export default function LiveSellingCard () {
    const navigate = useNavigate();
    const { dashboardUrl, enabledFeatures } = useSnapshot(shopStore);

    const handleClickGetStarted = useCallback(async () => {
        if (!shopStore.enabledFeatures.includes('live')) return;
        analyticsEventStore.goLiveClickedEvent();
        open(`${shopStore.dashboardUrl}`, '_blank');
    }, [dashboardUrl]);

    return (
        <div className="live-selling-card">
            <div className="card-shapes">
                <div className="square"></div>
                <div className="circle"></div>
            </div>
            <p className="card-title">
                <span>Live</span>
                <span>Selling</span>
            </p>
            <div className="card-content">
                <p>
                    Stream live shows, interact real-time, build community.{' '}
                    <Link url={LEARN_MORE_LINK}>Learn More</Link>
                </p>
                {enabledFeatures.includes('live') ? (
                    <button
                        className="get-started-button"
                        onClick={handleClickGetStarted}
                    >
                        Go Live <Icon source={ArrowDiagonalIcon} />{' '}
                    </button>
                ) : (
                    <button
                        className="get-started-button"
                        onClick={() => navigate(vfsRoutes.managePlans)}
                    >
                        Get Started <Icon source={ArrowDiagonalIcon} />{' '}
                    </button>
                )}
            </div>
            <div className="card-image">
                <video autoPlay muted loop style={{ width: '98px' }}>
                    <source src={liveSellCard} type="video/webm" />
                </video>
            </div>
        </div>
    );
};
