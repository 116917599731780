import { proxy } from 'valtio';
import { sha256 } from 'js-sha256';
import shopStore from './shopStore';
export interface popUpDataType {
    is_external_cta_link: boolean;
    render_url: string;
    rule_id: string;
    created_at: number;
    extras: {
        cta_text: string;
        cta_link: string;
    };
    rule_name: string;
    render_html: string;
    heading_text: string;
    is_active: boolean;
}
interface CustomBannersState {
    bannerList: popUpDataType[];
    getBannerList: () => Promise<void>;
    dismissBanner: (popup_id: string) => Promise<void>;
}

const apiKey = '01JJKMH7C37KBFASWRPJ6ME2HE';

export const getPopupsRules = async () => {
    try {
        const base_url = `${shopStore?.popclipsServiceApi}/shop-popups`;
        const currentTimestamp = new Date().getTime();
        const hashedString = sha256(`${apiKey}${currentTimestamp}`);
        const headerForAPI = {
            'x-request-timestamp': currentTimestamp.toString(),
            authorization: `Bearer ${hashedString}`,
        };
        const URL = `${base_url}?shopId=${shopStore.shopifyDomain}`;
        const result = await fetch(URL, {
            headers: headerForAPI,
            method: 'GET',
        });
        const response = await result.json();
        return response?.data;
    } catch (error) {
        console.log('Failed to fetch popup rules: ', error);
        return [];
    }
};
export const dismissPopUp = async (popup_id: string) => {
    const base_url = `${shopStore?.popclipsServiceApi}/shop-popups`;
    const currentTimestamp = new Date().getTime();
    const hashedString = sha256(`${apiKey}${currentTimestamp}`);
    const headerForAPI = {
        'x-request-timestamp': currentTimestamp.toString(),
        authorization: `Bearer ${hashedString}`,
        'Content-Type': 'application/json',
    };
    try {
        const URL = `${base_url}/dismiss`;
        const data = {
            shop_id: shopStore.shopifyDomain,
            rule_id: popup_id,
        };
        const result = await fetch(URL, {
            headers: headerForAPI,
            method: 'PUT',
            body: JSON.stringify(data),
        });
        const response = await result.json();
        return response;
    } catch (error) {
        console.log('Failed to dismiss popup: ', error);
    }
};
const CustomBanners = proxy<CustomBannersState>({
    bannerList: [],
    getBannerList: async () => {
        const response = await getPopupsRules();
        if (response && response!==null){
            CustomBanners.bannerList = response?.filter(
                (item: popUpDataType) =>
                    item?.is_active && item?.heading_text && item?.render_url,
            );
        }
        else{
            CustomBanners.bannerList = []
        }
    },
    dismissBanner: async (popup_id) => {
        const newBannerList=CustomBanners.bannerList?.filter((value:popUpDataType)=> value?.rule_id!=popup_id)
        CustomBanners.bannerList = newBannerList;
        await dismissPopUp(popup_id);
    },
});
export default CustomBanners;
