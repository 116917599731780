type PopclipsRouteNames =
    | 'prefix'
    | 'accountPrefix'
    | 'createAccount'
    | 'verifyAccount'
    | 'list'
    | 'create'
    | 'edit'
    | 'createCarousel'
    | 'editCarousel'
    | 'settings';
type MobileRouteNames = 'prefix' | 'setup' | 'notification' | 'appearence' | 'custom_menu' | 'composePushNotification';
type VFSRouteNames =
    | 'selectPlans'
    | 'accountPrefix'
    | 'createAccount'
    | 'verifyAccount'
    | 'getStarted'
    | 'landing'
    | 'managePlans'
    | 'live'
    | 'insights';

export const popclipsRoutes: Record<PopclipsRouteNames, string> = {
    prefix: '/popclips',
    accountPrefix: '/popclips/account',
    createAccount: '/popclips/account/create',
    verifyAccount: '/popclips/account/verify',
    list: '/popclips/list',
    create: '/popclips/create',
    edit: '/popclips/edit/:id',
    createCarousel: '/popclips/create-carousel',
    editCarousel: '/popclips/edit-carousel',
    settings: '/popclips/settings',
} as const;

export const mobileRoutes: Record<MobileRouteNames, string> = {
    prefix: '/mobile',
    notification: '/mobile/notifications-banners',
    appearence: '/mobile/appearance',
    custom_menu: '/mobile/custom-menu',
    setup: '/mobile/setup',
    composePushNotification: '/mobile/notification',
} as const;

export const vfsRoutes: Record<VFSRouteNames, string> = {
    selectPlans: '/account/plans',
    accountPrefix: '/account',
    createAccount: '/account/create',
    verifyAccount: '/account/verify',
    getStarted: '/get-started',
    landing: '/',
    managePlans: '/manage/plans',
    live: '/live',
    insights: '/insights',
} as const;