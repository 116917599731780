import { BlockStack, Box, InlineStack } from "@shopify/polaris";
import { useNavigate } from "react-router-dom";
import { analyticsEventStore, customBannersStore, shopStore } from "@/stores";
import '../../assets/css/CustomVFSBanner.scss';
import { APP_NAME_BY_ENV } from "@/stores/analyticsEventStore";

type CustomVFSBannerProps = {
    title: string;
    body: string;
    isCTATypeExternal: boolean;
    CTAUrl: string;
    CTATitle: string;
    popUp_id: string;
    rule_name:string;
    render_url:string;
};
export function CustomVFSBanner({
    title,
    body,
    isCTATypeExternal,
    CTATitle,
    CTAUrl,
    popUp_id,
    rule_name,
    render_url,
}: CustomVFSBannerProps) {
    const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
    const navigate = useNavigate();
    const onPressPrimaryButton = () => {
        if (isCTATypeExternal) {
            window.open(CTAUrl);
        } else {
            navigate(CTAUrl);
        }
        analyticsEventStore.customBannerReaction(
            rule_name,
            popUp_id,
            title,
            CTAUrl,
            isCTATypeExternal,
            false,
            CTATitle,
            `customBanner-component__action-${popUp_id}`,
            `${shopifyAdminURL + render_url}`,
        );
    };

    const onClose = () => {
        customBannersStore?.dismissBanner(popUp_id);
        analyticsEventStore.customBannerReaction(
            rule_name,
            popUp_id,
            title,
            CTAUrl,
            isCTATypeExternal,
            true,
            'Dismissed by X button',
            `customBanner-component__close-${popUp_id}`,
            `${shopifyAdminURL + render_url}`,
        );
    };
    return (
        <Box paddingBlockStart={'400'}>
            <div className="customBanner-component">
                <InlineStack align="space-between" gap="400">
                    <div style={{ flex: 1 }}>
                        <InlineStack gap="300" blockAlign="start">
                            <div className="customBanner-component__content">
                                <BlockStack gap={'050'}>
                                    <p className="customBanner-component__heading">
                                        {title}
                                    </p>
                                    {body && (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: `<div>${body}</div>`,
                                            }}
                                        ></div>
                                    )}
                                </BlockStack>
                            </div>
                        </InlineStack>
                    </div>
                    <InlineStack gap="400">
                        {CTATitle && (
                            <InlineStack gap="200" align="center">
                                <div style={{ alignSelf: 'center' }}>
                                    <button
                                        className={`customBanner-component__action customBanner-component__action-${popUp_id}`}
                                        onClick={onPressPrimaryButton}
                                    >
                                        {CTATitle}
                                    </button>
                                </div>
                            </InlineStack>
                        )}
                    </InlineStack>
                    <button
                        className={`customBanner-component__close customBanner-component__close-${popUp_id}`}
                        onClick={onClose}
                    >
                        X
                    </button>
                </InlineStack>
            </div>
        </Box>
    );
}