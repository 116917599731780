import {
    BlockStack,
    Box,
    Button,
    Card,
    Checkbox,
    Divider,
    FormLayout,
    Icon,
    InlineStack,
    Text,
    TextField,
    Thumbnail,
} from '@shopify/polaris';
import { useSnapshot } from 'valtio';
import { analyticsEventStore, mobileAppConfigStore, shopStore } from '@/stores';
import { useCallback, useEffect, useState } from 'react';
import { DateTimePicker } from '@/components/common/DateTimePicker';
import {
    SearchProductCollection,
    useGetShopifyProducts,
} from '@/hooks/useGetShopifyProducts';
import { mobileRoutes } from '@/constants/routes.constants';
import { useNavigate } from 'react-router-dom';
import { CheckCircleIcon } from '@shopify/polaris-icons';
import { BaseLayout } from '../../v2/pages/Layout/BaseLayout';
import { NotificationBlockModal } from '@/components/common/NotificationBlockModal';
import { shopifyIdFromGid } from '@/utils/GidHelper';

const ComposePushNotification = () => {
    const isLinkCollectionEnable = true;
    const [sendingNotification, setSendingNotification] = useState(false);
    const [isScheduleForLater, setIsScheduleForLater] = useState(false);
    const [selectedDates, setSelectedDates] = useState<Date | null>(null);
    const { getProductsImagesByCollectionId } = useGetShopifyProducts();
    const [selectedItem, setSelectedItem] =
        useState<SearchProductCollection | null>();
    const [productImgArr, setProductImgArr] = useState<string[]>();
    const [isProductOrCollection, setProductOrCollection] =
        useState<string>('');
    const [showNotificationBlockModal, setShowNotificationBlockModal] =
        useState(false);
    const navigate = useNavigate();
    const { featureFlags } = useSnapshot(shopStore);
    const textEllipsis = {
        width: '16rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
        'font-weight': 'bold',
    };
    const mobileAppConfigSnap = useSnapshot(mobileAppConfigStore, {
        sync: true,
    });

    const handleScheduleLaterCheckbox = useCallback(
        (newChecked: boolean) => setIsScheduleForLater(newChecked),
        [],
    );
    /* useEffect(() => {
        if (featureFlags?.disable_push_notification) {
            setShowNotificationBlockModal(true);
        }
    }, [featureFlags]); */
    const isValidDateAndTime = () => {
        let isValidDateAndTime = true;

        if (isScheduleForLater) {
            if (selectedDates) {
                const futureDateTime = new Date().getTime() + 1000 * 60 * 4;
                if (selectedDates.getTime() >= futureDateTime) {
                    isValidDateAndTime = true;
                } else {
                    isValidDateAndTime = false;
                    shopify.toast.show(
                        'Please select future time, 4 minutes ahead of current time',
                        {
                            isError: true,
                            duration: 5000,
                        },
                    );
                }
            } else {
                shopify.toast.show('Please select date and time', {
                    isError: true,
                    duration: 5000,
                });
                isValidDateAndTime = false;
            }
        }
        return isValidDateAndTime;
    };

    const handleNotificationBtn = async () => {
        if (
            mobileAppConfigStore.isNotificationsTitleValid &&
            mobileAppConfigStore.isNotificationsBodyValid
        ) {
            if (isValidDateAndTime()) {
                setSendingNotification(true);
                try {
                    const payload = {
                        type: 'custom',
                        title: mobileAppConfigStore.notificationsTitle,
                        body: mobileAppConfigStore.notificationsBody,
                        sentAt: isScheduleForLater
                            ? selectedDates?.toISOString()
                            : null,
                    };
                    if (selectedItem && selectedItem.handle) {
                        Object.assign(payload, {
                            route: `/${isProductOrCollection}/${selectedItem.handle}`,
                        });
                    }
                    const response = await fetch(
                        '/api/shopify/shop/mobile/notifications',
                        {
                            method: 'POST',
                            body: JSON.stringify(payload),
                        },
                    );

                    mobileAppConfigStore.showNotificationErrorLabel = false;
                    mobileAppConfigStore.notificationsTitle = '';
                    mobileAppConfigStore.notificationsBody = '';
                    if (response.status === 500) {
                        shopify.toast.show('Failed to sent notification', {
                            isError: true,
                            duration: 5000,
                        });
                    } else if (response.status === 200) {
                        shopify.toast.show(
                            isScheduleForLater
                                ? 'Notification scheduled successfully'
                                : 'Notification sent successfully',
                            {
                                isError: false,
                                duration: 5000,
                            },
                        );
                        const data = await response.json();
                        sendNotificationEvent(data);
                        setSelectedItem(null);
                        setProductImgArr([]);
                        setProductOrCollection('');
                        setIsScheduleForLater(false);
                        navigate(mobileRoutes.notification);
                    }
                } catch (error) {
                    shopify.toast.show(
                        'Failed. Please try again or contact support.',
                        {
                            isError: true,
                            duration: 5000,
                        },
                    );
                } finally {
                    setSendingNotification(false);
                }
            } else {
                return;
            }
        } else {
            mobileAppConfigStore.showNotificationErrorLabel = true;
        }
    };

    const handleDateTimeChange = (date: Date) => {
        setSelectedDates(date);
    };

    const handleTitleChange = (value: string) => {
        const title = value.replace(/["]/g, `'`);
        mobileAppConfigStore.notificationsTitle = title;
    };

    const handleBodyChange = (value: string) => {
        const body = value.replace(/["]/g, `'`);
        mobileAppConfigStore.notificationsBody = body;
    };

    const handleCollectionPicker = async () => {
        const selected = await shopify.resourcePicker({
            type: 'collection',
            multiple: false,
            action: 'select',
            filters: {
                hidden: false,
                variants: false,
                draft: false,
                archived: false,
            },
        });
        if (selected?.[0]) {
            setProductImgArr([]);
            setProductOrCollection('collections');
            getCollectionProductsImages(selected[0].id);
            setSelectedItem(selected[0]);
            let imagesArray = [];
            if (selected[0]?.image) {
                imagesArray = selected[0].image.map((imageData) => {
                    return imageData.originalSrc;
                });
            }
            setProductImgArr(imagesArray);
        }
    };

    const handleProductPicker = async () => {
        const selected = await shopify.resourcePicker({
            type: 'product',
            multiple: false,
            action: 'select',
            filters: {
                hidden: false,
                variants: false,
                draft: false,
                archived: false,
            },
        });
        if (selected?.[0]) {
            setProductImgArr([]);
            setProductOrCollection('products');
            setSelectedItem(selected[0]);
            const imagesArray = selected[0].images.map((imageData) => {
                return imageData.originalSrc;
            });
            setProductImgArr(imagesArray);
        }
    };

    const clearLinkedProductsCollection = () => {
        setSelectedItem(null);
        setProductImgArr([]);
        setProductOrCollection('');
    };

    const getCollectionProductsImages = (id: string) => {
        if (id) {
            (async () => {
                const productArr = await getProductsImagesByCollectionId(id);
                const imgArr = productArr.map((product) => product.imgSrc);
                setProductImgArr(imgArr);
                setProductOrCollection('collections');
            })();
        }
    };
    const handleNotificationBlock=()=>{
        setShowNotificationBlockModal(false);
        navigate(-1);
    }

    const sendNotificationEvent = (data: any) => {
        const optionObject = {
            notification_title: data?.title || '',
            notification_body: data?.body || '',
            is_scheduled: isScheduleForLater,
            is_collection_linked: false,
            is_product_linked: false,
            scheduled_datetime: data?.sentAt ||null,
        };
        if(isProductOrCollection === 'collections' && selectedItem?.id) {
            Object.assign(optionObject, {
                is_collection_linked: true,
                linked_collection_id: [shopifyIdFromGid(selectedItem.id)],
                linked_collection_name: [selectedItem?.title],
            });
        }
        if(isProductOrCollection === 'products' && selectedItem?.id) {
            Object.assign(optionObject, {
                is_product_linked: true,
                linked_product_id: [shopifyIdFromGid(selectedItem.id)],
                linked_product_name: [selectedItem?.title],
                linked_product_price: selectedItem?.variants?.[0]?.price ? [Math.round(selectedItem.variants[0].price * 100)] : null,
                linked_product_price_currency: "USD",
                linked_product_variants: selectedItem?.variants?.map((variant: any) => shopifyIdFromGid(variant.id)) || [],
            });
        }
        analyticsEventStore.composePushNotificationEvent(
            data.id,
            isScheduleForLater
                ? 'Schedule Notification'
                : 'Send Notification', 
            optionObject,
        );
    }

    return (
        <BaseLayout title="Compose Push Notification" backToLink={mobileRoutes.notification}>
            <BlockStack gap="1000">
                <BlockStack inlineAlign="center" gap="1000">
                    <Card padding="800">
                        <Box width="56rem">
                            <BlockStack gap="600">
                                <BlockStack>
                                    <InlineStack
                                        align="space-between"
                                        blockAlign="center"
                                    >
                                        <Text as="h3" variant="headingSm">
                                            Push Notifications
                                        </Text>
                                        {isLinkCollectionEnable && (
                                            <InlineStack gap="200">
                                                <Button
                                                    onClick={
                                                        handleCollectionPicker
                                                    }
                                                >
                                                    Link Collection
                                                </Button>
                                                <Button
                                                    onClick={
                                                        handleProductPicker
                                                    }
                                                    variant="primary"
                                                >
                                                    Link Product
                                                </Button>
                                            </InlineStack>
                                        )}
                                    </InlineStack>
                                    <Text as="h4" variant="bodySm">
                                        Stay connected with your customers
                                        through custom push notifications.
                                    </Text>
                                </BlockStack>
                                <Box>
                                    <Box paddingBlockEnd="200">
                                        {selectedItem ? (
                                            <Box>
                                                <Box
                                                    borderRadius="200"
                                                    borderColor="border-success"
                                                    borderWidth="025"
                                                    padding="300"
                                                    background="bg-fill-success-secondary"
                                                >
                                                    <InlineStack
                                                        align="space-between"
                                                        wrap={false}
                                                        gap="100"
                                                        blockAlign="center"
                                                    >
                                                        <InlineStack
                                                            wrap={false}
                                                            blockAlign="center"
                                                            gap="100"
                                                        >
                                                            <Box>
                                                                <Icon
                                                                    source={
                                                                        CheckCircleIcon
                                                                    }
                                                                    tone="success"
                                                                />
                                                            </Box>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    gap: '4px',
                                                                }}
                                                            >
                                                                <div>
                                                                    Users will
                                                                    be taken to
                                                                </div>
                                                                <div
                                                                    style={
                                                                        selectedItem
                                                                            .title
                                                                            .length >
                                                                        35
                                                                            ? textEllipsis
                                                                            : {
                                                                                  fontWeight:
                                                                                      'bold',
                                                                              }
                                                                    }
                                                                >
                                                                    {
                                                                        selectedItem.title
                                                                    }
                                                                </div>
                                                                <div>
                                                                    when they
                                                                    tap on the
                                                                    notification
                                                                </div>
                                                            </div>
                                                        </InlineStack>
                                                        <InlineStack gap="200">
                                                            {productImgArr?.map(
                                                                (src) => (
                                                                    <Thumbnail
                                                                        size="small"
                                                                        source={
                                                                            src
                                                                        }
                                                                        alt="product_image"
                                                                        key={
                                                                            src
                                                                        }
                                                                    />
                                                                ),
                                                            )}
                                                        </InlineStack>
                                                    </InlineStack>
                                                </Box>
                                                <Box>
                                                    <BlockStack inlineAlign="end">
                                                        <div
                                                            style={{
                                                                cursor: 'pointer',
                                                                color: 'blue',
                                                            }}
                                                            onClick={() =>
                                                                clearLinkedProductsCollection()
                                                            }
                                                        >
                                                            remove link
                                                        </div>
                                                    </BlockStack>
                                                </Box>
                                            </Box>
                                        ) : isLinkCollectionEnable ? (
                                            <Box
                                                borderRadius="200"
                                                padding="300"
                                                background="bg-fill-secondary-hover"
                                            >
                                                <Text as="p">
                                                    Enable your customers to
                                                    access a product or
                                                    collection directly from the
                                                    push notification.
                                                </Text>
                                            </Box>
                                        ) : null}
                                    </Box>
                                    <FormLayout>
                                        <TextField
                                            autoComplete="off"
                                            requiredIndicator
                                            label="Notification Title"
                                            placeholder="Enter title here"
                                            maxLength={50}
                                            value={
                                                mobileAppConfigSnap.notificationsTitle
                                            }
                                            onChange={handleTitleChange}
                                            error={
                                                !mobileAppConfigSnap.isNotificationsTitleValid &&
                                                mobileAppConfigSnap.showNotificationErrorLabel
                                                    ? 'Required field'
                                                    : ''
                                            }
                                        />
                                        <TextField
                                            autoComplete="off"
                                            requiredIndicator
                                            label="Notification Body"
                                            placeholder="Enter text here"
                                            maxLength={150}
                                            value={
                                                mobileAppConfigSnap.notificationsBody
                                            }
                                            onChange={handleBodyChange}
                                            error={
                                                !mobileAppConfigSnap.isNotificationsBodyValid &&
                                                mobileAppConfigSnap.showNotificationErrorLabel
                                                    ? 'Required field'
                                                    : ''
                                            }
                                            showCharacterCount
                                        />
                                        <Box
                                            background="bg-surface-brand-hover"
                                            padding="400"
                                            borderRadius="200"
                                        >
                                            <Checkbox
                                                label="Schedule notification for later date/time"
                                                checked={isScheduleForLater}
                                                onChange={
                                                    handleScheduleLaterCheckbox
                                                }
                                            />
                                            <Divider />
                                            {isScheduleForLater ? (
                                                <Box paddingBlock="200">
                                                    <DateTimePicker
                                                        onChange={
                                                            handleDateTimeChange
                                                        }
                                                    />
                                                </Box>
                                            ) : null}
                                        </Box>
                                        <InlineStack
                                            blockAlign="baseline"
                                            align="end"
                                        >
                                            <Box paddingBlockStart="1000">
                                                <Button
                                                    loading={
                                                        sendingNotification
                                                    }
                                                    variant="primary"
                                                    disabled={
                                                        sendingNotification
                                                    }
                                                    fullWidth={true}
                                                    submit
                                                    onClick={
                                                        handleNotificationBtn
                                                    }
                                                    size="large"
                                                >
                                                    {isScheduleForLater
                                                        ? 'Schedule Notification'
                                                        : 'Send Notification'}
                                                </Button>
                                            </Box>
                                        </InlineStack>
                                    </FormLayout>
                                </Box>
                            </BlockStack>
                        </Box>
                    </Card>
                </BlockStack>
            </BlockStack>
            {showNotificationBlockModal && (
                <NotificationBlockModal
                    setShowNotificationBlockModal={
                        setShowNotificationBlockModal
                    }
                    primaryAction={() => handleNotificationBlock()}
                />
            )}
        </BaseLayout>
    );
};
export default ComposePushNotification;
