import { BaseLayout } from "./Layout/BaseLayout";
import { BlockStack, Box, Button, Checkbox, Divider, Icon, InlineStack, Link, Spinner, Text, TextField } from "@shopify/polaris";
import { analyticsEventStore, shopStore, videoLiveSellingStore } from "@/stores";
import { useSnapshot } from "valtio";
import { useEffect, useRef, useState } from "react";
import { emailRegex } from '@/utils/validation';
import { getSanitizedPhoneNumber } from "@/utils/sanitize";
import { useNavigate } from "react-router-dom";
import CreateAccountLayout from "./Layout/CreateAccountLayout";
import { vfsRoutes } from "@/constants/routes.constants";
import { AlertCircleIcon } from "@shopify/polaris-icons";
import { isQaEnv } from "@/utils/helpers";

const utf8mb4Regex = /[\u{10000}-\u{10FFFF}]/u;

export default function AccountCreatePage() {
    const navigate = useNavigate();
    const pollingTriesRef = useRef(0);
    const { countryCode, isPhoneNumberInvalid, phoneNumber, emailAddress } = useSnapshot(videoLiveSellingStore, { sync: true });

    const [isApiLoading, setIsApiLoading] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [marketingSMSAccepted, setMarketingSMSAccepted] = useState(false);
    const [recaptchaInstance, setRecaptchaInstance] = useState<import('firebase/auth').RecaptchaVerifier>();

    const [emailError, setEmailError] = useState('');
    const [termsError, setTermsError] = useState('');

    useEffect(() => {
        videoLiveSellingStore.countryCode = '+1';
        videoLiveSellingStore.phoneNumber = '';
    }, []);

    useEffect(() => {
        (async () => {
            const firebaseAuth = await shopStore.getFirebaseAuth();
            if (firebaseAuth) {
                const { RecaptchaVerifier } = await import('firebase/auth');
                const recaptcha = new RecaptchaVerifier(
                    firebaseAuth,
                    'recaptcha-container',
                    { size: 'invisible' },
                );

                setRecaptchaInstance(recaptcha);
            }
        })();
    }, [shopStore.config]);

    const areFieldsValid = () => {
        let hasError = 0;

        if (videoLiveSellingStore.validatePhoneNumber() === false) hasError++;
        if (!termsAccepted) {
            setTermsError('You must accept the Terms of Service and Privacy Policy to proceed.');
            hasError++;
        }

        if (!shopStore.csStoreCreated) {
            const isEmailValid =
                emailAddress.length > 0 &&
                emailAddress.length <= 255 &&
                emailRegex.test(emailAddress) &&
                !utf8mb4Regex.test(emailAddress);
    
            if (!isEmailValid) {
                setEmailError('Invalid email address');
                hasError++;
            }
        }

        return hasError > 0 ? false : true;
    };

    const verifyCoreAccountCreated = async () => {
        pollingTriesRef.current += 1;
        const isCoreAccountCreated = await shopStore.isCoreAccountCreated();

        if (isCoreAccountCreated) {
            return;
        } else {
            if (pollingTriesRef.current <= 20) {
                setTimeout(verifyCoreAccountCreated, 2000);
                return;
            }
        }
    };

    const createCoreAccount = async () => {
        setIsApiLoading(true);
        try {
            await shopStore.createCoreStoreAccount(emailAddress.trim());
            await verifyCoreAccountCreated();
        } catch (e) {
            console.error(e);
            shopify.toast.show('Failed to create CommentSold account. Please contact support.', {
                isError: true,
                duration: 5000,
            });
        } finally {
            setIsApiLoading(false);
        }
    };

    const createPSLAccount = async () => {
        const { countryCode, phoneNumber } = videoLiveSellingStore;

        try {
            const firebaseAuth = await shopStore.getFirebaseAuth();
            if (recaptchaInstance && firebaseAuth) {
                setIsApiLoading(true);
                shopify.toast.show(
                    'Sending OTP to phone number. Please wait...',
                    {
                        isError: false,
                        duration: 5000,
                    },
                );

                const { signInWithPhoneNumber } = await import('firebase/auth');
                videoLiveSellingStore.userAuth = await signInWithPhoneNumber(
                    firebaseAuth,
                    getSanitizedPhoneNumber(`${countryCode}${phoneNumber}`),
                    recaptchaInstance,
                );

                setIsApiLoading(false);
                navigate(vfsRoutes.verifyAccount);
            } else {
                shopify.toast.show(
                    'Something went wrong. Please refresh & try again.',
                    {
                        isError: true,
                        duration: 5000,
                    },
                );
            }
        } catch (error) {
            let errorMessage = 'Something went wrong while trying to sign in.';

            switch (error.code) {
                case 'auth/invalid-phone-number':
                    errorMessage =
                        'Invalid phone number. Please enter a valid phone number.';
                    break;
                case 'auth/too-many-requests':
                    errorMessage =
                        'You have reached the maximum number of OTP requests. Please try again later.';
                    break;
            }

            shopify.toast.show(errorMessage, {
                isError: true,
                duration: 5000,
            });
            console.error(error);
            setIsApiLoading(false);
        }
    };

    const createAccounts = async () => {
        if (!areFieldsValid()) return;
        setIsApiLoading(true);

        if (!shopStore.csStoreCreated) {
            const isEmailAvailable = await shopStore.isEmailAvailable(emailAddress);
            if (!isEmailAvailable) {
                setIsApiLoading(false);
                setEmailError('Email already in use');
                return;
            }
            await createCoreAccount();
        }

        if (!isQaEnv) {
            const phoneNumber = `${videoLiveSellingStore.countryCode}${videoLiveSellingStore.phoneNumber}`;
            const isPhoneAvailable = await shopStore.isPhoneAvailable(phoneNumber.replace('+', '%2B'));
            if (!isPhoneAvailable) {
                setIsApiLoading(false);
                shopify.toast.show('Phone number already in use', {
                    isError: true,
                    duration: 5000,
                });
                return;
            }
        }

        analyticsEventStore.accountEnterContactDetails(`${videoLiveSellingStore.countryCode}${videoLiveSellingStore.phoneNumber}`, emailAddress);
        await createPSLAccount();
    }

    return (
        <BaseLayout title="Create your account" isLoading={false}>
            <CreateAccountLayout cssStyle={{ marginBottom: '3rem', marginTop: '2rem' }}>
                <BlockStack gap="400">
                    <h4 className="card-body-heading">
                        Congratulations and Welcome to the Videeo Family!
                    </h4>
                    <p className="card-body-subheading">
                        Enter your email and verify your phone number to create
                        your account and unlock all the powerful features of our
                        mobile video commerce platform.
                    </p>
                    {!shopStore.csStoreCreated && (
                        <TextField
                            autoComplete="on"
                            requiredIndicator
                            type="email"
                            label="Email Address"
                            placeholder="my@email.com"
                            helpText="Required for essential product and account updates."
                            inputMode="email"
                            maxLength={255}
                            showCharacterCount
                            value={emailAddress}
                            onFocus={() => setEmailError('')}
                            onChange={(value) => videoLiveSellingStore.emailAddress = value}
                            error={emailError}
                            disabled={isApiLoading}
                        />
                    )}
                    <TextField
                        autoComplete="off"
                        requiredIndicator
                        type="text"
                        label="Phone Number"
                        inputMode="tel"
                        connectedLeft={
                            <Box width="4rem">
                                <TextField
                                    autoComplete="off"
                                    label=" "
                                    labelHidden
                                    value={countryCode}
                                    onChange={(value) => {
                                        videoLiveSellingStore.countryCode =
                                            value;
                                        videoLiveSellingStore.validatePhoneNumber();
                                    }}
                                    onFocus={() => {
                                        videoLiveSellingStore.isPhoneNumberInvalid =
                                            false;
                                    }}
                                    disabled={isApiLoading}
                                    error={isPhoneNumberInvalid}
                                />
                            </Box>
                        }
                        value={phoneNumber}
                        onChange={(value) => {
                            videoLiveSellingStore.phoneNumber = value;
                            videoLiveSellingStore.validatePhoneNumber();
                        }}
                        onFocus={() => {
                            videoLiveSellingStore.isPhoneNumberInvalid = false;
                        }}
                        error={
                            isPhoneNumberInvalid
                                ? 'Please enter a valid phone number'
                                : ''
                        }
                        helpText="We will send you a confirmation code to complete authentication."
                        disabled={isApiLoading}
                    />
                    <Box>
                        <Checkbox
                            label={
                                <Text as="p">
                                    I agree to the{' '}
                                    <Link
                                        url="https://commentsold.com/legal/main-terms"
                                        target="_blank"
                                    >
                                        Videeo Terms of Service
                                    </Link>{' '}
                                    and{' '}
                                    <Link
                                        url="https://commentsold.com/privacy_policy"
                                        target="_blank"
                                    >
                                        Privacy Policy
                                    </Link>
                                </Text>
                            }
                            checked={termsAccepted}
                            onChange={(checked) => {
                                setTermsError('');
                                setTermsAccepted(checked);
                            }}
                            disabled={isApiLoading}
                            error={termsError != ''}
                        />
                        {termsError && (
                            <InlineStack gap="200" align="start">
                                <div style={{ transform: 'scale(0.8)' }}>
                                    <Icon source={AlertCircleIcon} />
                                </div>
                                <Text as="p" tone="critical">
                                    {termsError}
                                </Text>
                            </InlineStack>
                        )}
                    </Box>
                    {/* <Checkbox
                        label={
                            <Text as="p">
                                I agree to receive marketing texts/SMS via
                                automated means. Consent not required to
                                proceed. Message rates may apply.{' '}
                                <Text as="span" tone="subdued">
                                    (Optional)
                                </Text>
                            </Text>
                        }
                        checked={marketingSMSAccepted}
                        onChange={(checked) => setMarketingSMSAccepted(checked)}
                        disabled={isApiLoading}
                    /> */}
                    <Divider />
                    <InlineStack blockAlign="center" align="space-between">
                        <Box
                            id="recaptcha-container"
                            paddingBlockStart="200"
                        ></Box>
                        <InlineStack gap="400" blockAlign="center">
                            {isApiLoading ? (
                                <Spinner size="small" />
                            ) : (
                                <Button
                                    variant="primary"
                                    onClick={createAccounts}
                                    disabled={
                                        isPhoneNumberInvalid ||
                                        isApiLoading
                                    }
                                >
                                    Continue
                                </Button>
                            )}
                        </InlineStack>
                    </InlineStack>
                </BlockStack>
            </CreateAccountLayout>
        </BaseLayout>
    );
}