import { useEffect } from 'react';
import {
    Outlet,
    Routes as ReactRouterRoutes,
    Route,
    useLocation,
} from 'react-router-dom';
import {
    LandingPage,
    MobileAppSetupPage,
    PopClipsGridPage,
    CreatePopClipPage,
    PageNotFound,
    MobileAppNotificationPage,
    MobileAppCustomMenuPage,
    MobileAppAppearancePage,
    ComposePushNotification,
    InsightsPage,
    SupportResourcesPage,
    PopClipSettingsPage,
    CreateCustomCarousels,
} from '@/pages';
import shopStore from './stores/shopStore';
import { useSnapshot } from 'valtio';
import {
    RequireAccountsCreated,
    RequireSubscription,
    RequireNotPublishedMobileApp,
    RequireNoSubscription,
    RequireLiveFeatureEnabled,
    RequireAccountNotCreated,
    RequireGetStartedSteps,
} from './hooks/routes';
import {
    mobileRoutes,
    popclipsRoutes,
    vfsRoutes,
} from './constants/routes.constants';
import { billingStore, popclipsStore } from './stores';
import {
    SelectPopclipsAddonPage,
    WelcomePage,
    AccountCreatePage,
    AppLoaderPage,
    VerifyOTPPage,
    GetStartedStepsPage,
    SubscriptionPage,
} from './v2/pages';
import customBannersStore from './stores/customBannersStore';
import { addGoogleFontLink } from './utils/helpers';
import { useStorefrontGraphQL } from './hooks/useStorefrontGraphQL';

export default function Routes() {
    const location = useLocation();
    const { pathname } = location;
    const { isLoading } = useSnapshot(shopStore);
    const { makeQuery } = useStorefrontGraphQL();

    useEffect(() => {
        [mobileRoutes.appearence, mobileRoutes.custom_menu, mobileRoutes.notification].includes(pathname) && addGoogleFontLink();
        customBannersStore.getBannerList();
    }, [pathname]);

    useEffect(() => {
        const initialSetup = async () => {
            if (billingStore.plans.length === 0) {
                billingStore.getPlans();
                billingStore.getAddons();
                billingStore.getFeatures();
            }

            await shopStore.getShopGetStartedStatus();
            await shopStore.getShop(0, makeQuery);
            shopStore.setupZendesk();
            shopStore.getShopFeatureGating();
            popclipsStore.getPopclipsCarousels();
        };

        initialSetup();
    }, []);

    if (isLoading) return <AppLoaderPage />;

    return (
        <ReactRouterRoutes>
            <Route
                path={vfsRoutes.selectPlans}
                element={
                    <RequireNoSubscription>
                        <SelectPopclipsAddonPage />
                    </RequireNoSubscription>
                }
            />
            <Route
                path={vfsRoutes.accountPrefix}
                element={
                    <RequireSubscription>
                        <RequireAccountNotCreated>
                            <Outlet />
                        </RequireAccountNotCreated>
                    </RequireSubscription>
                }
            >
                <Route
                    path={vfsRoutes.createAccount}
                    element={<AccountCreatePage />}
                />
                <Route
                    path={vfsRoutes.verifyAccount}
                    element={<VerifyOTPPage />}
                />
            </Route>
            <Route
                path={vfsRoutes.getStarted}
                element={
                    <RequireSubscription>
                        <RequireAccountsCreated>
                            <GetStartedStepsPage />
                        </RequireAccountsCreated>
                    </RequireSubscription>
                }
            />
            <Route
                path={vfsRoutes.landing}
                element={
                    <RequireSubscription>
                        <RequireAccountsCreated>
                            <RequireGetStartedSteps>
                                <WelcomePage />
                            </RequireGetStartedSteps>
                        </RequireAccountsCreated>
                    </RequireSubscription>
                }
            />
            <Route
                path={vfsRoutes.managePlans}
                element={
                    <RequireSubscription>
                        <RequireAccountsCreated>
                            <SubscriptionPage />
                        </RequireAccountsCreated>
                    </RequireSubscription>
                }
            />
            <Route
                path={vfsRoutes.insights}
                element={
                    <RequireAccountsCreated>
                        <RequireGetStartedSteps>
                            <InsightsPage />
                        </RequireGetStartedSteps>
                    </RequireAccountsCreated>
                }
            />

            <Route
                path={mobileRoutes.prefix}
                element={
                    <RequireSubscription>
                        <RequireAccountsCreated>
                            <RequireGetStartedSteps>
                                <Outlet />
                            </RequireGetStartedSteps>
                        </RequireAccountsCreated>
                    </RequireSubscription>
                }
            >
                <Route
                    path={mobileRoutes.notification}
                    element={<MobileAppNotificationPage />}
                />
                <Route
                    path={mobileRoutes.appearence}
                    element={<MobileAppAppearancePage />}
                />
                <Route
                    path={mobileRoutes.custom_menu}
                    element={<MobileAppCustomMenuPage />}
                />
                <Route
                    path={mobileRoutes.setup}
                    element={
                        <RequireNotPublishedMobileApp>
                            <MobileAppSetupPage />
                        </RequireNotPublishedMobileApp>
                    }
                />
                <Route
                    path={mobileRoutes.composePushNotification}
                    element={
                        <RequireSubscription>
                            <RequireAccountsCreated>
                                <ComposePushNotification />
                            </RequireAccountsCreated>
                        </RequireSubscription>
                    }
                />
            </Route>

            <Route
                path={vfsRoutes.live}
                element={
                    <RequireSubscription>
                        <RequireAccountsCreated>
                            <RequireLiveFeatureEnabled>
                                <RequireGetStartedSteps>
                                    <LandingPage />
                                </RequireGetStartedSteps>
                            </RequireLiveFeatureEnabled>
                        </RequireAccountsCreated>
                    </RequireSubscription>
                }
            />

            <Route
                path={popclipsRoutes.prefix}
                element={
                    <RequireSubscription>
                        <RequireAccountsCreated>
                            <Outlet />
                        </RequireAccountsCreated>
                    </RequireSubscription>
                }
            >
                <Route
                    path={popclipsRoutes.list}
                    element={
                        <RequireGetStartedSteps>
                            <PopClipsGridPage />
                        </RequireGetStartedSteps>
                    }
                />
                <Route
                    path={popclipsRoutes.settings}
                    element={<PopClipSettingsPage />}
                />
                <Route
                    path={popclipsRoutes.create}
                    element={<CreatePopClipPage />}
                />
                <Route
                    path={popclipsRoutes.edit}
                    element={<CreatePopClipPage editMode />}
                />
                <Route
                    path={popclipsRoutes.createCarousel}
                    element={
                        <RequireGetStartedSteps>
                            <CreateCustomCarousels />
                        </RequireGetStartedSteps>
                    }
                />
                <Route
                    path={popclipsRoutes.editCarousel}
                    element={
                        <RequireGetStartedSteps>
                            <CreateCustomCarousels editMode />
                        </RequireGetStartedSteps>
                    }
                />
            </Route>
            <Route path="/resources" element={<SupportResourcesPage />} />
            <Route path="*" element={<PageNotFound />} />
        </ReactRouterRoutes>
    );
}
