import { countAbbreviation } from '@/utils/helpers';
import {
    BlockStack,
    Box,
    Button,
    Icon,
    InlineStack,
    Spinner,
    Text,
} from '@shopify/polaris';
import {
    CheckIcon,
    HeartIcon,
    PlusIcon,
    ViewIcon,
} from '@shopify/polaris-icons';
import { MouseEvent, useRef, useState } from 'react';
import { popclipsLoading } from '../../../assets/images';

type CarouselClipProps = {
    clipData: any;
    selectedClips?: any;
    handleRemoveClip?: (metaIdentifier: string) => void;
    handleSelectClip?: (clipData: any) => void;
    hideSelectBtn?: boolean;
    cursor?: string;
    setLoadedClipID: () => void;
};

export default function CarouselClip({
    clipData,
    selectedClips,
    handleRemoveClip,
    handleSelectClip,
    hideSelectBtn,
    cursor = 'pointer',
    setLoadedClipID,
}: CarouselClipProps) {
    const [isVideoLoading, setIsVideoLoading] = useState(true);
    const [hoverOnClip, setHoverOnClip] = useState<string | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [sourceUrl, setSourceUrl] = useState<string>("");
    const abortController = useRef<AbortController | null>(null);
    const thumbnailUrl = clipData?.cdnUrl ? `${clipData.cdnUrl}${
        clipData.metaData?.customThumbnailImageURL
            ? clipData.metaData.customThumbnailImageURL
            : clipData.metaData?.defaultThumbnailImageURL
              ? clipData.metaData.defaultThumbnailImageURL
              : ''
        }` : '';

    const handleVideoPlayOnHover = (e: MouseEvent) => {
        startDownload();
        const elm = e.target as HTMLVideoElement;
        elm.play();
    };

    const handleVideoResetOnOut = (e: MouseEvent) => {
        cancelDownload();
        const elm = e.target as HTMLVideoElement;
        elm.pause();
        elm.currentTime = 0;
    };

    const startDownload = async () => {
        if (!clipData.metaData.processedURL || sourceUrl) return;
    
        abortController.current = new AbortController();
        const signal = abortController.current.signal;
    
        try {    
          const response = await fetch(`${clipData.cdnUrl}${clipData.metaData.processedURL}`, { signal });
    
          if (!response.ok) throw new Error("Failed to fetch the video");
    
          const blob = await response.blob();
          const blobUrl = URL.createObjectURL(blob);
          setSourceUrl(blobUrl);
    
          if (videoRef.current) {
            videoRef.current.src = blobUrl;
            videoRef.current.load();
            videoRef.current.play().catch((err) => console.log("Autoplay blocked:", err));
          }
        } catch (error) {
          if (signal.aborted) {
            setSourceUrl("");
          }
        }
      };
    
      const cancelDownload = () => {
        setHoverOnClip(null)
        if (abortController.current) {
          abortController.current.abort();
        }
        setSourceUrl("");
        if (videoRef.current) {
          videoRef.current.src = "";
        }
      };

    const handleLoadedData = () => {
        setIsVideoLoading(false);
        if (clipData?.metaIdentifier) {
            setLoadedClipID();
        }
    };

    return (
        <Box
            background="bg-fill"
            borderRadius="200"
            overflowX="hidden"
            overflowY="hidden"
            borderColor="border"
            borderWidth="025"
            shadow="100"
        >
            {clipData?.status !== 'processed' ? (
                <VideoLoader text="Processing..." />
            ) : (
                <>
                    <div style={{ position: 'relative', display: 'block' }}>
                        {isVideoLoading &&
                            hoverOnClip == clipData.metaIdentifier && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        inset: '0',
                                        margin: 'auto',
                                    }}
                                >
                                    <VideoLoader text="Loading..." />
                                </div>
                            )}
                        {hoverOnClip == clipData.metaIdentifier && (
                            <video
                                ref={videoRef}
                                muted
                                loop
                                width="100%"
                                height="242"
                                style={{
                                    objectFit: 'cover',
                                    cursor: cursor,
                                    width: !hideSelectBtn ? '149px' : '100%',
                                    aspectRatio: '16 / 9',
                                    display: 'block',
                                }}
                                onMouseOver={handleVideoPlayOnHover}
                                onMouseOut={handleVideoResetOnOut}
                                onLoadedData={handleLoadedData}
                                onMouseLeave={() => setHoverOnClip(null)}
                                onBlur={() => setHoverOnClip(null)}
                            >
                                <source
                                    src={`${clipData.cdnUrl}${clipData.metaData.thumbnailURL}`}
                                    type="video/mp4"
                                />
                                Your browser does not support the video
                            </video>
                        )}
                        <img
                            src={thumbnailUrl}
                            style={{
                                objectFit: 'cover',
                                cursor: 'pointer',
                                width: !hideSelectBtn ? '149px' : '100%',
                                height: '242px',
                                aspectRatio: '16 / 9',
                                display:
                                    hoverOnClip == clipData.metaIdentifier
                                        ? 'none'
                                        : '',
                            }}
                            onMouseEnter={() =>
                                setHoverOnClip(clipData.metaIdentifier)
                            }
                            onFocus={() =>
                                setHoverOnClip(clipData.metaIdentifier)
                            }
                        />

                        {clipData?.showViews && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    width: '100%',
                                    color: 'white',
                                }}
                            >
                                <Box padding="200">
                                    <InlineStack
                                        align="space-between"
                                        blockAlign="center"
                                    >
                                        {clipData?.showViews && (
                                            <InlineStack
                                                gap="200"
                                                blockAlign="center"
                                            >
                                                <Icon source={ViewIcon} />{' '}
                                                <Text as="p" fontWeight="bold">
                                                    {countAbbreviation(
                                                        clipData?.clipViews ||
                                                            0,
                                                    )}
                                                </Text>
                                            </InlineStack>
                                        )}
                                        {clipData?.showLikes && (
                                            <InlineStack
                                                gap="200"
                                                blockAlign="center"
                                            >
                                                <Icon source={HeartIcon} />{' '}
                                                <Text as="p" fontWeight="bold">
                                                    {countAbbreviation(
                                                        clipData?.clipLikes,
                                                    )}
                                                </Text>
                                            </InlineStack>
                                        )}
                                    </InlineStack>
                                </Box>
                            </div>
                        )}
                    </div>
                </>
            )}
            <Box
                paddingInline="300"
                paddingBlockEnd={!hideSelectBtn ? '300' : '0'}
            >
                <BlockStack align="start" inlineAlign="start" gap="300">
                    <div
                        style={{
                            width: '128px',
                            userSelect: isVideoLoading ? 'none' : 'auto',
                        }}
                    >
                        <Text as="p" truncate>
                            {clipData?.metaData?.videoTitle || ''}
                        </Text>
                    </div>
                    {!hideSelectBtn && (
                        <>
                            {selectedClips.includes(clipData.metaIdentifier) ? (
                                <Button
                                    onClick={() =>
                                        handleRemoveClip &&
                                        handleRemoveClip(
                                            clipData?.metaIdentifier,
                                        )
                                    }
                                    variant="primary"
                                    tone="success"
                                    icon={CheckIcon}
                                >
                                    Selected
                                </Button>
                            ) : (
                                <Button
                                    onClick={() =>
                                        handleSelectClip &&
                                        handleSelectClip(clipData)
                                    }
                                    icon={PlusIcon}
                                >
                                    Select
                                </Button>
                            )}
                        </>
                    )}
                </BlockStack>
            </Box>
        </Box>
    );
}

const VideoLoader = ({ text }: { text: string }) => (
    <Box minHeight="242px" minWidth="149px">
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '248px',
                gap: '4px',
                flexDirection: 'column',
                userSelect: 'none',
                backgroundImage: `url(${popclipsLoading})`,
            }}
        >
            <Spinner size="small" />
            <Text as="p">{text}</Text>
        </div>
    </Box>
);