import { useEffect } from 'react';
import '../../assets/css/VfsModal.scss';
import { Button, InlineStack } from '@shopify/polaris';
import { ArrowLeftIcon, XIcon } from '@shopify/polaris-icons';

type VfsModalProps = {
    onCloseModal?: () => void;
    title: string;
    children: React.ReactNode;
    primaryAction?: () => void;
    primaryActionText?: string;
    primaryActionTone?: 'primary' | 'success';
    modalWidth?: string;
    btnFullWidth?: boolean;
    closeable?: boolean;
    flexDirection?:
        | 'row'
        | 'row-reverse'
        | 'column'
        | 'column-reverse'
        | 'unset';
    crossIcon?: boolean;
    secondaryAction?: () => void;
    secondaryActionText?: string;
    header?: string;
};

export default function VfsModal({
    onCloseModal,
    title,
    children,
    primaryAction,
    primaryActionText = 'Click me',
    primaryActionTone = 'primary',
    modalWidth,
    closeable = true,
    btnFullWidth = false,
    flexDirection = 'unset',
    crossIcon,
    secondaryAction,
    secondaryActionText,
    header ='default'
}: VfsModalProps) {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        if (closeable) {
            document.addEventListener('keydown', hideOnEscPress);
            setTimeout(() => {
                document.body.addEventListener('click', hideOnOutsideClick);
            });
        }

        return () => {
            document.body.style.overflow = 'auto';
            document.body.removeEventListener('click', hideOnOutsideClick);
            document.removeEventListener('keydown', hideOnEscPress);
        };
    }, []);

    const hideOnEscPress = (e) => {
        if (e.key === 'Escape' && onCloseModal) {
            onCloseModal();
        }
    };
    const hideOnOutsideClick = (e) => {
        if (!e.target.closest('.vfsModal__container') && onCloseModal) {
            onCloseModal();
        }
    };

    return (
        <div className="vfsModal__backdrop">
            <div
                className="vfsModal__container"
                style={{ width: modalWidth ? modalWidth : '80rem' }}
            >
                <div
                    className={
                        header === 'secondary'
                            ? 'vfsModal__header_second'
                            : 'vfsModal__header'
                    }
                >
                    {crossIcon ? (
                        <InlineStack
                            gap="300"
                            align="space-between"
                            blockAlign="center"
                        >
                            <p className="vfsModal__title">{title}</p>
                            {crossIcon && (
                                <Button
                                    icon={XIcon}
                                    variant="plain"
                                    onClick={onCloseModal}
                                />
                            )}
                        </InlineStack>
                    ) : (
                        <InlineStack
                            gap="300"
                            align="start"
                            blockAlign="center"
                        >
                            {closeable && (
                                <Button
                                    icon={ArrowLeftIcon}
                                    variant="plain"
                                    onClick={onCloseModal}
                                />
                            )}
                            <p className="vfsModal__title">{title}</p>
                        </InlineStack>
                    )}
                </div>
                <div
                    className="vfsModal__content"
                    style={{ flexDirection: flexDirection }}
                >
                    {children}
                </div>
                {primaryAction && (
                    <div className="vfsModal__footer">
                        {secondaryAction && (
                            <button
                                className="vfsModal__secondary__button"
                                onClick={secondaryAction}
                                style={{
                                    width: btnFullWidth ? '100%' : 'auto',
                                }}
                            >
                                {secondaryActionText}
                            </button>
                        )}
                        <button
                            className={`vfsModal__${primaryActionTone}__button`}
                            onClick={primaryAction}
                            style={{ width: btnFullWidth ? '100%' : 'auto' }}
                        >
                            {primaryActionText}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}