import { analyticsEventStore, billingStore, shopStore } from "@/stores";
import AlertBannerComponent from "../AlertBannerComponent";
import { useSnapshot } from "valtio";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { vfsRoutes } from "@/constants/routes.constants";
import VfsModal from "../VfsModal";
import PlanCartComponent from "@/components/plans/PlanCartComponent";
import { useStorefrontGraphQL } from "@/hooks/useStorefrontGraphQL";

type PlanChangeBannerProps = {
    isDisabled?: boolean;
};

export default function PlanChangeBanner({ isDisabled }: PlanChangeBannerProps) {
    const navigate = useNavigate();
    const { makeQuery } = useStorefrontGraphQL();
    const { pendingSubscription } = useSnapshot(shopStore);
    const [isApiLoading, setIsApiLoading] = useState(false);
    const [shopPlanBreakUpModal, setShopPlanBreakUpModal] = useState(false);

    const onPlanChangeAccepted = async () => {
        setShopPlanBreakUpModal(false);
        setIsApiLoading(true);
        await handleCheckoutPlan();
    }
    
    const handleCheckoutPlan = async () => {
        setIsApiLoading(true);
        await analyticsEventStore.accountCheckoutSubscriptionPlan('UPGRADE_ACTION', 'Accept Plan Change', '//*[@id="app"]/div[1]/div/div/div[1]/div/div[2]/div/div[1]/div[1]/div/div/div[2]/div/button');
        const response = await billingStore.getPlanPurchaseUrl(pendingSubscription?.id);

        if (response) {
            if (response.confirmationUrl) {
                open(decodeURIComponent(response.confirmationUrl), '_top');
            } else if (response.subscription.isStubSubscription) {
                await shopStore.getShop(0, makeQuery);
                navigate(vfsRoutes.landing);
            }
        } else {
            setIsApiLoading(false);
            shopify.toast.show('Failed to checkout. Please contact support.', {
                isError: true,
                duration: 5000,
            });
        }
    };

    return (
        <div style={{ marginBlock: pendingSubscription ? '10px' : 'unset' }}>
            {pendingSubscription && (
                <AlertBannerComponent
                    type="info"
                    title="You have a pending plan change"
                    message="You have a pending plan change, please accept to continue using Videeo."
                    buttonText="Review Plan Change Summary"
                    primaryAction={() => setShopPlanBreakUpModal(true)}
                    size="large"
                    loading={isApiLoading || isDisabled}
                />
            )}
            {shopPlanBreakUpModal && (
                <VfsModal
                    onCloseModal={() => setShopPlanBreakUpModal(false)}
                    primaryAction={onPlanChangeAccepted}
                    primaryActionText="Accept Plan Change"
                    title="Plan Change Summary"
                    modalWidth="30rem"
                >
                    <PlanCartComponent
                        shopSubscriptionDraft={pendingSubscription.draft}
                    />
                </VfsModal>
            )}
        </div>
    );
}