import { proxy } from 'valtio';
import {
    ATTRIBUTE_IDS,
    PLAN_SLIDER_INFINITY,
} from '@/constants/planFeatureCard.constants';
import billingStore from './billingStore';
import { fetchPost, isQaEnv } from '@/utils/helpers';
import analyticsEventStore from './analyticsEventStore';
import { BYPASS_GATING_FOR_DOMAINS } from '@/constants/shopify.constants';
import { gatingDataForQA } from '@/utils/GatingDataForQA';
import { customBannersStore } from '.';

type FirebaseAuth = ReturnType<typeof import('firebase/auth').getAuth>;

const OLD_BASE_PLAN_SKU = 'bundle-flexible-monthly';
export const BASE_PLAN_SKU = 'bundle-flexible-2024-monthly';
export const BASE_PLAN_SKU_2025 = 'bundle-flexible-2025-monthly';
export const ADDON_POPCLIPS_FREE = 'addon-popclips-free';
export const NEW_ADDON_POPCLIPS_FREE = 'addon-popclips-free-2025';
const CONNECTION_TYPE_COMMENTSOLD = 'commentsold';
const CONNECTION_TYPE_POPSHOPLIVE = 'popshoplive';

let planIdAbortController = new AbortController();
let firebaseAuth: FirebaseAuth | null = null;

declare global {
    interface Window {
        zE: any;
        zESettings: {
            webWidget: {
                authenticate?: {
                    chat?: {
                        jwtFn: (callback: (jwt: string) => void) => void;
                    };
                };
                color: {
                    theme: string;
                };
                contactForm: {
                    attachments: boolean;
                    subject: boolean;
                    fields: { id: string; prefill: { '*': string } }[];
                    title: {
                        '*': string;
                    };
                };
            };
        };
    }
}

export interface ShopConfig {
    pslFirebaseConfig: {
        apiKey: string;
        appId: string;
        authDomain: string;
        databaseURL: string;
        measurementId: string;
        messagingSenderId: string;
        projectId: string;
        storageBucket: string;
    };
    legacyVfsService: {
        apiUrl: string;
    };
    legacyPopclipsSignup: {
        apiUrl: string;
    };
    mediaGallery: {
        apiUrl: string;
    };
    csDataLakeConfig: {
        apiUrl: string;
    };
    firebaseAdminBaseUrl: {
        apiUrl: string;
    };
    zendesk: {
        key: string;
    };
}

const getShopPlanById = async (planId: string) => {
    try {
        planIdAbortController.abort();
        planIdAbortController = new AbortController();

        const result = await fetch(`/api/billing/plans/${planId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            signal: planIdAbortController.signal,
        });

        return await result.json();
    } catch (error) {
        return null;
    }
};

type GetStartedSteps =
    | 'STEP_ADD_YOUR_FIRST_POPCLIP'
    | 'STEP_ENABLE_VIDEEO_SDK'
    | 'STEP_PUBLISH_PRODUCTS_TO_VIDEEO_SC'
    | 'STEP_PUBLISH_YOUR_FIRST_POPCLIP_CAROUSEL';

export interface ShopStoreState {
    isLoading: boolean;
    shopifyDomain: string;
    ownerName: string;
    ownerEmail: string;
    featuredPopclipId: string;
    onBoardedOn: string;
    csToken: string;
    storefrontAccessToken: string;
    popshopliveSellerId: string;
    shopHandle: string;
    dashboardUrl: string;
    csStoreCreated: boolean;
    popshopliveAccountCreated: boolean;
    embedEnabled: boolean;
    livePageCreated: boolean;
    subscription: {
        subscriptionSubtotal: {
            amount: string;
            currencyCode: string;
        };
        subscriptionTotal: {
            amount: string;
            currencyCode: string;
        };
    } | null;
    subscriptionActive: boolean;
    appEmbedEnableUrl: string;
    popclipCarouselEmbedEnableUrl: string;
    activePlan: any;
    pendingSubscription: any;
    config?: ShopConfig;
    enabledFeatures: string[];
    shopName?: string;
    featureGatingData: Record<string, any>;
    popclipsServiceApi: string;
    appRequestId: string | null;
    appRequestSkIdentifier: string | null;
    getStartedStatus: {
        completionPercentage: number;
        events: {
            STEP_ADD_YOUR_FIRST_POPCLIP: boolean;
            STEP_ENABLE_VIDEEO_SDK: boolean;
            STEP_PUBLISH_PRODUCTS_TO_VIDEEO_SC: boolean;
            STEP_PUBLISH_YOUR_FIRST_POPCLIP_CAROUSEL: boolean;
        };
    };
    featureFlags: {
        looker_dashboard: boolean;
        popclips_analytics_dashboard: boolean;
        deactivate_shop: boolean;
        disable_push_notification: boolean;
    };
    publishedProductsToSalesChannelCount: number;
    getShop: (retryCount?: number, sfGraphQlClient?: any) => Promise<void>;
    isEmbedEnabled: () => Promise<boolean>;
    createLiveShoppingPage: () => Promise<boolean>;
    isEmailAvailable: (email: string) => Promise<boolean>;
    isPhoneAvailable: (phone: string) => Promise<boolean>;
    createCoreStoreAccount: (email: string) => Promise<boolean>;
    isCoreAccountCreated: () => Promise<boolean>;
    getActivePlanAddonValue: (
        featureType: string,
        includePlanAddons?: boolean,
    ) => any;
    getActivePlanAddonPrices: (
        featureType: string,
        includePlanAddons?: boolean,
    ) => [];
    setupZendesk: () => void;
    getShopFeatureGating: () => void;
    getShopGetStartedStatus: () => Promise<void>;
    updateGetStartedStatus: (step: GetStartedSteps) => Promise<void>;
    getFirebaseAuth: () => Promise<FirebaseAuth | undefined>;
    getActivePlanExtraAddonSKUs: () => string[];
    progressPercentage: number;
    isVideeoSdkAndLivePageEnabled: boolean;
    isProductsPublishedToSalesChannel: boolean;
    isFirstPopclipCreated: boolean;
    isFirstPopclipCarouselPublished: boolean;
    shouldLimitPopclipsAccess: boolean;
    shouldShowViewsLimitModal: boolean;
    isFreePlan:boolean;
}

const shopStore: ShopStoreState = proxy<ShopStoreState>({
    publishedProductsToSalesChannelCount: 0,
    isLoading: true,
    shopifyDomain: '',
    ownerName: '',
    ownerEmail: '',
    featuredPopclipId: '',
    onBoardedOn: '',
    csToken: '',
    storefrontAccessToken: '',
    popshopliveSellerId: '',
    shopHandle: '',
    dashboardUrl: '',
    csStoreCreated: false,
    popshopliveAccountCreated: false,
    embedEnabled: false,
    livePageCreated: false,
    subscriptionActive: false,
    appEmbedEnableUrl: '',
    popclipCarouselEmbedEnableUrl: '',
    config: undefined,
    pendingSubscription: null,
    subscription: null,
    activePlan: null,
    enabledFeatures: [],
    shopName: '',
    featureGatingData: [],
    popclipsServiceApi: '',
    appRequestId: null,
    appRequestSkIdentifier: null,
    getStartedStatus: {
        completionPercentage: 0,
        events: {
            STEP_ENABLE_VIDEEO_SDK: false,
            STEP_PUBLISH_PRODUCTS_TO_VIDEEO_SC: false,
            STEP_ADD_YOUR_FIRST_POPCLIP: false,
            STEP_PUBLISH_YOUR_FIRST_POPCLIP_CAROUSEL: false,
        },
    },
    featureFlags: {
        looker_dashboard: false,
        popclips_analytics_dashboard: false,
        deactivate_shop: false,
        disable_push_notification: false,
    },
    getShop: async (retryCount = 0, sfGraphQlClient = null) => {
        shopStore.isLoading = true;
        try {
            const shopResponse = await fetch('/api/shopify/shop', {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const shop = await shopResponse.json();
            if (
                !shop.subscriptionActive &&
                shop.subscription &&
                retryCount < 4 // this is to prevent infinite loop
            ) {
                return shopStore.getShop(retryCount + 1, sfGraphQlClient);
            }
            shopStore.shopifyDomain = shop.shopifyDomain;
            shopStore.csToken = shop.csToken;
            shopStore.storefrontAccessToken = shop.storefrontAccessToken;
            shopStore.dashboardUrl = shop.dashboardUrl;
            shopStore.embedEnabled = shop.embedEnabled;
            shopStore.livePageCreated = shop.livePageCreated;
            shopStore.appEmbedEnableUrl = shop.appEmbedEnableUrl;
            shopStore.popclipCarouselEmbedEnableUrl =
                shop.popclipCarouselEmbedEnableUrl;
            shopStore.config = shop.config;
            shopStore.shopHandle = shop.shopHandle;
            shopStore.subscriptionActive = shop.subscriptionActive;
            shopStore.subscription = shop.subscription;
            shopStore.pendingSubscription = shop.pendingSubscription;
            shopStore.shopName = shop?.shop?.name || '';
            shopStore.ownerName = shop?.shop?.ownerName || '';
            shopStore.ownerEmail = shop?.shop?.ownerEmail || '';
            shopStore.popclipsServiceApi =
                shop?.config?.popclipsService?.apiUrl || '';
            shopStore.featureFlags = shop?.featureFlags;
            shopStore.featuredPopclipId = shop?.shop?.featuredPopclipId || null;
            shopStore.appRequestId = shop?.shop?.appRequestId || null;
            shopStore.appRequestSkIdentifier =
                shop?.shop?.appRequestSkIdentifier || null;

            if (
                shopStore.embedEnabled &&
                shopStore.livePageCreated &&
                !shopStore.getStartedStatus.events.STEP_ENABLE_VIDEEO_SDK
            ) {
                analyticsEventStore.onboardingCompleteVideoSDK();
                shopStore.updateGetStartedStatus('STEP_ENABLE_VIDEEO_SDK');
            }

            if (shop?.shop?.installedAt) {
                shopStore.onBoardedOn = shop?.shop?.installedAt;
            } else if (shop?.shop?.shopifyShopCreatedAt) {
                shopStore.onBoardedOn = shop?.shop?.shopifyShopCreatedAt;
            } else {
                shopStore.onBoardedOn =
                    shopStore?.subscription?.createdAt || '';
            }

            if (shop.subscription && shop.subscription.planId) {
                const shopPlan = await getShopPlanById(
                    shop.subscription.planId,
                );

                shopStore.activePlan = shopPlan;
            }

            billingStore.selectedPlanSku = BASE_PLAN_SKU_2025;

            if (
                shopStore.activePlan &&
                shopStore.activePlan.skuCode === BASE_PLAN_SKU
            ) {
                billingStore.selectedPlanSku = BASE_PLAN_SKU;
            } else if (
                shopStore.activePlan &&
                shopStore.activePlan.skuCode === OLD_BASE_PLAN_SKU
            ) {
                billingStore.selectedPlanSku = OLD_BASE_PLAN_SKU;
            }

            if (shop.enabledFeatures) {
                shopStore.enabledFeatures = shop.enabledFeatures;
            }
            const csStore = shop.connections.find(
                ({ type }: any) => type === CONNECTION_TYPE_COMMENTSOLD,
            );
            const popshopliveAccount = shop.connections.find(
                ({ type }: any) => type === CONNECTION_TYPE_POPSHOPLIVE,
            );
            if (popshopliveAccount) {
                shopStore.popshopliveSellerId = popshopliveAccount.sellerId;
                shopStore.popshopliveAccountCreated = true;
            }
            if (csStore) {
                shopStore.csStoreCreated = true;
            }

            if (sfGraphQlClient) {
                const data = await sfGraphQlClient({
                    query: `query { products(first: 1){ nodes { id } } }`,
                });
                shopStore.publishedProductsToSalesChannelCount =
                    data?.products?.nodes?.length || 0;
            }
        } catch (e) {
            shopify.toast.show(
                'Could not load store state. Please contact support.',
                { isError: true, duration: 5000 },
            );
            console.error(
                'Could not load store state. Please contact support.',
                e,
            );
        }
        shopStore.isLoading = false;
        customBannersStore.getBannerList();
    },
    getFirebaseAuth: async () => {
        if (firebaseAuth) return firebaseAuth;
        if (shopStore.popshopliveAccountCreated) return;

        const config = shopStore.config?.pslFirebaseConfig;
        if (!config) return;

        const { getAuth } = await import('firebase/auth');
        const { initializeApp } = await import('firebase/app');

        firebaseAuth = getAuth(
            initializeApp({
                apiKey: config.apiKey,
                appId: config.appId,
                authDomain: config.authDomain,
                databaseURL: config.databaseURL,
                projectId: config.projectId,
                storageBucket: config.storageBucket,
                measurementId: config.measurementId,
                messagingSenderId: config.messagingSenderId,
            }),
        );

        firebaseAuth.useDeviceLanguage();
        return firebaseAuth;
    },
    isEmbedEnabled: async () => {
        try {
            const result = await fetch('/api/shopify/shop/embed', {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!result.ok) throw new Error("Couldn't check embed status");

            const response = await result.json();
            shopStore.embedEnabled = response.embedEnabled;
            return response.embedEnabled;
        } catch (error) {
            console.error("Couldn't check embed status");
            return false;
        }
    },
    createLiveShoppingPage: async () => {
        try {
            const result = await fetch('/api/shopify/shop/live-page', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!result.ok)
                throw new Error("Couldn't create live shopping page");

            const response = await result.json();
            shopStore.livePageCreated = response.livePageCreated;
            return response.livePageCreated;
        } catch (error) {
            console.error("Couldn't create live shopping page");
            return false;
        }
    },
    isEmailAvailable: async (email) => {
        try {
            const result = await fetch(
                '/api/shopify/shop/connections/commentsold/check-email',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email }),
                },
            );

            const response = await result.json();
            return response.emailAvailable;
        } catch (error) {
            return false;
        }
    },
    isPhoneAvailable: async (phone) => {
        try {
            const url = `${shopStore.config?.firebaseAdminBaseUrl.apiUrl}/api/firebase/check-phone?phone=${phone}`;
            const result = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const response = await result.json();
            return response?.data?.is_phone_available;
        } catch (error) {
            return false;
        }
    },
    createCoreStoreAccount: async (email) => {
        try {
            const result = await fetch(
                '/api/shopify/shop/connections/commentsold',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email }),
                },
            );

            await result.json();
            return true;
        } catch (error) {
            return false;
        }
    },
    isCoreAccountCreated: async () => {
        try {
            const result = await fetch(
                '/api/shopify/shop/connections/commentsold',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            );

            const response = await result.json();
            if (!response?.dashboardUrl) return false;

            shopStore.dashboardUrl = response.dashboardUrl;
            shopStore.csStoreCreated = true;
            return true;
        } catch (error) {
            return false;
        }
    },
    getActivePlanAddonValue: (featureType, includePlanAddons = false) => {
        let includedAddons = [];
        const subscriptionLineItems = shopStore.subscription?.lineItems;
        let extraAddons = subscriptionLineItems
            ? subscriptionLineItems?.filter((item) => item.type == 'addon')
            : [];
        let allAddons = extraAddons.map((item) => item.item);

        if (includePlanAddons) {
            let basePlan = subscriptionLineItems
                ? subscriptionLineItems?.find((item) => item.type == 'plan')
                : [];
            basePlan = basePlan ? basePlan.item : null;
            includedAddons = basePlan?.addons ?? [];
            allAddons = [...allAddons, ...includedAddons];
        }

        const addon = allAddons?.find(
            (addon) => addon.featureId == featureType,
        );
        const addonAttr = addon
            ? addon.attributes.find(
                  (attr) => attr.attributeId == ATTRIBUTE_IDS[featureType],
              )
            : null;

        if (addon && addonAttr) {
            let value =
                addonAttr.value == '-1'
                    ? PLAN_SLIDER_INFINITY
                    : Math.round(parseInt(addonAttr.value));

            return { addon, value };
        } else {
            return null;
        }
    },
    getActivePlanAddonPrices: (featureType, includePlanAddons = false) => {
        let includedAddons = [];
        const subscriptionLineItems = shopStore.subscription?.lineItems;
        let extraAddons = subscriptionLineItems
            ? subscriptionLineItems?.filter((item) => item.type == 'addon')
            : [];
        let allAddons = extraAddons.map((item) => item.item);

        if (includePlanAddons) {
            let basePlan = subscriptionLineItems
                ? subscriptionLineItems?.find((item) => item.type == 'plan')
                : [];
            basePlan = basePlan ? basePlan.item : null;
            includedAddons = basePlan?.addons ?? [];
            allAddons = [...allAddons, ...includedAddons];
        }

        const addons = allAddons?.filter(
            (addon) => addon.featureId == featureType,
        );
        return addons.map((addon) => +addon.price[0].amount) || [];
    },
    setupZendesk: async () => {
        window.zESettings = {
            webWidget: {
                authenticate: {
                    chat: {
                        async jwtFn(callback) {
                            const result = await fetch(
                                '/api/shopify/shop/zendesk/token',
                            );
                            if (result.ok) {
                                const response = await result.json();
                                callback(response?.data?.zendeskToken?.jwt);
                            }
                        },
                    },
                },
                color: {
                    theme: '#303030',
                },
                contactForm: {
                    attachments: false,
                    subject: true,
                    fields: [
                        {
                            id: 'email',
                            prefill: { '*': `${shopStore.ownerEmail} ` },
                        },
                        {
                            id: 'name',
                            prefill: { '*': `${shopStore.ownerName} ` },
                        },
                        {
                            id: 'tags',
                            prefill: { '*': shopStore.shopName || '' },
                        },
                        {
                            id: 'subject',
                            prefill: { '*': `[${shopStore.shopName}] ` },
                        },
                    ],
                    title: {
                        '*': 'Contact Help Center',
                    },
                },
            },
        };

        const zendeskKey = shopStore.config?.zendesk?.key;
        if (zendeskKey && !document.getElementById('ze-snippet')) {
            await createRuntimeScript({
                src: `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`,
                id: 'ze-snippet',
            });

            window.zE(() => {
                window.zE.hide();
            });

            if (!isQaEnv) {
                window.zE('webWidget', 'helpCenter:reauthenticate');
            }
        }
    },
    getShopFeatureGating: async () => {
        try {
            if (process.env.NODE_ENV === 'production') {
                const response = await fetch(
                    '/api/shopify/shop/popclips/gating',
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                );

                if (!response.ok) {
                    throw new Error('Failed to fetch feature gating data');
                }

                const { data } = await response.json();
                const maxViews =
                    data && data?.max_views
                        ? data.max_views
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : '0';
                const totalViews =
                    data && data?.total_views
                        ? data.total_views
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : '0';

                shopStore.featureGatingData['popclips'] = {
                    maxViews,
                    totalViews,
                    limitAccess: BYPASS_GATING_FOR_DOMAINS.includes(
                        shopStore.shopifyDomain,
                    )
                        ? false
                        : data.limit_access,
                    startDateOfViewsCount: data.start_date_of_views_count,
                    typeOfDate: data.type_of_date,
                };
            } else {
                const gatingData = await gatingDataForQA();
                if (gatingData) {
                    shopStore.featureGatingData['popclips'] = gatingData;
                }
            }
        } catch (error) {
            console.log(error);
        }
    },
    getShopGetStartedStatus: async () => {
        try {
            const result = await fetch('/api/shopify/shop/app-setup-wizard', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!result.ok) {
                throw new Error('Failed to fetch get started status');
            }

            const { data } = await result.json();
            shopStore.getStartedStatus.completionPercentage =
                data.completion_percentage;
            shopStore.getStartedStatus.events = data.events;
        } catch (error) {
            console.log(error);
        }
    },
    updateGetStartedStatus: async (step) => {
        try {
            await fetchPost('/api/shopify/shop/app-setup-wizard', {
                event: step,
            });

            shopStore.getStartedStatus.events[step] = true;
            shopStore.getStartedStatus.completionPercentage += 25;
        } catch (error) {
            console.log(error);
        }
    },
    getActivePlanExtraAddonSKUs: () => {
        try {
            const subData: any = shopStore.subscription;
            if (!subData && subData.lineItems)
                throw new Error('Subscription data not found');
            const extraAddons = subData.lineItems
                .filter((item: any) => item.type === 'addon')
                .map((row: any) => row.item);
            const formattedExtraAddons =
                extraAddons.length > 0
                    ? billingStore.getFormattedAddonsForCart(extraAddons)
                    : [];
            return formattedExtraAddons.map(({ skuCode }: any) => skuCode);
        } catch (error) {
            return [];
        }
    },
    get progressPercentage() {
        let progress = 0;

        if (shopStore.isVideeoSdkAndLivePageEnabled) {
            progress += 25;
        }

        if (shopStore.isProductsPublishedToSalesChannel) {
            progress += 25;
        }

        if (shopStore.isFirstPopclipCreated) {
            progress += 25;
        }

        if (shopStore.isFirstPopclipCarouselPublished) {
            progress += 25;
        }

        return progress;
    },
    get isVideeoSdkAndLivePageEnabled() {
        return (
            shopStore.getStartedStatus.events.STEP_ENABLE_VIDEEO_SDK &&
            shopStore.embedEnabled &&
            shopStore.livePageCreated
        );
    },
    get isProductsPublishedToSalesChannel() {
        return (
            shopStore.getStartedStatus.events
                .STEP_PUBLISH_PRODUCTS_TO_VIDEEO_SC &&
            shopStore.publishedProductsToSalesChannelCount > 0
        );
    },
    get isFirstPopclipCreated() {
        return shopStore.getStartedStatus.events.STEP_ADD_YOUR_FIRST_POPCLIP;
    },
    get isFirstPopclipCarouselPublished() {
        return shopStore.getStartedStatus.events
            .STEP_PUBLISH_YOUR_FIRST_POPCLIP_CAROUSEL;
    },
    get shouldLimitPopclipsAccess() {
        if (
            !shopStore.featureGatingData?.popclips ||
            shopStore.featureGatingData.popclips.maxViews === '-1'
        )
            return false;
        return shopStore.featureGatingData.popclips.limitAccess;
    },
    get shouldShowViewsLimitModal() {
        if (
            !shopStore.featureGatingData?.popclips ||
            shopStore.featureGatingData.popclips.maxViews === '-1'
        )
            return false;
        if (shopStore.featureGatingData.popclips.limitAccess) return false;
        const popclipsGating = shopStore.featureGatingData.popclips;
        const popclipsMaxViews = popclipsGating.maxViews;
        const popclipsTotalViews = popclipsGating.totalViews.replace(/,/g, '');

        if (!popclipsMaxViews || !popclipsTotalViews) return false;

        const maxViews = Number(popclipsMaxViews.replace(/,/g, ''));
        const percentage = (Number(popclipsTotalViews) / maxViews) * 100;

        const isMaxViewMatching = billingStore.addons
            .filter(({ skuCode }) =>
                [
                    'addon-popclips-basic',
                    'addon-popclips-free-2025',
                    'addon-popclips-free',
                ].includes(skuCode),
            )
            .map(
                ({ attributes }: any) =>
                    attributes.find(
                        ({ attributeId }: any) =>
                            attributeId === ATTRIBUTE_IDS.popclips,
                    )?.value,
            )
            .some(
                (value: string) => value == popclipsMaxViews.replace(/,/g, ''),
            );

        return percentage >= 80 && isMaxViewMatching;
    },
    get isFreePlan() {
        if (
            !shopStore.featureGatingData?.popclips ||
            shopStore.featureGatingData.popclips.maxViews === '-1'
        )
            return false;

        const isFreePlan = billingStore.addons
            .filter(({ skuCode }) =>
                ['addon-popclips-free-2025', 'addon-popclips-free'].includes(
                    skuCode,
                ),
            )
            .map(
                ({ attributes }: any) =>
                    attributes.find(
                        ({ attributeId }: any) =>
                            attributeId === ATTRIBUTE_IDS.popclips,
                    )?.value,
            )
            .some(
                (value: string) =>
                    value ==
                    shopStore.featureGatingData.popclips.maxViews.replace(
                        /,/g,
                        '',
                    ),
            );

        return isFreePlan ;
    },
});

const createRuntimeScript = ({ src, id }: { src: string; id: string }) =>
    new Promise<void>((resolve, reject) => {
        const script: HTMLScriptElement = document.createElement('script');
        script.src = src;
        script.id = id;
        script.setAttribute('async', 'true');
        script.addEventListener('load', () => resolve());
        script.addEventListener('error', () => reject());
        document.head.appendChild(script);
    });

export default shopStore;
