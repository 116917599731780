import { Box, Text } from "@shopify/polaris";
import VfsModal from "../../v2/Components/VfsModal";
import { Dispatch, SetStateAction } from 'react';

type notificationBlockModalProps = {
    setShowNotificationBlockModal: Dispatch<SetStateAction<boolean>>;
    primaryAction:() => void;
};
export const NotificationBlockModal = ({
    setShowNotificationBlockModal,
    primaryAction,
}: notificationBlockModalProps) => {
    return (
        <VfsModal
            onCloseModal={() => setShowNotificationBlockModal(false)}
            title=""
            modalWidth="32rem"
            closeable={false}
            crossIcon={false}
            primaryAction={() => primaryAction()}
            primaryActionText={'OK'}
            header="secondary"
        >
            <Box>
                <Box
                    background="bg-surface-info-active"
                    borderRadius="200"
                    padding={'500'}
                >
                    <Text
                        as="p"
                        fontWeight="semibold"
                        variant="headingSm"
                        alignment="center"
                    >
                        Kindly update your Apple Developer Agreement in order to
                        enable push notifications for your mobile app.
                    </Text>
                </Box>
                <Box paddingBlockStart={'400'}>
                    <Text
                        as="p"
                        variant="headingSm"
                        alignment="center"
                        fontWeight="semibold"
                    >
                        Once updated please reach out to support for more
                        information.
                    </Text>
                </Box>
            </Box>
        </VfsModal>
    );
};