import { BlockStack, Button, InlineStack, Spinner } from "@shopify/polaris";
import { useEffect, useState } from "react";
import CarouselForm from "./carouselComponents/CarouselForm";
import CarouselClipSelector from "./carouselComponents/CarouselClipSelector";
import CarouselClipModal from "./carouselComponents/CarouselClipModal";
import { analyticsEventStore, popclipsStore, shopStore } from '@/stores';
import CarouselPublishModal from "./carouselComponents/CarouselPublishModal";
import { useSnapshot } from "valtio";
import { useNavigate } from "react-router-dom";
import { popclipsRoutes } from "@/constants/routes.constants";
import { BaseLayout } from "../../v2/pages/Layout/BaseLayout";
import SocialMediaPlanUpdateModal from "./SocialMediaPlanUpdateModal";
import { CUSTOM_CAROUSAL } from "@/constants/planFeatureCard.constants";
import PopClipsCard from "@/components/common/PopClipsCard";
import CarouselClipsPreview from "./carouselComponents/CarouselClipsPreview";
import { COLLECTION, CUSTOM } from "@/constants/popclipsFeature.constants";
import { BYPASS_GATING_FOR_DOMAINS } from "@/constants/shopify.constants";
import { shopifyIdFromGid } from "@/utils/GidHelper";

export default function CreateCustomCarousels({ editMode }: { editMode?: boolean }) {
    const navigate = useNavigate();
    const {carouselToEdit, clipsForEditCarousel} = useSnapshot(popclipsStore);
    
    const [title, setTitle] = useState('');
    const [tempTitle, setTempTitle] = useState('');
    const [selectedClips, setSelectedClips] = useState<string[]>([]);
    const [clipsObjsArr, setClipsObjsArr] = useState<any[]>([]);
    const [carouselType, setCarouselType] = useState<string>('custom')
    const [isCollectionPopclipsLoading, setIsCollectionPopclipsLoading] = useState(false);
    const [selectedCollectionID, setSelectedCollectionID] = useState<string[]>([])
    const [collectionVideoPopclips, setCollectionVideoPopclips] = useState([]);
    const [sortedPopclipsIds, setSortedPopclipsIds] = useState<string[]>([]);
    const [sortedCollectionIds, setSortedCollectionIds] = useState<string[]>([]);
    const [collectionName, setCollectionName] = useState<string>('');

    const [carouselId, setCarouselId] = useState('');
    const [isPublished, setIsPublished] = useState(false);
    const [isApiLoading, setIsApiLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openPublishModal, setOpenPublishModal] = useState(false);
    const [showPlanUpgradeModal, setShowPlanUpgradeModal] = useState(false);

    useEffect(() => {
        if (editMode && carouselId == '') {
            if ((carouselToEdit && clipsForEditCarousel.length > 0) || (carouselToEdit?.carousel_type == COLLECTION)) {
                setCarouselId(popclipsStore.carouselToEdit.carousel_id);
                setTitle(popclipsStore.carouselToEdit.carousel_title);
                setTempTitle(popclipsStore.carouselToEdit.carousel_title);
                setSelectedClips(carouselToEdit?.carousel_type_ids || []);
                setClipsObjsArr(popclipsStore.clipsForEditCarousel);
                setIsPublished(popclipsStore.carouselToEdit.is_published);
                setCarouselType(popclipsStore.carouselToEdit.carousel_type)
                if(carouselToEdit.carousel_type == COLLECTION){
                    setSelectedCollectionID(popclipsStore.carouselToEdit.carousel_type_ids)
                    if(popclipsStore.carouselToEdit?.sorted_popclips_ids){
                        if(popclipsStore.carouselToEdit?.carousel_type == CUSTOM){
                            setSortedPopclipsIds(popclipsStore.carouselToEdit.sorted_popclips_ids)
                        }
                        if(popclipsStore.carouselToEdit?.carousel_type == COLLECTION){
                            setSortedCollectionIds(popclipsStore.carouselToEdit.sorted_popclips_ids)
                        }
                    }
                }
            } else {
                navigate(popclipsRoutes.list);
            }
        }
    }, [editMode, carouselToEdit, clipsForEditCarousel]);

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => e.preventDefault();
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            popclipsStore.resetPopclipsStore();
        };
    }, []);

    useEffect(() => {
        if (BYPASS_GATING_FOR_DOMAINS.includes(shopStore.shopifyDomain)) return;

        const addonPrices = shopStore.getActivePlanAddonPrices('popclips', true);
        const maxPrice = Math.max(...addonPrices);
        if (maxPrice < 29.99) navigate(popclipsRoutes.list);
    },[]);

    const onHandleSelectClip = (clipsData: any) => {
        if(carouselType == COLLECTION){
            setCollectionVideoPopclips(clipsData);
            setSortedCollectionIds(clipsData.map(({ metaIdentifier }: any) => metaIdentifier));
        } else { 
            setClipsObjsArr(clipsData);
            setSelectedClips(clipsData.map(({ metaIdentifier }: any) => metaIdentifier));
            setSortedPopclipsIds(clipsData.map(({ metaIdentifier }: any) => metaIdentifier))
        }
    };

    const handleCollectionVideoPopclips = (videoClips) => {
        setCollectionVideoPopclips(videoClips)
        setSortedCollectionIds(videoClips.map(({ metaIdentifier }: any) => metaIdentifier));
    }

    const handleCarouselTypeSelect = (type: string) => {
        setCarouselType(type);
    }

    const handleSaveAndPublish = async () => {
        const toastOptions = { duration: 2000, isError: true };

        if (title.trim() === '') {
            shopify.toast.show('Please enter a title for the carousel', toastOptions);
            return;
        }

        if (carouselType == 'custom' && selectedClips.length < 3) {
            shopify.toast.show('Please select at least 3 Popclips to create a carousel', toastOptions);
            return;
        }

        if (carouselType == 'collection' && selectedCollectionID.length < 1) {
            shopify.toast.show('Please select collection to create a carousel', toastOptions);
            return;
        }

        try {
            const carouselName = tempTitle === title ? undefined : title;
            
            const carouselTypeIds = carouselType == 'custom' 
            ? sortedPopclipsIds?.length > 0 
                ? sortedPopclipsIds 
                : selectedClips 
            : selectedCollectionID

            const payload = {
                carousel_id: editMode ? carouselId : undefined,
                customer_id: shopStore.shopifyDomain,
                carousel_title: editMode ? carouselName : title,
                carousel_type_ids: carouselTypeIds,
                carousel_type: carouselType, 
            }
            if(carouselType == COLLECTION && sortedCollectionIds?.length > 0 ){
                Object.assign(payload, {sorted_popclips_ids: sortedCollectionIds})
            }

            setIsApiLoading(true);
            if(editMode){
                const startTime = new Date().getTime();
                const previousCarouselTypeIds = carouselToEdit?.carousel_type == COLLECTION  && carouselToEdit?.carousel_type_ids?.[0]
                        ? shopifyIdFromGid(carouselToEdit.carousel_type_ids[0])
                        :  carouselToEdit?.carousel_type_ids || [];
                const newCarouselTypeIds = carouselType == COLLECTION && carouselTypeIds?.[0]
                        ? shopifyIdFromGid(carouselTypeIds[0])
                        : carouselTypeIds
                const previousSortedPopclipsIds = carouselType == CUSTOM 
                        ?  carouselToEdit?.carousel_type_ids || []
                        : previousCarouselTypeIds?.[0] != newCarouselTypeIds?.[0] 
                            ? [] 
                            :  carouselToEdit?.sorted_popclips_ids || []
                const options = {
                    carousel_type: `Selected by ${carouselType == COLLECTION ? COLLECTION : 'popclip'}`,
                    previous_carousel_title: tempTitle,
                    new_carousel_title: title,
                    previous_carousel_type_ids: previousCarouselTypeIds,
                    new_carousel_type_ids: newCarouselTypeIds,
                    previous_sorted_popclips_ids: previousSortedPopclipsIds,
                }
                if(carouselType == COLLECTION && collectionName){
                    Object.assign(options, {collection_name: collectionName})
                }

                const { carousel_id, updated_at, sorted_popclips_ids, carousel_type_ids } = await popclipsStore.saveCustomCarousel(payload, editMode != undefined);
                const endTime = new Date().getTime();
                const timeTaken = endTime - startTime;
                
                setCarouselId(carousel_id);

                analyticsEventStore.popclipEditCustomCarouselTriggered({
                    duration: Math.floor(timeTaken / 1000),
                    carouselId: carouselId,
                    options: {
                        ...options,
                        new_sorted_popclips_ids: carouselType == COLLECTION ? sorted_popclips_ids : carousel_type_ids,
                        updated_at: updated_at,
                    },
                    buttonTitle: isPublished ? 'Save' : 'Save & Publish',
                });
            } else {
                const options = {};
                if(carouselType == COLLECTION && collectionName){
                    Object.assign(options, {collection_name: collectionName})
                }

                const startTime = new Date().getTime();
                const { carousel_id } = await popclipsStore.saveCustomCarousel(payload, editMode !== undefined);

                const endTime = new Date().getTime();
                const timeTaken = endTime - startTime;
                setCarouselId(carousel_id);
                analyticsEventStore.popclipCreateCustomCarouselTriggered({
                    duration: Math.floor(timeTaken / 1000),
                    carouselId: carousel_id,
                    options: {
                        ...options,
                        carousel_title: title,
                        carousel_type_ids: carouselType == COLLECTION && carouselTypeIds?.[0] 
                            ? shopifyIdFromGid(carouselTypeIds[0])
                            : carouselTypeIds,
                        carousel_type: `Selected by ${carouselType == COLLECTION ? COLLECTION : 'popclip'}`,
                        sorted_popclips_ids: carouselType == COLLECTION 
                            ?  sortedCollectionIds 
                            : sortedPopclipsIds,
                    }
                });
            }

            if (isPublished) {
                shopify.toast.show('Custom carousel changes saved', {
                    duration: 2000,
                    isError: false,
                });
                navigate(popclipsRoutes.list);
                popclipsStore.carouselToEdit = null;
            } else {
                setOpenPublishModal(true);
            }
        } catch (error) {
            shopify.toast.show(error.message, {
                duration: 2000,
                isError: true,
            });
            console.error(error);
            return;
        } finally {
            setIsApiLoading(false);
        }
    };

    return (
        <BaseLayout
            title={
                editMode ? 'Edit Custom Carousel' : 'Create New Custom Carousel'
            }
            headerAction={
                <InlineStack gap="300" align="center" blockAlign="center">
                    {isApiLoading && <Spinner size="small" />}
                    <Button
                        variant="primary"
                        submit
                        onClick={handleSaveAndPublish}
                        disabled={isApiLoading}
                    >
                        {isPublished ? 'Save' : 'Save & Publish'}
                    </Button>
                </InlineStack>
            }
            backToLink={popclipsRoutes.list}
        >
            <BlockStack gap="400">
                <PopClipsCard>
                <CarouselForm
                    carouselId={carouselId}
                    title={title}
                    handleTitle={(value: string) => setTitle(value)}
                    isApiLoading={isApiLoading}
                />
                <CarouselClipSelector
                    toggleOpenModal={() => setOpenModal(true)}
                    selectedClipData={clipsObjsArr}
                    isApiLoading={isApiLoading}
                    handleCarouselTypeSelect={handleCarouselTypeSelect}
                    carouselType={carouselType}
                    handleCollectionSelect={(id:string) => setSelectedCollectionID([id])}
                    handleCollectionNameSelect={(name:string) => setCollectionName(name)}
                    collectionID={selectedCollectionID}
                    isEditMode={editMode}
                    setShowPlanUpgradeModal={setShowPlanUpgradeModal}
                    setCollectionVideoPopclips={handleCollectionVideoPopclips}
                    isCollectionPopclipsLoading={isCollectionPopclipsLoading}
                    setIsCollectionPopclipsLoading={setIsCollectionPopclipsLoading}
                />
                <CarouselClipModal
                    openModal={openModal}
                    toggleOpenModal={() => setOpenModal(false)}
                    selectedClips={selectedClips}
                    handleSelectClip={onHandleSelectClip}
                    isEditMode={editMode}
                />
                </PopClipsCard>
                <PopClipsCard>
                    <CarouselClipsPreview
                        selectedType={carouselType}
                        selectedClipData={carouselType == CUSTOM ? clipsObjsArr : collectionVideoPopclips || []}
                        isApiLoading={isCollectionPopclipsLoading}
                        selectedCollection={selectedCollectionID}
                        setClipsObjsArr={onHandleSelectClip}
                        toggleOpenModal={() => setOpenModal(true)}
                    />
                </PopClipsCard>
            </BlockStack>
            <CarouselPublishModal
                openPublishModal={openPublishModal}
                hidePublishModal={() => {setOpenPublishModal(false), navigate(popclipsRoutes.list)}}
                carouselId={carouselId}
            />
            {showPlanUpgradeModal && (
                <SocialMediaPlanUpdateModal
                    setShowSocialPlanUpdate={setShowPlanUpgradeModal}
                    socialButtonType={CUSTOM_CAROUSAL}
                />
            )}
        </BaseLayout>
    );
}

