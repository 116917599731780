import {
    Badge,
    BlockStack,
    Box,
    Button,
    Card,
    Divider,
    InlineStack,
    Pagination,
    SkeletonBodyText,
    Text,
} from '@shopify/polaris';
import { useSnapshot } from 'valtio';
import { mobileAppConfigStore, shopStore } from '@/stores';
import { DeleteIcon } from '@shopify/polaris-icons';
import { useNavigate } from 'react-router-dom';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { formatDateToLocalTime } from '@/utils/formatDate';
import { mobileRoutes } from '@/constants/routes.constants';

type notificationObjectType = {
    body: string;
    id: string;
    sentAt: string;
    shopifyDomain: string;
    status: string;
    title: string;
    type: string;
};
type NotificationsCardProps = {
    setShowNotificationBlockModal:Dispatch<SetStateAction<boolean>>;
};
const NOTIFICATIONSPERPAGE = 10;
const NotificationsCard = ({
    setShowNotificationBlockModal,
}: NotificationsCardProps) => {
    const [notificationList, setNotificationList] = useState<
        notificationObjectType[]
    >([]);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPreviousPage, setHasPreviousPage] = useState(false);
    const [cursorArray, setCursorArray] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeletingId, setIsDeletingId] = useState<string | null>(null);
    const mobileAppConfigSnap = useSnapshot(mobileAppConfigStore, {
        sync: true,
    });
    const { featureFlags } = useSnapshot(shopStore);
    const navigate = useNavigate();

    const handlePreviousPageSelect = () => {
        cursorArray.pop();
        if (hasNextPage) {
            cursorArray.pop();
        }
        cursorArray?.length > 0
            ? fetchNotificationList(cursorArray[cursorArray.length - 1])
            : fetchNotificationList();
    };

    const handleNextPageSelect = () => {
        fetchNotificationList(cursorArray[cursorArray.length - 1]);
    };

    const handleDeleteNotification = async (
        notificationData: notificationObjectType,
    ) => {
        setIsDeletingId(notificationData.id);
        try {
            const response = await fetch(
                `/api/shopify/shop/mobile/notifications/${notificationData.id}`,
                { method: 'DELETE' },
            );
            cursorArray.pop();
            cursorArray?.length > 0
                ? fetchNotificationList(cursorArray[cursorArray.length - 1])
                : fetchNotificationList();

            if (response?.status === 200) {
                shopify.toast.show(
                    'Scheduled Notification Deleted Sucessfully',
                    {
                        isError: false,
                        duration: 5000,
                    },
                );
            } else {
                shopify.toast.show('Failed to delete scheduled notification', {
                    isError: true,
                    duration: 5000,
                });
            }
        } catch (error) {
            console.log('Failed to delete scheduled notification', error);
            shopify.toast.show('Failed to delete scheduled notification', {
                isError: true,
                duration: 5000,
            });
        }
        setIsDeletingId(null);
    };

    const fetchNotificationList = async (cursor: string | null = null) => {
        setIsLoading(true);
        try {
            const response = await fetch(
                `/api/shopify/shop/mobile/notifications?limit=${NOTIFICATIONSPERPAGE}${
                    cursor ? `&cursor=${cursor}` : ''
                }`,
                { method: 'GET' },
            );
            const { data } = await response.json();
            if (data?.items) {
                setNotificationList(data?.items || []);
                setHasPreviousPage(data?.hasPreviousPage);
                setHasNextPage(data?.hasNextPage);
                if (data?.cursor) {
                    if (!cursorArray.includes(data.cursor)) {
                        cursorArray.push(data.cursor);
                    }
                }
            }
        } catch (error) {
            console.log('Failed to fecth scheduled notification list: ', error);
            shopify.toast.show('Failed to fecth scheduled notification list', {
                isError: true,
                duration: 5000,
            });
        }
        setIsLoading(false);
    };
    useEffect(() => {
        fetchNotificationList();
    }, []);

    const onClickComposeNotification = () => {
        navigate(mobileRoutes.composePushNotification);
        /* if (featureFlags?.disable_push_notification) {
            setShowNotificationBlockModal(true);
        } else {
            navigate(mobileRoutes.composePushNotification);
        } */
    };
    return (
        <Card padding="800">
            <Box width="56rem">
                <BlockStack gap="600">
                    <BlockStack gap="300">
                        <InlineStack align="space-between">
                            <Text as="h3" variant="headingSm">
                                Notifications
                            </Text>
                            <Button
                                variant="primary"
                                onClick={() => onClickComposeNotification()}
                            >
                                Compose Push Notification
                            </Button>
                        </InlineStack>
                        <Text as="h4" variant="bodySm">
                            Create notifications that send important messages
                            directly to your customers.
                        </Text>
                    </BlockStack>
                    {mobileAppConfigSnap.isLoading ? (
                        <SkeletonBodyText />
                    ) : (
                        <Box>
                            <BlockStack>
                                {notificationList?.length > 0
                                    ? notificationList.map((notification) => {
                                          return (
                                              <Box key={notification.id}>
                                                  <InlineStack
                                                      align="space-between"
                                                      wrap={false}
                                                  >
                                                      <Box width="70%">
                                                          <Text
                                                              as="h3"
                                                              variant="headingSm"
                                                          >
                                                              {
                                                                  notification.title
                                                              }
                                                          </Text>
                                                          <Text as="p">
                                                              {
                                                                  notification.body
                                                              }
                                                          </Text>
                                                      </Box>
                                                      <Box>
                                                          <InlineStack
                                                              align="center"
                                                              gap="200"
                                                          >
                                                              {notification.status ===
                                                                  'pending' && (
                                                                  <Badge tone="info">
                                                                      Pending
                                                                  </Badge>
                                                              )}
                                                              {notification.status ===
                                                                  'sent' && (
                                                                  <Badge tone="success">
                                                                      Sent
                                                                  </Badge>
                                                              )}
                                                              {notification.status ===
                                                                  'failed' && (
                                                                  <Badge tone="critical">
                                                                      Failed
                                                                  </Badge>
                                                              )}
                                                              <Box
                                                                  background="bg-surface-brand-hover"
                                                                  padding="100"
                                                                  width="8rem"
                                                                  borderRadius="200"
                                                              >
                                                                  <Text
                                                                      variant="bodyMd"
                                                                      as="p"
                                                                      alignment="center"
                                                                  >
                                                                      {formatDateToLocalTime(
                                                                          notification.sentAt,
                                                                      )}
                                                                  </Text>
                                                              </Box>
                                                              <Button
                                                                  icon={
                                                                      DeleteIcon
                                                                  }
                                                                  accessibilityLabel="Delete scheduled notification"
                                                                  variant="primary"
                                                                  onClick={() =>
                                                                      handleDeleteNotification(
                                                                          notification,
                                                                      )
                                                                  }
                                                                  disabled={
                                                                      notification.status ===
                                                                      'sent'
                                                                  }
                                                                  loading={
                                                                      notification.id ==
                                                                      isDeletingId
                                                                  }
                                                              ></Button>
                                                          </InlineStack>
                                                      </Box>
                                                  </InlineStack>
                                                  <Box paddingBlock="400">
                                                      <Divider />
                                                  </Box>
                                              </Box>
                                          );
                                      })
                                    : null}
                                {notificationList &&
                                notificationList.length > 0 ? (
                                    <Pagination
                                        hasPrevious={
                                            hasPreviousPage && !isLoading
                                        }
                                        onPrevious={() =>
                                            handlePreviousPageSelect()
                                        }
                                        hasNext={hasNextPage && !isLoading}
                                        onNext={() => handleNextPageSelect()}
                                    />
                                ) : null}
                            </BlockStack>
                        </Box>
                    )}
                </BlockStack>
            </Box>
        </Card>
    );
};
export default NotificationsCard;
