import { useState } from 'react';
import { DropZone, Button } from '@shopify/polaris';
import { videoLiveSellingStore } from '@/stores';
import { DeleteIcon } from '@shopify/polaris-icons';
import { useSnapshot } from 'valtio';

type VideoPickerProps = {
    labelStr: string;
    videoUrl: string;
    hintText: string;
};

const AppVideoPicker = (props: VideoPickerProps) => {
    const [openFileDialog, setOpenFileDialog] = useState(false);
    const videoLiveSellingSnap = useSnapshot(videoLiveSellingStore, {
        sync: true,
    });

    const handleDropZone = async (
        _dropFiles: File[],
        acceptedFiles: File[],
    ) => {
        if (acceptedFiles[0] && acceptedFiles[0].size > 100 * 1024 * 1024) {
            // 100MB
            shopify.toast.show('Uploaded video size should be less than 100MB', {
                isError: true,
                duration: 5000,
            });
            return;
        }

        const formData = new FormData();

        const fileName = acceptedFiles[0].name;
        const sanitizedName = `${fileName.slice(0, -3).replace(/[^a-zA-Z0-9]/g, '')}.${fileName.slice(-3)}`;

        formData.append('file', acceptedFiles[0], sanitizedName);
        videoLiveSellingStore.dropzoneFile = formData;

        var URL = window.URL || window.webkitURL;
        var src = URL.createObjectURL(acceptedFiles[0]);
        videoLiveSellingStore.setVideoUrl(src);
    };

    const handleRemoveDZfile = () => {
        videoLiveSellingStore.setVideoUrl('');
        videoLiveSellingStore.dropzoneFile = null;
        videoLiveSellingStore.videoThumbnailImage = '';
    }

    const removeMediaFromDropZone = (
        <div
            style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Button
                variant="primary"
                tone="critical"
                onClick={handleRemoveDZfile}
                icon={DeleteIcon}
                disabled={videoLiveSellingSnap.savingPopclip}
            >
                Delete & upload a new clip
            </Button>
        </div>
    );

    return (
        <DropZone
            accept=".mp4,.mov"
            type="video"
            label={props.labelStr}
            allowMultiple={false}
            openFileDialog={openFileDialog}
            onDrop={handleDropZone}
            onFileDialogClose={() =>
                setOpenFileDialog((openFileDialog) => !openFileDialog)
            }
            disabled={
                videoLiveSellingSnap.savingPopclip || props.videoUrl != ''
            }
        >
            {(!videoLiveSellingSnap.editClipData && props.videoUrl) ? removeMediaFromDropZone : (
                <DropZone.FileUpload
                    actionTitle="Add Video"
                    actionHint={props.hintText}
                />
            )}
        </DropZone>
    );
};

export default AppVideoPicker;
