import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import {
    useNavigationType,
    matchRoutes,
    useLocation,
    createRoutesFromElements,
} from 'react-router-dom';
import "./assets/main.css" 
import App from './App';
import { isQaEnv } from './utils/helpers';

const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);
root.render(
    // <React.StrictMode>
        <App />
    // </React.StrictMode>,
);

(async() => {
    if (isQaEnv) return;

    Sentry.init({
        dsn: 'https://0c22aa7309f24f98aab1339445a53d4d@o43862.ingest.sentry.io/4505608014397440',
        environment: import.meta.env.MODE,
        tracesSampleRate: 0.05,
        tracePropagationTargets: [],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });

    const {BrowserTracing, reactRouterV6Instrumentation } = await import('@sentry/react');

    Sentry.addIntegration(
        new BrowserTracing({
            routingInstrumentation: reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromElements,
                matchRoutes,
            ),
        }),
    );
})();