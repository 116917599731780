import {
    BlockStack,
    Box,
    Divider,
    Frame,
    Icon,
    InlineStack,
    Link,
    Loading,
    Page,
    Spinner,
} from '@shopify/polaris';
import {
    ArrowLeftIcon,
    CaretDownIcon,
    CaretUpIcon,
} from '@shopify/polaris-icons';
import '../../../assets/css/BaseLayout.scss';
import { useEffect, useState } from 'react';
import { SaveBar } from '@shopify/app-bridge-react';
import { shopStore } from '@/stores';
import { useSnapshot } from 'valtio';
import ProgressStatusPopup from '../../Components/ProgressStatusPopup';
import ZendeskSupportWidget from '@/components/common/ZendeskSupportWidget';
import customBannerStore, { popUpDataType } from '@/stores/customBannersStore';
import { useLocation } from 'react-router-dom';
import { CustomVFSBanner } from '@/components/common/CustomVFSBanner';

type BaseLayoutProps = {
    isLoading?: boolean;
    bodyIsLoading?: boolean;
    showProgressStatus?: boolean;
    children: React.ReactNode;
    title: string | JSX.Element;
    subtitle?: string | JSX.Element;
    headerAction?: JSX.Element;
    backToLink?: string;
    disabledBackButton?: boolean;
    saveBarPrimaryAction?: () => void;
    saveBarSecondaryAction?: () => void;
};

type LoadingComponentProps = {
    loading: boolean;
    children: React.ReactNode;
};

export function BaseLayout({
    isLoading,
    bodyIsLoading,
    title,
    subtitle,
    headerAction,
    backToLink,
    disabledBackButton = false,
    saveBarPrimaryAction,
    saveBarSecondaryAction,
    showProgressStatus,
    children,
}: BaseLayoutProps) {
    const [showProgressPopup, setShowProgressPopup] = useState(false);
    const [customBannersToShow, setCustomBannersToShow] = useState<
        popUpDataType[]
    >([]);
    const { progressPercentage } = useSnapshot(shopStore);
    const { bannerList } = useSnapshot(customBannerStore);
    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        // Restore scroll position on page load
        window.scrollTo(0, 0);
    }, []);

    const toggleProgressPopup = () => {
        setShowProgressPopup(!showProgressPopup);
    };
    useEffect(() => {
        if (bannerList?.length) {
            const filteredPopUp = bannerList?.filter(
                (item) => {
                    if(item?.render_url?.includes(':id')){
                     const new_render_url = item?.render_url.replace(
                         '/:id',
                         '',
                     );
                     const new_pathname = pathname.substring(
                         0,
                         pathname.lastIndexOf('/'),
                     );
                     return new_render_url === new_pathname;
                    }

                    else return item?.render_url === pathname
                }
            );
            setCustomBannersToShow(filteredPopUp);
        }
        else{
            setCustomBannersToShow([]);
        }
    }, [pathname, bannerList]);
    return (
        <Page fullWidth>
            <SaveBar id="shopify-save-bar">
                <button variant="primary" onClick={saveBarPrimaryAction} />
                <button onClick={saveBarSecondaryAction} />
            </SaveBar>
            <RenderLoadedComponent loading={isLoading || false}>
                <Box
                    background="bg-fill"
                    borderRadius="150"
                    width="min(85rem, 100%)"
                >
                    <BlockStack gap="300">
                        <div
                            style={{
                                position: 'sticky',
                                top: '0',
                                zIndex: '5',
                            }}
                        >
                            <Box background="bg-fill" paddingBlockStart="600">
                                <Box paddingInline="800" paddingBlockEnd="300">
                                    <InlineStack
                                        align="space-between"
                                        wrap={false}
                                    >
                                        <div
                                            style={{
                                                minHeight:
                                                    !subtitle &&
                                                    !showProgressStatus
                                                        ? '38px'
                                                        : '62px',
                                            }}
                                        >
                                            <InlineStack
                                                gap="400"
                                                align="start"
                                                blockAlign="center"
                                            >
                                                {backToLink && (
                                                    <>
                                                        {disabledBackButton ? (
                                                            <Icon
                                                                source={
                                                                    ArrowLeftIcon
                                                                }
                                                            />
                                                        ) : (
                                                            <Link
                                                                url={backToLink}
                                                                monochrome
                                                                removeUnderline
                                                            >
                                                                <Icon
                                                                    source={
                                                                        ArrowLeftIcon
                                                                    }
                                                                />
                                                            </Link>
                                                        )}
                                                    </>
                                                )}

                                                <div>
                                                    <h1 className="page-heading">
                                                        {title}
                                                    </h1>
                                                    {subtitle && (
                                                        <Box paddingBlockStart="100">
                                                            {typeof subtitle ==
                                                            'string'
                                                                ? subtitle
                                                                : subtitle}
                                                        </Box>
                                                    )}
                                                </div>
                                            </InlineStack>
                                        </div>
                                        {headerAction
                                            ? headerAction
                                            : showProgressStatus && (
                                                  <div
                                                      style={{
                                                          position: 'relative',
                                                      }}
                                                  >
                                                      <div
                                                          className="get-started-progress"
                                                          onClick={
                                                              toggleProgressPopup
                                                          }
                                                      >
                                                          <div
                                                              className="circular-progress"
                                                              style={{
                                                                  background: `conic-gradient(#fff ${
                                                                      (progressPercentage /
                                                                          100) *
                                                                      360
                                                                  }deg, #898787 0deg)`,
                                                              }}
                                                          >
                                                              <div className="inner-circle"></div>
                                                          </div>
                                                          <div>
                                                              <p>Get Started</p>
                                                              <p>
                                                                  {
                                                                      progressPercentage
                                                                  }
                                                                  % Complete
                                                              </p>
                                                          </div>
                                                          <div
                                                              style={{
                                                                  cursor: 'pointer',
                                                                  padding:
                                                                      '2px 4px',
                                                              }}
                                                          >
                                                              <Icon
                                                                  source={
                                                                      showProgressPopup
                                                                          ? CaretUpIcon
                                                                          : CaretDownIcon
                                                                  }
                                                              />
                                                          </div>
                                                      </div>
                                                      {showProgressPopup && (
                                                          <ProgressStatusPopup
                                                              toggleProgressPopup={
                                                                  toggleProgressPopup
                                                              }
                                                              showProgressPopup={
                                                                  showProgressPopup
                                                              }
                                                          />
                                                      )}
                                                  </div>
                                              )}
                                    </InlineStack>
                                </Box>
                                <Divider />
                            </Box>
                        </div>
                        {customBannersToShow?.length > 0 && (
                            <Box paddingInline={'800'}>
                                {customBannersToShow?.map((item, index) => (
                                    <CustomVFSBanner
                                        key={index}
                                        popUp_id={item?.rule_id}
                                        title={item?.heading_text}
                                        body={item?.render_html}
                                        CTATitle={item?.extras?.cta_text}
                                        isCTATypeExternal={
                                            item?.is_external_cta_link
                                        }
                                        CTAUrl={item?.extras?.cta_link}
                                        rule_name={item?.rule_name}
                                        render_url={item?.render_url}
                                    />
                                ))}
                            </Box>
                        )}
                        <div style={{ overflow: 'hidden' }}>
                            <Box paddingInline="800">
                                {bodyIsLoading ? (
                                    <div
                                        style={{
                                            height: '40rem',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Spinner size="large" />
                                    </div>
                                ) : (
                                    children
                                )}
                            </Box>
                            <p className="page-watermark">Videeo</p>
                        </div>
                    </BlockStack>
                </Box>
                <ZendeskSupportWidget />
            </RenderLoadedComponent>
        </Page>
    );
}

const RenderLoadedComponent = ({
    loading,
    children,
}: LoadingComponentProps) => (
    <>
        {loading && (
            <Frame>
                <Loading />
            </Frame>
        )}
        {!loading && (
            <div className="base-layout-page-container">{children}</div>
        )}
    </>
);
