import { Banner, BlockStack, Box, Text } from '@shopify/polaris';
import {
    BannerCard,
    NotificationsCard,
    LiveBannerCard,
} from '@/components/mobile-app-configuration';
import { buildMobileAppStore, mobileAppConfigStore } from '@/stores';
import { useEffect, useState } from 'react';
import { useGetAppConfig } from '@/hooks/useGetAppConfig';
import AppBuilderBaseLayout from '@/components/layout/AppBuilderBaseLayout';
import { useSnapshot } from 'valtio';
import { useLocation } from 'react-router-dom';
import { mobileRoutes } from '@/constants/routes.constants';
import { NotificationBlockModal } from '@/components/common/NotificationBlockModal';

const MobileAppNotificationPage = () => {
    const { getAppConfig } = useGetAppConfig();
    const { pathname } = useLocation();
    const mobileAppConfigSnap = useSnapshot(mobileAppConfigStore, {
        sync: true,
    });
    const buildMobileAppSnapshot = useSnapshot(buildMobileAppStore, {
        sync: true,
    });

    const { isAppRequestSubmitted } = buildMobileAppSnapshot;
    const [selected, setSelected] = useState<number>(0);
    const [isAppApprovePending, setIsAppApprovePending] = useState(false);
    const [showNotificationBlockModal, setShowNotificationBlockModal] =
        useState(false);

    useEffect(() => {
        mobileAppConfigStore.showNotificationErrorLabel = false;
        mobileAppConfigStore.showFormErrorLabel = false;
        mobileAppConfigStore.isLoading = true;
        getAppConfig();
        if (pathname.includes(mobileRoutes.notification)) {
            setSelected(0);
        }
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setIsAppApprovePending(!isAppRequestSubmitted);
    }, [isAppRequestSubmitted]);

    return (
        <AppBuilderBaseLayout
            title="Videeo Mobile App Management"
            buttonLabel=""
            buttonDisabled={mobileAppConfigSnap.isLoading}
            selectedTabId={selected}
        >
            <BlockStack gap="1000">
                <BlockStack inlineAlign="center" gap="1000">
                    {!mobileAppConfigSnap.isLoading && isAppApprovePending && (
                        <Box width="60rem">
                            <Banner title="Setup Not Completed" tone="info">
                                <Box paddingInline="500">
                                    <Text as="p">
                                        Please complete mobile app setup from
                                        setup tab to save the changes.
                                    </Text>
                                </Box>
                            </Banner>
                        </Box>
                    )}
                    <NotificationsCard
                        isAppRequestSubmitted={isAppRequestSubmitted}
                        setShowNotificationBlockModal={
                            setShowNotificationBlockModal
                        }
                    />
                    <BannerCard />
                    <LiveBannerCard />
                </BlockStack>
            </BlockStack>
            {showNotificationBlockModal && (
                <NotificationBlockModal
                    setShowNotificationBlockModal={
                        setShowNotificationBlockModal
                    }
                    primaryAction={() => setShowNotificationBlockModal(false)}
                />
            )}
        </AppBuilderBaseLayout>
    );
};
export default MobileAppNotificationPage;
