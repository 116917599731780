import { vfsRoutes } from "@/constants/routes.constants";
import { BlockStack, Box, Text } from "@shopify/polaris";
import { poclips2kCarousel } from "../../assets/images";
import VfsModal from "../../v2/Components/VfsModal";
import { useNavigate } from "react-router-dom";
import { analyticsEventStore } from "@/stores";

type PopclipsCarousel2kBannerProps = {
    modalOpen: boolean;
    toggleHideModal: () => void;
};

export default function PopclipsCarousel2kBanner({
    modalOpen,
    toggleHideModal,
}: PopclipsCarousel2kBannerProps) {
    const navigate = useNavigate();
    
    const handleUpdate=()=>{
        navigate(vfsRoutes.managePlans);
        analyticsEventStore.popclipClickButtonInViewLimitModal(
            'account_react_freeuserpopclipspopupoffer',
            'popup_offer_reaction',
            'Upgrade just for $29.99/month',
            ['addon-popclips-basic'],
            29.99,
            '//*[@id="modal-content-8885451e-38a1-4196-835b-40f3efb46c4e"]/div/div/div/div/button[2]',
            true,
        );
    }
     const handleContinue = () => {
        toggleHideModal();
         analyticsEventStore.popclipClickButtonInViewLimitModal(
             'account_react_freeuserpopclipspopupoffer',
             'popup_offer_reaction',
             'Continue with Free Plan',
             ['addon-popclips-basic'],
             29.99,
             '//*[@id="modal-content-8885451e-38a1-4196-835b-40f3efb46c4e"]/div/div/div/div/button[1]',
             false,
         );
     };
    return (
        <VfsModal
            onCloseModal={handleContinue}
            crossIcon={false}
            closeable={false}
            title="🎉 Exciting Update!"
            modalWidth="40rem"
            primaryActionText="Upgrade just for $29.99/month"
            primaryActionTone="success"
            primaryAction={handleUpdate}
            secondaryActionText="Continue with Free Plan"
            secondaryAction={handleContinue}
        >
            <Box padding="800">
                <BlockStack gap="600" align="center">
                    <img src={poclips2kCarousel} alt="poclips2kCarousel" />
                    <Text as="p" alignment="center">
                        Explore our new <b>Customizable Popclips Carousel</b> and{' '}
                        <b>On-Video Views Counter</b>, now available in the
                        Basic Plan for just $29.99/month. Capture attention and
                        convert viewers to buyers with unique Popclips on every
                        page.
                    </Text>
                </BlockStack>
            </Box>
        </VfsModal>
    );
}