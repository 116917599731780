import { BlockStack, Divider } from "@shopify/polaris";
import { useEffect } from "react";
import WizardStep4 from "./OnboardingWizard/WizardStep4";
import WizardStep1 from "./OnboardingWizard/WizardStep1";
import WizardStep2 from "./OnboardingWizard/WizardStep2";
import WizardStep3 from "./OnboardingWizard/WizardStep3";
import { shopStore } from "@/stores";
import { useSnapshot } from "valtio";
import OnboardingWizardHeader from "./OnboardingWizard/OnboardingWizardHeader";

type ProgressStatusPopupProps = {
    showProgressPopup: boolean;
    toggleProgressPopup: () => void;
};

export default function ProgressStatusPopup({
    showProgressPopup,
    toggleProgressPopup,
}: ProgressStatusPopupProps) {
    const { getStartedStatus } = useSnapshot(shopStore);

    useEffect(() => {
        document.addEventListener('keydown', hideOnEscPress);
        setTimeout(() => {
            document.body.addEventListener('click', hideOnOutsideClick);
        });

        return () => {
            document.body.removeEventListener('click', hideOnOutsideClick);
            document.removeEventListener('keydown', hideOnEscPress);
        };
    }, []);

    const hideOnOutsideClick = (e) => {
        if (
            showProgressPopup &&
            !e.target.closest('.progress-status-popup') &&
            !e.target.closest('.get-started-progress')
        ) {
            toggleProgressPopup();
        }
    };

    const hideOnEscPress = (e) => {
        if (showProgressPopup && e.key === 'Escape') {
            toggleProgressPopup();
        }
    };

    return (
        <div className="progress-status-popup">
            <BlockStack gap="400">
                <OnboardingWizardHeader />
                <Divider />
                <div className="progress-status-popup-content">
                    <BlockStack gap="600" align="start" inlineAlign="start">
                        <div className="onboarding-wizard-modal__step-completed">
                            <WizardStep1 stepExpanded={false} isCompleted={true} />
                        </div>
                        <div className="onboarding-wizard-modal__step-completed">
                            <WizardStep2 stepExpanded={false} isCompleted={true} />
                        </div>
                        <div className="onboarding-wizard-modal__step-completed">
                            <WizardStep3 stepExpanded={false} isCompleted={true} />
                        </div>
                        <div
                            style={{ width: '100%' }}
                            className={`${
                                getStartedStatus.events
                                    .STEP_PUBLISH_YOUR_FIRST_POPCLIP_CAROUSEL &&
                                'onboarding-wizard-modal__step-completed'
                            }`}
                        >
                            <WizardStep4
                                stepExpanded={true}
                                isCompleted={false}
                                hideTime
                            />
                        </div>
                    </BlockStack>
                </div>
            </BlockStack>
        </div>
    );
}
