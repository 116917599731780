import { useState } from 'react';
import { useMediaGalleryAuthFetch } from '@/hooks/useAuthenticatedFetch';
import shopStore from '@/stores/shopStore';

type LastEvaluatedKey = {
    id: string;
    metaIdentifier: string;
};

export const usePopclipsPagination = (
    pageSize: number = 10,
    fileCategory: string = 'shopifyClips',
) => {
    let abortController = new AbortController();
    const MGAuthFetch = useMediaGalleryAuthFetch();
    const [previousEvalKey, setPreviousEvalKey] = useState<LastEvaluatedKey | null>(null);
    const [cacheFeaturedPopclip, setCacheFeaturedPopclip] = useState();

    const getPaginatedData = async (
        usePrevEval: boolean = false,
        lastEvaluatedKey: LastEvaluatedKey | null = null,
    ) => {
        abortController.abort();
        abortController = new AbortController();
        if (!usePrevEval) setPreviousEvalKey(lastEvaluatedKey);

        try {
            if (!shopStore.popshopliveSellerId) return;

            const { data: clipsArray } = await MGAuthFetch('/listMediabyID', {
                method: 'POST',
                body: JSON.stringify({
                    id: shopStore.popshopliveSellerId,
                    pageSize,
                    fileCategory,
                    lastEvaluatedKey: usePrevEval
                        ? previousEvalKey
                        : lastEvaluatedKey,
                }),
                signal: abortController.signal,
            });
            const clipsArrayItems = clipsArray?.items || [];
            await fetchLikesAndViewsPerClip(clipsArrayItems);
            const modifiedArr = await getFeaturedClip(clipsArrayItems);
            return {
                clips: clipsArrayItems,
                clipsWithFeatureClips: modifiedArr,
                lastEvaluatedKey: clipsArray.lastEvaluatedKey,
                isDataAvailable: clipsArrayItems.length > 0,
            };
        } catch (error) {
            if (error?.name != 'AbortError') {
                return null;
            }
        }
    };

    const fetchLikesAndViewsPerClip = async (filterClipArray: any) => {
        const clipIDs = filterClipArray.map(
            ({ id, metaIdentifier }) => id + '_' + metaIdentifier,
        );
    
        if (clipIDs?.length < 1) {
            return;
        }
    
        const [views_response, likes_response] = await Promise.all([
            fetch('/api/popclips/views/fetch', {
                method: 'POST',
                body: JSON.stringify({
                    customer_id: shopStore.shopifyDomain,
                    clip_ids: clipIDs,
                }),
            }),
            fetch('/api/popclips/likes/fetch', {
                method: 'POST',
                body: JSON.stringify({
                    customer_id: shopStore.shopifyDomain,
                    clip_ids: clipIDs,
                    user_id: shopStore.shopifyDomain,
                }),
            }),
        ]);
    
        if (views_response.status === 200) {
            const { data } = await views_response.json();
            updateFilterClipArrayWithViews(filterClipArray, data);
        }
    
        if (likes_response.status === 200) {
            const { data } = await likes_response.json();
            updateFilterClipArrayWithLikes(filterClipArray, data);
        }
    };

    const getFeaturedClip = async (filterClipArray: any) => {
        if (shopStore.featuredPopclipId?.length < 1) return filterClipArray;

        let modifiedArr = [];
        const clipFound = filterClipArray.find(({ metaIdentifier }) => metaIdentifier === shopStore.featuredPopclipId);
        if (clipFound) {
            modifiedArr = [...[clipFound], ...filterClipArray.filter((clip: any) => clip.metaIdentifier != clipFound.metaIdentifier)];
            setCacheFeaturedPopclip(modifiedArr[0]);
        } else if (cacheFeaturedPopclip && cacheFeaturedPopclip.metaIdentifier === shopStore.featuredPopclipId) {
            modifiedArr = [cacheFeaturedPopclip, ...filterClipArray.filter((clip: any) => clip.metaIdentifier != cacheFeaturedPopclip.metaIdentifier)];
        } else {
            const result = await MGAuthFetch('/listMediabyTypeAndMetaId', {
                method: 'POST',
                body: JSON.stringify({
                    fileCategory: 'shopifyClips',
                    metaIds: [shopStore.featuredPopclipId],
                    sellerId: shopStore.popshopliveSellerId
                }),
            });
    
            if (!result.data || result.data.length === 0) return filterClipArray;

            const clipFound = result.data[0];
            const featuredClipArr = [clipFound];
            await fetchLikesAndViewsPerClip(featuredClipArr);
            modifiedArr = [...featuredClipArr, ...filterClipArray.filter((clip: any) => clip.metaIdentifier != clipFound.metaIdentifier)];
            setCacheFeaturedPopclip(modifiedArr[0]);
        }

        return modifiedArr;
    };

    return { getPaginatedData, fetchLikesAndViewsPerClip };
};

const updateFilterClipArrayWithViews = (filterClipArray: any, data: any) => {
    if (data?.length > 0) {
        data.forEach((item) => {
            const clipsFound = filterClipArray.find(
                ({ metaIdentifier, id }) =>
                    `${id}_${metaIdentifier}` === item.clip_id,
            );
            if (clipsFound) {
                clipsFound.showViews =
                    item?.clip_total_views || item?.clip_total_views === 0;
                clipsFound.clipViews = item?.clip_total_views;
            }
        });
    }
};

const updateFilterClipArrayWithLikes = (filterClipArray: any, data: any) => {
    if (data?.length > 0) {
        data.forEach((item) => {
            const clipsFound = filterClipArray.find(
                ({ metaIdentifier, id }) =>
                    `${id}_${metaIdentifier}` === item.clip_id,
            );

            if (clipsFound) {
                clipsFound.showLikes = item?.likes || item?.likes === 0;
                clipsFound.clipLikes = item?.likes;
            }
        });
    }
};
