import { proxy } from 'valtio';
import shopStore from './shopStore';
import { sha256 } from 'js-sha256';
import { getDeviceInfo, getFingerprint, isQaEnv } from '@/utils/helpers';
import { UAParser } from 'ua-parser-js';
import { mobileRoutes, popclipsRoutes, vfsRoutes } from '@/constants/routes.constants';
import billingStore from './billingStore';
import { generatePath } from 'react-router-dom';

export const APP_NAME_BY_ENV = isQaEnv ? 'vfs-november' : 'videeo-by-commentsold';
const SUBSCRIPTION_TYPE = {
    FIRST_EVER: "first_ever", // selecting the plan for the first time after account establishment
    UPGRADE_GATED: "upgrade_gated", // adding new add-on based on the enforcement of the gating on some feature
    UPGRADE_SPONTANEOUS: "upgrade_spontaneous", // adding new add-on not linkable to any gating limitation or trial/action offer
    UPGRADE_ACTION: "upgrade_action", // adding new add-on based on awarded trial or special complimentary offering.
    DOWNGRADE_COMPANYDRIVEN: "downgrade_companydriven", // change of the subscription lower level of primary bundle or surrending add-on(s) as a decision on the company side
    DOWNGRADE_USERENFORCED: "downgrade_userenforced", // change of the subscription lower level of primary bundle or surrendering add-on(s) based on push from customer side or as retention move from the company
    OTHER: "other", // none of the above listed reasons
};

const analyticsEventStore = proxy({
    popclipsUploadedAppEvent: (responseData: any) => {
        if (!responseData) return;

        try {
            const appEventsApiUrl = `${shopStore.config?.csDataLakeConfig?.apiUrl}/ingest/appevents`;
            fetch(appEventsApiUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    occurred_at: Date.now(),
                    event_type: 'POPCLIP_UPLOADED',
                    customer_name: shopStore.shopName,
                    customer_id: shopStore.shopifyDomain,
                    event_details: responseData,
                }),
                redirect: 'follow',
            });
        } catch (error) {
            console.log('Failed to fetch data lake API: ', error);
        }
    },
    popclipUploadTriggered: async (isEditMode: boolean, data: any) => {
        const {
            duration,
            popclipsID,
            source,
            sourceReelUrl,
            newPopclipTitle,
            newProductList,
            newPDPVisibility,
            isFeaturedPopclip,
        } = data;
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const productEditLink = `${shopifyAdminURL}${generatePath(popclipsRoutes.edit, { id: popclipsID })}`;
        const currentUrl = isEditMode
            ? productEditLink
            : `${shopifyAdminURL}${popclipsRoutes.create}`;

        dataLakePayloadToTrack(
            'popclip_upload_triggered',
            'task_completed',
            {
                duration,
                location: currentUrl,
                source,
                editLink: productEditLink,
                sourceReelUrl,
                popclip_title_updated: true,
                previous_popclip_title: null,
                new_popclip_title: newPopclipTitle,
                product_list_updated: true,
                previous_product_list: null,
                new_product_list: newProductList,
                pdp_visibility_updated: true,
                previous_pdp_visibility: null,
                new_pdp_visibility: newPDPVisibility,
                is_featured_popclip: isFeaturedPopclip,
            },
            popclipsID,
            'popclips/list',
            {
                type: 'button',
                title: 'Save',
                xPath: '//*[@id="app"]/div[1]/div/div/div/div/div[1]/div/div/div/div[2]/button/span/span',
            },
        );
    },
    popclipEditExistingPopclip: async (isEditMode: boolean, data: any) => {
        const {
            duration,
            popclipsID,
            source,
            sourceReelUrl,
            popclipTitleUpdated,
            previousPopclipTitle,
            newPopclipTitle,
            productListUpdated,
            previousProductList,
            newProductList,
            pdpVisibilityUpdated,
            previousPdpVisibility,
            newPdpVisibility,
            isFeaturedPopclip,
        } = data;
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const productEditLink = `${shopifyAdminURL}${generatePath(popclipsRoutes.edit, { id: popclipsID })}`;
        const currentUrl = isEditMode
            ? productEditLink
            : `${shopifyAdminURL}${popclipsRoutes.create}`;

        dataLakePayloadToTrack(
            'popclip_edit_existingpopclip',
            'task_completed',
            {
                duration,
                location: currentUrl,
                source,
                editLink: productEditLink,
                sourceReelUrl,
                popclip_title_updated: popclipTitleUpdated,
                previous_popclip_title: previousPopclipTitle,
                new_popclip_title: newPopclipTitle,
                product_list_updated: productListUpdated,
                previous_product_list: previousProductList,
                new_product_list: newProductList,
                pdp_visibility_updated: pdpVisibilityUpdated,
                previous_pdp_visibility: previousPdpVisibility,
                new_pdp_visibility: newPdpVisibility,
                is_featured_popclip: isFeaturedPopclip,
            },
            popclipsID,
            'popclips/list',
            {
                type: 'button',
                title: 'Save',
                xPath: '//*[@id="app"]/div[1]/div/div/div/div/div[1]/div/div/div/div[2]/button/span/span',
            },
        );
    },
    popclipCreateCustomCarouselTriggered: async ({
        duration,
        carouselId,
        options = {},
    }: any) => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${popclipsRoutes.createCarousel}`;
        const entryURL = `${shopifyAdminURL}${popclipsRoutes.list}`;


        dataLakePayloadToTrack(
            'popclip_create_customcarousel',
            'task_completed',
            {
                duration,
                location: currentUrl,
                ...options,
            },
            shopStore.ownerEmail +'@'+ carouselId,
            entryURL,
            {
                type: 'button',
                title: 'Save & Publish',
                xPath: '//*[@id="app"]/div[1]/div/div/div/div/div/div[1]/div/div[1]/div[2]/div[2]/button',
            },
        );
    },
    popclipEditCustomCarouselTriggered: async ({
        duration,
        carouselId,
        options = {},
        buttonTitle = 'Save & Publish',
    }: any) => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${popclipsRoutes.editCarousel}`;
        const entryURL = `${shopifyAdminURL}${popclipsRoutes.list}`;

        dataLakePayloadToTrack(
            'popclip_edit_customcarousel',
            'task_completed',
            {
                duration,
                location: currentUrl,
                ...options
            },
            shopStore.ownerEmail +'@'+ carouselId,
            entryURL,
            {
                type: 'button',
                title: buttonTitle,
                xPath: '//*[@id="app"]/div[1]/div/div/div[1]/div/div[1]/div/div/div/div[2]/button',
            },
        );
    },
    popclipDeleteCustomCarouselTriggered: async ({
        duration,
        carouselId,
        options = {},
    }: any) => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${popclipsRoutes.list}`;

        dataLakePayloadToTrack(
            'popclip_delete_customcarousel',
            'task_completed',
            {
                duration,
                location: currentUrl,
                ...options
            },
            shopStore.ownerEmail +'@'+ carouselId,
            currentUrl,
            {
                type: 'button',
                title: 'Delete',
                xPath: '//*[@id="01JKTWNPJ94P017V0YVN9V1304"]/td[5]/div/button[2]',
            },
        );
    },
    onboardingClickVideoSDK: async () => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.getStarted}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.verifyAccount}`;

        dataLakePayloadToTrack(
            'onboarding_click_videoSDK',
            'task_initiated',
            {
                duration: 0,
                location: currentUrl,
            },
            '',
            entryURL,
            {
                type: 'button',
                title: 'Review and Save',
                xPath: '//*[@id="app"]/div[1]/div[2]/div/div[2]/div[1]/div/div[2]/div/div[2]/button',
            },
        );
    },
    onboardingCompleteVideoSDK: async () => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.getStarted}`;

        dataLakePayloadToTrack(
            'onboarding_complete_videoSDK',
            'task_completed',
            {
                duration: 0,
                location: currentUrl,
            },
            '',
            '',
            {
                type: null,
                title: null,
                xPath: null,
            },
        );
    },
    onboardingClickVideoSalesChannel: async () => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.getStarted}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.verifyAccount}`;

        dataLakePayloadToTrack(
            'onboarding_click_videosaleschannel',
            'task_initiated',
            {
                duration: 0,
                location: currentUrl,
            },
            '',
            entryURL,
            {
                type: 'button',
                title: 'Publish Now',
                xPath: '//*[@id="app"]/div[1]/div[2]/div/div[2]/div[2]/div/div[2]/div/div[2]/button',
            },
        );
    },
    onboardingCompleteVideoSalesChannel: async () => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.getStarted}`;

        dataLakePayloadToTrack(
            'onboarding_complete_videosaleschannel',
            'task_completed',
            {
                duration: 0,
                location: currentUrl,
            },
            '',
            '',
            {
                type: null,
                title: null,
                xPath: null,
            },
        );
    },
    onboardingAddFirstPopclip: async () => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.getStarted}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.verifyAccount}`;

        dataLakePayloadToTrack(
            'onboarding_add_firstpopclip',
            'task_initiated',
            {
                duration: 0,
                location: currentUrl,
            },
            '',
            entryURL,
            {
                type: 'button',
                title: 'Add Now',
                xPath: '//*[@id="app"]/div[1]/div[2]/div/div[2]/div[3]/div/div[2]/div/div/button',
            },
        );
    },
    onboardingAddPopclipCarousel: async () => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.landing}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.getStarted}`;

        dataLakePayloadToTrack(
            'onboarding_add_popclipcarousel',
            'task_initiated',
            {
                duration: 0,
                location: currentUrl,
            },
            '',
            entryURL,
            {
                type: 'button',
                title: 'Publish Now',
                xPath: '//*[@id="app"]/div[1]/div/div/div/div/div[1]/div/div/div/div[2]/div[2]/div/div[2]/div/div[4]/div/div[2]/div/div/button',
            },
        );
    },
    browsingViewWelcomeScreen: async () => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.landing}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.landing}`;

        dataLakePayloadToTrack(
            'browsing_view_welcomescreen',
            'page_view',
            {
                duration: 0,
                location: currentUrl,
            },
            '',
            entryURL,
            {
                type: null,
                title: null,
                xPath: null,
            },
        );
    },
    browsingClickPopclipsManageButton: async () => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.landing}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.landing}`;

        dataLakePayloadToTrack(
            'browsing_click_popclipsmanagebutton',
            'task_initiated',
            {
                duration: 0,
                location: currentUrl,
            },
            '',
            entryURL,
            {
                type: 'button',
                title: 'Manage Popclips',
                xPath: '//*[@id="app"]/div[1]/div/div/div/div/div[2]/div/div[2]/div[1]/div[2]/div[2]/button[1]',
            },
        );
    },
    popclipClickCreateButton: async () => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${popclipsRoutes.list}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.landing}`;

        dataLakePayloadToTrack(
            'popclip_click_createbutton',
            'task_initiated',
            {
                duration: 0,
                location: currentUrl,
            },
            '',
            entryURL,
            {
                type: 'button',
                title: 'Create Popclip',
                xPath: '//*[@id="app"]/div[1]/div/div/div/div/div[2]/div/div/div[2]/div/div/div/div[1]/div/div[2]/button[2]',
            },
        );
    },
    popclipClickEditButton: async (popclipsId: string) => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${popclipsRoutes.list}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.landing}`;

        dataLakePayloadToTrack(
            'popclip_click_editbutton',
            'task_initiated',
            {
                duration: 0,
                location: currentUrl,
            },
            popclipsId,
            entryURL,
            {
                type: 'button',
                title: 'Edit',
                xPath: `popclip_editbtn_${popclipsId}`,
            },
        );
    },
    onCreateAllPopclipsCarousel: async (
        experiment_details = "",
        xPath = "",
    ) => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const entryURL = `${shopifyAdminURL}${popclipsRoutes.list}`;
        const currentUrl = `${shopifyAdminURL}${window.location.pathname}`;
        const currentTimeStamp = Date.now();

        dataLakePayloadToTrack(
            'popclip_trigger_generalcarouselcreation',
            'carousel_creation_submission',
            {
                general_carousel_id: `${shopStore.shopHandle}_${currentTimeStamp}`,
                experiment_details: experiment_details,
                location: currentUrl,
            },
            'generic_carousel',
            entryURL,
            {
                type: 'button',
                title: 'Create All-Popclips Carousel',
                xPath: xPath,
            },
            currentTimeStamp,
        );
    },
    popclipClickButtonInViewLimitModal: async (
        eventAction: string,
        elementActionStage: string,
        buttonTitle: string,
        offer_product_skucode: string[],
        offer_total_amount: number,
        xPath: string,
        givenOfferAccepted: boolean,
    ) => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${popclipsRoutes.list}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.landing}`;

        dataLakePayloadToTrack(
            eventAction,
            elementActionStage,
            {
                duration: 0,
                location: currentUrl,
                existing_bundle_skucode: shopStore.activePlan?.skuCode,
                existing_addon_skucode: shopStore.getActivePlanExtraAddonSKUs(),
                offer_product_skucode: offer_product_skucode,
                offer_total_amount: offer_total_amount,
                offer_total_currency: 'USD',
                views_consumed_before_reminder: shopStore.featureGatingData
                    ?.popclips?.totalViews
                    ? Number(
                          shopStore.featureGatingData?.popclips?.totalViews.replace(
                              /,/g,
                              '',
                          ),
                      )
                    : 0,
                given_offer_accepted: givenOfferAccepted
            },
            shopStore.ownerEmail,
            entryURL,
            {
                type: 'button',
                title: buttonTitle,
                xPath: xPath,
            },
        );
    },
    popclipRemovewatermakModal: async (
        eventAction: string,
        elementActionStage: string,
        buttonTitle: string,
        offer_product_skucode: string[],
        offer_total_amount: number,
        xPath: string,
        givenOfferAccepted: boolean,
    ) => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${window.location.pathname}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.landing}`;

        dataLakePayloadToTrack(
            eventAction,
            elementActionStage,
            {
                duration: 0,
                location: currentUrl,
                existing_bundle_skucode: shopStore.activePlan?.skuCode,
                existing_addon_skucode: shopStore.getActivePlanExtraAddonSKUs(),
                offer_product_skucode: offer_product_skucode,
                offer_total_amount: offer_total_amount,
                offer_total_currency: 'USD',
                given_offer_accepted: givenOfferAccepted
            },
            shopStore.ownerEmail,
            entryURL,
            {
                type: 'button',
                title: buttonTitle,
                xPath: xPath,
            },
        );
    },
    accountSelectSubscriptionPlan: async (addonSku: string, planAddonSku: string, valueStr: string, currencyStr: string) => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.selectPlans}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.selectPlans}`;

        dataLakePayloadToTrack(
            'account_select_subscriptionplan',
            'task_completed',
            {
                duration: 0,
                location: currentUrl,
                plan_selected: planAddonSku,
                plan_value: valueStr,
                plan_currency: currencyStr,
            },
            shopStore.ownerEmail,
            entryURL,
            {
                type: 'button',
                title: 'Select Plan',
                xPath: `select_planbtn_${addonSku}`,
            },
        );
    },
    accountConfirmSubscriptionPlan: async (btnTitle: string, btnXPath: string) => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.selectPlans}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.selectPlans}`;

        const basePlan = billingStore.plans.find(({ skuCode }) => skuCode === billingStore.selectedPlanSku);
        const selectedAddons = billingStore.addons.filter(({ id }) => billingStore.selectedAddonsIdsForEvent.includes(id));
        const addonSkus = selectedAddons.map((addon) => addon.skuCode);
        const addonAmts = selectedAddons.map((addon) => addon.price[0].amount);
        const addonCurrencies = selectedAddons.map((addon) => addon.price[0].currencyCode);

        const planAddonSku = `${basePlan.skuCode}, ${addonSkus.join(',')}`;
        const valueStr = `${basePlan.price[0].amount}, ${addonAmts.join(',')}`;
        const currencyStr = `${basePlan.price[0].currencyCode}, ${addonCurrencies.join(',')}`;
        const discountCode = billingStore.isDiscountCodeApplied ? billingStore.discountCode.toUpperCase().trim() : 'N/A';

        dataLakePayloadToTrack(
            'account_confirm_subscriptionplan',
            'task_completed',
            {
                duration: 0,
                location: currentUrl,
                plan_selected: planAddonSku,
                plan_value: valueStr,
                plan_currency: currencyStr,
                discount_code: discountCode,
            },
            shopStore.ownerEmail,
            entryURL,
            {
                type: 'button',
                title: btnTitle,
                xPath: btnXPath,
            },
        );
    },
    accountCheckoutSubscriptionPlan: async (reasonType: keyof typeof SUBSCRIPTION_TYPE, btnTitle: string, btnXPath: string) => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.selectPlans}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.selectPlans}`;

        let basePlan;
        let selectedAddons;
        let discountCode;
        const planChangeRequest = shopStore.pendingSubscription?.draft;

        if (planChangeRequest) {
            basePlan = planChangeRequest.lineItems.filter((item: any) => item.type === 'plan')[0].item;
            const extraAddons = planChangeRequest.lineItems.filter((item: any) => item.type === 'addon').map((row: any) => row.item);
            const discountItem = planChangeRequest.lineItems.filter((item: any) => item.type === 'discount')[0]?.item || null;

            selectedAddons = [...basePlan.addons, ...extraAddons];
            discountCode = discountItem ? discountItem.discountCode.toUpperCase().trim() : 'N/A';
        } else {
            basePlan = billingStore.plans.find(({ skuCode }) => skuCode === billingStore.selectedPlanSku);
            selectedAddons = billingStore.addons.filter(({ id }) => billingStore.selectedAddonsIdsForEvent.includes(id));
            discountCode = billingStore.isDiscountCodeApplied ? billingStore.discountCode.toUpperCase().trim() : 'N/A';
        }

        const addonSkus = selectedAddons.map((addon) => addon.skuCode);
        const addonAmts = selectedAddons.map((addon) => addon.price[0].amount);
        const addonCurrencies = selectedAddons.map((addon) => addon.price[0].currencyCode);

        const planAddonSku = `${basePlan.skuCode}, ${addonSkus.join(',')}`;
        const valueStr = `${basePlan.price[0].amount}, ${addonAmts.join(',')}`;
        const currencyStr = `${basePlan.price[0].currencyCode}, ${addonCurrencies.join(',')}`;

        const gatingData = shopStore.featureGatingData?.popclips;
        const limitAccess = gatingData && gatingData?.maxViews != '-1' && gatingData?.limitAccess || false;

        await dataLakePayloadToTrack(
            'account_checkout_subscriptionplan',
            'task_completed',
            {
                duration: 0,
                location: currentUrl,
                plan_selected: planAddonSku,
                plan_value: valueStr,
                plan_currency: currencyStr,
                discount_code: discountCode,
                reason: limitAccess ? SUBSCRIPTION_TYPE.UPGRADE_GATED : SUBSCRIPTION_TYPE[reasonType]
            },
            shopStore.ownerEmail,
            entryURL,
            {
                type: 'button',
                title: btnTitle,
                xPath: btnXPath,
            },
        );
    },
    accountEnterContactDetails: async (accountPhNo: string, accountEmail: string) => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.createAccount}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.selectPlans}`;

        let basePlan;
        let selectedAddons;
        let discountCode;
        const subscriptionLineItems = shopStore.subscription?.lineItems;

        if (subscriptionLineItems) {
            basePlan = subscriptionLineItems.filter((item: any) => item.type === 'plan')[0].item;
            const extraAddons = subscriptionLineItems.filter((item: any) => item.type === 'addon').map((row: any) => row.item);
            const discountItem = subscriptionLineItems.filter((item: any) => item.type === 'discount')[0]?.item || null;

            selectedAddons = [...basePlan.addons, ...extraAddons];
            discountCode = discountItem ? discountItem.discountCode.toUpperCase().trim() : 'N/A';
        } else {
            basePlan = billingStore.plans.find(({ skuCode }) => skuCode === billingStore.selectedPlanSku);
            selectedAddons = billingStore.addons.filter(({ id }) => billingStore.selectedAddonsIdsForEvent.includes(id));
            discountCode = billingStore.isDiscountCodeApplied ? billingStore.discountCode.toUpperCase().trim() : 'N/A';
        }

        const addonSkus = selectedAddons.map((addon) => addon.skuCode);
        const addonAmts = selectedAddons.map((addon) => addon.price[0].amount);
        const addonCurrencies = selectedAddons.map((addon) => addon.price[0].currencyCode);

        const planAddonSku = `${basePlan.skuCode}, ${addonSkus.join(',')}`;
        const valueStr = `${basePlan.price[0].amount}, ${addonAmts.join(',')}`;
        const currencyStr = `${basePlan.price[0].currencyCode}, ${addonCurrencies.join(',')}`;

        dataLakePayloadToTrack(
            'account_enter_contactdetails',
            'task_completed',
            {
                duration: 0,
                location: currentUrl,
                plan_selected: planAddonSku,
                plan_value: valueStr,
                plan_currency: currencyStr,
                discount_code: discountCode,
                reason: SUBSCRIPTION_TYPE.FIRST_EVER,
                account_tel_provided : accountPhNo,
                account_email_provided : accountEmail,
            },
            shopStore.ownerEmail,
            entryURL,
            {
                type: 'button',
                title: 'Continue',
                xPath: '//*[@id="app"]/div[1]/div/div/div/div/div[2]/div/div/div/div[2]/div/div[3]/div[2]/button',
            },
        );
    },
    accountVerificationOtpConfirmation: async () => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.verifyAccount}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.createAccount}`;

        let basePlan;
        let selectedAddons;
        let discountCode;
        const subscriptionLineItems = shopStore.subscription?.lineItems;

        if (subscriptionLineItems) {
            basePlan = subscriptionLineItems.filter((item: any) => item.type === 'plan')[0].item;
            const extraAddons = subscriptionLineItems.filter((item: any) => item.type === 'addon').map((row: any) => row.item);
            const discountItem = subscriptionLineItems.filter((item: any) => item.type === 'discount')[0]?.item || null;

            selectedAddons = [...basePlan.addons, ...extraAddons];
            discountCode = discountItem ? discountItem.discountCode.toUpperCase().trim() : 'N/A';
        } else {
            basePlan = billingStore.plans.find(({ skuCode }) => skuCode === billingStore.selectedPlanSku);
            selectedAddons = billingStore.addons.filter(({ id }) => billingStore.selectedAddonsIdsForEvent.includes(id));
            discountCode = billingStore.isDiscountCodeApplied ? billingStore.discountCode.toUpperCase().trim() : 'N/A';
        }

        const addonSkus = selectedAddons.map((addon) => addon.skuCode);
        const addonAmts = selectedAddons.map((addon) => addon.price[0].amount);
        const addonCurrencies = selectedAddons.map((addon) => addon.price[0].currencyCode);

        const planAddonSku = `${basePlan.skuCode}, ${addonSkus.join(',')}`;
        const valueStr = `${basePlan.price[0].amount}, ${addonAmts.join(',')}`;
        const currencyStr = `${basePlan.price[0].currencyCode}, ${addonCurrencies.join(',')}`;

        dataLakePayloadToTrack(
            'account_verification_otpconfirmation',
            'task_completed',
            {
                duration: 0,
                location: currentUrl,
                plan_selected: planAddonSku,
                plan_value: valueStr,
                plan_currency: currencyStr,
                discount_code: discountCode,
                reason: SUBSCRIPTION_TYPE.FIRST_EVER,
            },
            shopStore.ownerEmail,
            entryURL,
            {
                type: 'button',
                title: 'Verify',
                xPath: '//*[@id="app"]/div[1]/div/div/div/div/div[2]/div/div/div/div[2]/div/div[3]/div[2]/button',
            },
        );
    },
    goLiveClickedEvent: async () => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.landing}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.landing}`;

        dataLakePayloadToTrack(
            'welcomescreen_click_golive',
            'click_golive',
            { location: currentUrl },
            shopStore.ownerEmail,
            entryURL,
            {
                type: 'button',
                title: 'Go Live',
                xPath: '//*[@id="app"]/div[1]/div/div/div[1]/div/div[3]/div/div[2]/div[2]/div[2]/div[2]/button',
            },
            null,
            "welcomescreen"
        );
    },
    scheduleOnboardingCall : async () => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.landing}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.landing}`;

        dataLakePayloadToTrack(
            'welcomescreen_schedule_onboardingcall',
            'schedule_onboardingcall',
            { location: currentUrl },
            shopStore.ownerEmail,
            entryURL,
            {
                type: 'button',
                title: 'Schedule a free onboarding call',
                xPath: '//*[@id="app"]/div[1]/div/div/div[1]/div/div[1]/div/div/div/div/div/div/div/div/div/a/div',
            },
            null,
            "welcomescreen"
        );
    },
    setupMobileAppEvent : async (event:string, elementActionStage:string, elementCtaTitle:string, options = {}) => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.landing}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.landing}`;

        dataLakePayloadToTrack(
            event,
            elementActionStage,
            {
                location: currentUrl,
                ...options
            },
            shopStore.ownerEmail,
            entryURL,
            {
                type: 'button',
                title: elementCtaTitle,
                xPath: '//*[@id="app"]/div[1]/div/div/div[1]/div/div[3]/div/div[2]/div[2]/div[3]/div[2]/div/button',
            },
            null,
            "welcomescreen"
        );
    },
    submitMobileAppEvent: async (contextOptions = {}) => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${mobileRoutes.setup}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.landing}`;

        const toSnakeCase = (str:string) => {
            return str.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();
          }

        const convertKeysToSnakeCase = (obj) => {
            if (Array.isArray(obj)) {
                return obj.map(convertKeysToSnakeCase);
          } else if (obj !== null && typeof obj === "object") {
                return Object.keys(obj).reduce((acc, key) => {
                    const newKey = toSnakeCase(key);
                    acc[newKey] = convertKeysToSnakeCase(obj[key]);
                    return acc;
                }, {});
            }
            return obj;
        }

        const options = Object.keys(contextOptions)?.length > 0 ? convertKeysToSnakeCase(contextOptions) : contextOptions

        dataLakePayloadToTrack(
            'welcomescreen_submit_mobileapp',
            'app_submission',
            {
                location: currentUrl,
                ...options
            },
            shopStore.ownerEmail,
            entryURL,
            {
                type: 'button',
                title: 'Submit Your App',
                xPath: '//*[@id="basic-collapsible"]/div/div/div/button',
            },
            null,
            "welcomescreen"
        );
    },
    customBannerReaction: async (
        banner_rule: string,
        banner_rule_id: string,
        banner_title: string,
        banner_cta_link: string,
        is_external_link: boolean,
        dismissed_by_user: boolean,
        button_title: string,
        xPath: string,
        currentUrl: string,
    ) => {
        dataLakePayloadToTrack(
            'account_reaction_ownadminbanner',
            'reaction_banner',
            {
                location: currentUrl,
                banner_rule,
                banner_rule_id,
                banner_title,
                banner_cta_link,
                is_external_link,
                dismissed_by_user,
            },
            shopStore.ownerEmail,
            currentUrl,
            {
                type: 'button',
                title: button_title,
                xPath: xPath,
            },
        );
    },
    popclipSettingChangedEvent: async (options ={}) => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${popclipsRoutes.settings}`;
        const entryURL = `${shopifyAdminURL}${popclipsRoutes.list}`;

        dataLakePayloadToTrack(
            'popclip_setting_changed',
            'click_save',
            {
                ...options, 
                location: currentUrl
            },
            shopStore.ownerEmail,
            entryURL,
            {
                type: 'button',
                title: 'Save',
                xPath: '//*[@id="AppFrameTopBar"]/div/div[1]/div/div/div[2]/div[2]/div/div/div/div[2]/span[2]/div/button',
            },
            null,
            "popclips"
        );
    },
    visitInsightPageEvent: async () => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${vfsRoutes.insights}`;
        const entryURL = `${shopifyAdminURL}${vfsRoutes.landing}`;

        dataLakePayloadToTrack(
            'browsing_view_insightspage',
            'page_view',
            {
                location: currentUrl,
                insights_areas: ["popclips analytics"]
            },
            shopStore.ownerEmail,
            entryURL,
            {
                type: null,
                title: null,
                xPath: null,
            },
            null,
            "browsing"
        );
    },
    composePushNotificationEvent: async (notificationId:string, buttonTitle:string, options= {}) => {
        const shopifyAdminURL = `https://admin.shopify.com/store/${shopStore.shopHandle}/apps/${APP_NAME_BY_ENV}`;
        const currentUrl = `${shopifyAdminURL}${mobileRoutes.composePushNotification}`;
        const entryURL = `${shopifyAdminURL}${mobileRoutes.notification}`;

        dataLakePayloadToTrack(
            'mobileapp_create_pushnotification',
            'create_pushnotification',
            {
                location: currentUrl,
                ...options
            },
            shopStore.ownerEmail +'@'+ notificationId,
            entryURL,
            {
                type: "button",
                title: buttonTitle,
                xPath: '//*[@id="app"]/div[1]/div/div/div[1]/div/div[2]/div/div/div/div/div/div/div/div[2]/div[2]/div[4]/div/div/button',
            },
            null,
            "mobileapp"
        );
    }
});

const dataLakePayloadToTrack = async (
    eventAction: string,
    elementActionStage: string,
    options = {},
    elementID: string,
    entryURL: string,
    elementCTA: {
        type: string | null;
        title: string | null;
        xPath: string | null;
    },
    currentTimeStamp: null | number = null,
    elementCategory: null | string = null,
) => {
    const parser = new UAParser();
    const sessionId = await getFingerprint();
    const hashSessionId = sha256(sessionId);
    const { deviceModel, deviceManufacturer } = getDeviceInfo(parser);

    const payloadData = {
        element_action: eventAction,
        element_category: elementCategory ? elementCategory : eventAction.split('_')[0] == 'popclip' ? 'popclips' : eventAction.split('_')[0],
        event_timestamp: currentTimeStamp ? currentTimeStamp : Date.now(),
        element_action_stage: elementActionStage,
        element_cta_type: elementCTA.type,
        element_cta_title: elementCTA.title,
        element_cta_objectid: elementCTA.xPath,
        entry_url: entryURL,
        element_identifier: elementID,
        shop_id: shopStore.shopifyDomain,
        session_id: `${shopStore.shopifyDomain}_${hashSessionId}`,
        shop_user_id: shopStore.ownerEmail,
        fingerprint_id: hashSessionId,
        event_platform: 'vfs_admin_web',
        event_platform_version: '1.0.0',
        event_context: {
            device_model: deviceModel,
            device_manufacturer: deviceManufacturer,
            operating_system: parser?.getOS()?.name || 'Unknown',
            os_version: parser?.getOS()?.version || 'Unknown',
            language: navigator.language.split('-')[0],
            locale: navigator.language,
            experiment_details: '',
            notifications_enabled: false,
            gclid: null,
            dclid: null,
            gbraid: null,
            wbraid: null,
            fbclid: null,
            twclid: null,
            clid_src: null,
            msclkid: null,
            ttclid: null,
            ...options,
        },
    };
    try {
        const res = fetch(
            `${shopStore.config?.csDataLakeConfig?.apiUrl}/ingest/productanalytics`,
            {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payloadData),
                redirect: 'follow',
            },
        );

        return res;
    } catch (error) {
        console.log('Failed to fetch data lake API: ', error);
    }
};

export default analyticsEventStore;
