import { shopStore } from '@/stores';
import { openEmbedWindow } from '@/utils/helpers';
import { Modal, TitleBar } from '@shopify/app-bridge-react';
import {
    BlockStack,
    Box,
    Button,
    Divider,
    InlineStack,
    Link,
    Text,
} from '@shopify/polaris';
import { ArrowDiagonalIcon } from '@shopify/polaris-icons';
import { useRef } from 'react';
import { popclipsAddToPageVideo } from '../../../assets/images';
import { useSnapshot } from 'valtio';

type CarouselPublishModalProps = {
    openPublishModal: boolean;
    hidePublishModal: () => void;
};

export default function CarouselPagePublishModal({
    openPublishModal,
    hidePublishModal,
}: CarouselPublishModalProps) {
    const title = 'Publish Page Carousel';
    const subHeader = 'Follow the steps below to use Page Carousels';
    const urlLink = 'https://www.youtube.com/watch?v=1eS_8RCY6pU';

    const { popclipCarouselEmbedEnableUrl } = useSnapshot(shopStore);

    const themeWindowRef = useRef<Window | null>();

    const handleOpenEmbedWindow = async () => {
        const shopifyThemeEditorUrl = popclipCarouselEmbedEnableUrl
            .replace('popclips-carousel', 'popclips-page-reels')
            .concat(`&template=product`);

        openEmbedWindow(themeWindowRef, shopifyThemeEditorUrl);
        // if (!getStartedStatus.events.STEP_PUBLISH_YOUR_FIRST_POPCLIP_CAROUSEL) {
        //     shopStore.updateGetStartedStatus('STEP_PUBLISH_YOUR_FIRST_POPCLIP_CAROUSEL');
        // }
    };

    return (
        <Modal
            open={openPublishModal}
            variant="small"
            onHide={hidePublishModal}
        >
            <TitleBar title={title}>
                <button variant="primary" onClick={hidePublishModal}>
                    Done
                </button>
            </TitleBar>
            <Box padding="400">
                <BlockStack gap="500">
                    <BlockStack gap="300" align="start">
                        <InlineStack wrap={false}>
                            <Text as="p" variant="headingSm" tone="subdued">
                                {subHeader}{' '}
                                <Link
                                    url={urlLink}
                                    removeUnderline
                                    target="_blank"
                                >
                                    Watch this video
                                </Link>
                            </Text>
                        </InlineStack>
                    </BlockStack>
                    <Divider />
                    <BlockStack gap="300">
                        <InlineStack gap="300" blockAlign="center">
                            <Box
                                borderRadius="full"
                                background="bg-fill-disabled"
                                width="2rem"
                                padding="100"
                                paddingInlineStart="300"
                            >
                                <Text as="p" tone="subdued" fontWeight="bold">
                                    1
                                </Text>
                            </Box>
                            <Text as="p" variant="headingSm">
                                Add carousel to any product page
                            </Text>
                        </InlineStack>
                        <InlineStack align="start">
                            <Button
                                variant="primary"
                                size="micro"
                                icon={ArrowDiagonalIcon}
                                onClick={handleOpenEmbedWindow}
                            >
                                Go To Theme Editor
                            </Button>
                        </InlineStack>
                    </BlockStack>
                    <Divider />
                    <BlockStack gap="300">
                        <InlineStack gap="300" blockAlign="center">
                            <Box
                                borderRadius="full"
                                background="bg-fill-disabled"
                                width="2rem"
                                padding="100"
                                paddingInlineStart="300"
                            >
                                <Text as="p" tone="subdued" fontWeight="bold">
                                    2
                                </Text>
                            </Box>
                            <Box width="19rem">
                                <Text as="p" variant="headingSm">
                                    Add a new Page Carousel App block in
                                    your Shopify theme and save it.
                                </Text>
                            </Box>
                        </InlineStack>
                        <img
                            src={popclipsAddToPageVideo}
                            alt="customCarouselPublish"
                        />
                    </BlockStack>
                </BlockStack>
            </Box>
        </Modal>
    );
}
