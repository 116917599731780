import {
    BlockStack,
    Box,
    Button,
    Grid,
    IndexTable,
    InlineStack,
    Pagination,
    SkeletonBodyText,
    Text,
    useIndexResourceState,
} from '@shopify/polaris';
import DashboardHeaderSection from './DashboardHeaderSection';
import { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { shopStore, videoLiveSellingStore } from '@/stores';
import { NamingObjectTypes, PopclipsStaticsTypes } from './dashboardTypes';
import VfsModal from '../../v2/Components/VfsModal';
import { commaSeprateValue } from '@/utils/MoneyFormatter';
import { popclipsRoutes } from '@/constants/routes.constants';
import { generatePath, useNavigate } from 'react-router-dom';
import { formatTimeDateYearInLocalTime } from '@/utils/formatDate';

const modalContetToDisplay = [
    'popclip_title',
    'total_sales',
    'sales_direct_amount',
    'sales_influenced_amount',
    'sales_indirect_amount',
    'total_views',
    'total_clicks',
    'total_add_to_carts',
    'total_orders',
];

const currencyFields = [
    'total_sales',
    'sales_direct_amount',
    'sales_influenced_amount',
    'sales_indirect_amount',
];

const spanGap = '100';
const rowSpanStyle = { fontSize: '14px', padding: '8px' };

const DashboardPopclipStatsTable = ({
    namingObject,
}: {
    namingObject: NamingObjectTypes;
}) => {
    const [isModalOpen, setIsOpenModal] = useState(false);
    const [popclipsDetailsToDisplay, setPopclipsDetailsToDisplay] =
        useState<PopclipsStaticsTypes | null>(null);
    const [popclipsStatData, setPopclipsStatData] = useState([]);
    const [isExpand, setIsExpand] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState({
        items: [],
        currentCursor: null,
        nextCursor: null,
        hasNextPage: false,
        hasPreviousPage: false,
        limit: 5,
    });

    const [cursorHistory, setCursorHistory] = useState([]);
    const [searchString, setSearchString] = useState('');
    const { shopifyDomain, popclipsServiceApi } = useSnapshot(shopStore);
    const navigate = useNavigate();

    const handleOpenPopclipDetails = (details: PopclipsStaticsTypes) => {
        setPopclipsDetailsToDisplay(details);
        setIsOpenModal(true);
    };

    const ClosePoplipDetailModal = () => {
        setIsOpenModal(false);
        setPopclipsDetailsToDisplay(null);
    };

    const handleResizeChart = () => {
        setIsExpand(!isExpand);
    };

    const handleViewPopclipClick = async () => {
        if (popclipsDetailsToDisplay?.popclip_id) {
            videoLiveSellingStore.resetCreatePopClip();
            navigate(generatePath(popclipsRoutes.edit, {
                id: popclipsDetailsToDisplay.popclip_id.toString()
            }));
        }
    };

    const handleNextPage = () => {
        if (pagination.hasNextPage) {
            setCursorHistory([...cursorHistory, pagination?.currentCursor]);
            fetchPopclipsStatsData(pagination.nextCursor);
        }
    };

    const handlePreviousPage = () => {
        if (cursorHistory.length > 0) {
            const previousCursor = cursorHistory[cursorHistory.length - 1];
            setCursorHistory(cursorHistory.slice(0, -1));
            fetchPopclipsStatsData(previousCursor);
        }
    };

    const fetchPopclipsStatsData = async (cursor = null) => {
        try {
            setIsLoading(true);
            const resp = await fetch(
                `${popclipsServiceApi}/dashboard/popclips-stats?shop_id=${shopifyDomain}&limit=5${
                    cursor ? '&cursor=' + cursor : ''
                }${searchString ? '&searchValue=' + searchString : ''}`,
                {
                    method: 'GET',
                },
            );
            const { data } = await resp.json();
            const { items } = data;

            setPopclipsStatData(items);

            if (data?.cursor) {
                setPagination((prevState) => ({
                    ...prevState,
                    items: data.items,
                    currentCursor: cursor,
                    nextCursor: data.cursor,
                    hasNextPage: data.hasNextPage,
                    hasPreviousPage: data.hasPreviousPage,
                }));
            } else {
                setPagination((prevState) => ({
                    ...prevState,
                    items: data.items,
                    hasNextPage: data.hasNextPage,
                    hasPreviousPage: data.hasPreviousPage,
                }));
            }

            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchPopclipsStatsData(null);
    }, [searchString]);

    useEffect(() => {
        if (popclipsServiceApi) {
            fetchPopclipsStatsData(null);
        }
    }, []);

    const resourceName = {
        singular: 'data',
        plural: 'data',
    };

    const { selectedResources } = useIndexResourceState(popclipsStatData);

    const rowMarkup =
        popclipsStatData &&
        popclipsStatData.map((item: PopclipsStaticsTypes, index: number) => (
            <IndexTable.Row
                id={item.popclip_id}
                key={item.popclip_id}
                selected={selectedResources.includes(item.popclip_id)}
                position={index}
            >
                <IndexTable.Cell>
                    <Box padding={spanGap}>
                        <Text
                            variant="bodyMd"
                            fontWeight="bold"
                            as="p"
                            breakWord={true}
                        >
                            <Button
                                variant="plain"
                                textAlign="left"
                                fullWidth={false}
                                onClick={() => handleOpenPopclipDetails(item)}
                            >
                                {item.popclip_title}
                            </Button>
                        </Text>
                    </Box>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <Box padding={spanGap}>{item.total_clicks}</Box>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <Box padding={spanGap}>
                        {commaSeprateValue(item.total_sales)}{' '}
                        {item?.currency || ''}
                    </Box>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <Box padding={spanGap}>{item.total_orders}</Box>
                </IndexTable.Cell>
            </IndexTable.Row>
        ));

    const rowMarkupExpandView =
        popclipsStatData &&
        popclipsStatData.map((item: PopclipsStaticsTypes, index: number) => (
            <IndexTable.Row
                id={item.popclip_id + item.popclip_title}
                key={item.popclip_id + item.popclip_title}
                selected={selectedResources.includes(item.popclip_id)}
                position={index}
            >
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>
                        <Button
                            variant="plain"
                            onClick={() => handleOpenPopclipDetails(item)}
                        >
                            {item.popclip_title}
                        </Button>
                    </div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>
                        {commaSeprateValue(item.total_sales)}{' '}
                        {item?.currency || ''}
                    </div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>
                        {commaSeprateValue(item?.sales_direct_amount || 0)}
                        {item?.currency || ''}
                    </div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>
                        {commaSeprateValue(item?.sales_influenced_amount || 0)}
                        {item?.currency || ''}
                    </div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>
                        {commaSeprateValue(item?.sales_indirect_amount || 0)}
                        {item?.currency || ''}
                    </div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>{item?.total_views || 0}</div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>{item.total_clicks}</div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>
                        {item?.total_add_to_carts || 0}
                    </div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                    <div style={rowSpanStyle}>{item.total_orders}</div>
                </IndexTable.Cell>
            </IndexTable.Row>
        ));

    return (
        <div>
            <Box
                borderWidth="025"
                borderColor="border"
                borderRadius="500"
                padding="600"
                background="bg-fill"
                shadow="200"
                width="100%"
                minHeight="410px"
            >
                <Box paddingBlockEnd="400">
                    <DashboardHeaderSection
                        title="Popclips Stats"
                        showResizeButton={true}
                        isExpand={isExpand}
                        handleResizeChart={handleResizeChart}
                        enableSearchFeature={
                            !(
                                popclipsStatData?.length == 0 &&
                                searchString?.length < 1
                            )
                        }
                        placeholder="Search by Popclip Name"
                        handleSearchData={(value) => setSearchString(value)}
                    />
                </Box>
                {isLoading ? (
                    <Box width="100%" minHeight="290px">
                        <BlockStack gap="400">
                            <SkeletonBodyText />
                            <SkeletonBodyText />
                            <SkeletonBodyText />
                        </BlockStack>
                    </Box>
                ) : popclipsStatData?.length && !isLoading ? (
                    <Box>
                        <IndexTable
                            resourceName={resourceName}
                            itemCount={popclipsStatData?.length || 0}
                            headings={[
                                { title: 'Popclip Title' },
                                { title: 'Clicks' },
                                { title: 'Total Sales' },
                                { title: 'Total Orders' },
                            ]}
                            selectable={false}
                        >
                            {rowMarkup}
                        </IndexTable>
                        <Box paddingBlockStart="400">
                            <InlineStack align="center">
                                <Pagination
                                    hasPrevious={pagination?.hasPreviousPage}
                                    onPrevious={handlePreviousPage}
                                    hasNext={pagination?.hasNextPage}
                                    onNext={handleNextPage}
                                />
                            </InlineStack>
                        </Box>
                    </Box>
                ) : (
                    <InlineStack align="center">No data available</InlineStack>
                )}
            </Box>
            {popclipsStatData && isExpand && !isModalOpen && (
                <VfsModal
                    onCloseModal={handleResizeChart}
                    title="Popclip Stats"
                    modalWidth="90%"
                >
                    <Box width="100%">
                        {popclipsStatData?.length && !isLoading ? (
                            <>
                                <IndexTable
                                    resourceName={resourceName}
                                    itemCount={popclipsStatData?.length || 0}
                                    headings={[
                                        { title: 'Popclip Title' },
                                        { title: 'Total Sales' },
                                        { title: 'Sales Direct Amount' },
                                        { title: 'Sales Influenced Amount' },
                                        { title: 'Sales Indirect Amount' },
                                        { title: 'Total Views' },
                                        { title: 'Clicks' },
                                        { title: 'Total Add to Carts' },
                                        { title: 'Total Orders' },
                                    ]}
                                    selectable={false}
                                >
                                    {rowMarkupExpandView}
                                </IndexTable>
                                <Box paddingBlockStart="400">
                                    <InlineStack align="center">
                                        <Pagination
                                            hasPrevious={
                                                pagination?.hasPreviousPage
                                            }
                                            onPrevious={handlePreviousPage}
                                            hasNext={pagination?.hasNextPage}
                                            onNext={handleNextPage}
                                        />
                                    </InlineStack>
                                </Box>
                            </>
                        ) : (
                            <InlineStack align="center">
                                No data available
                            </InlineStack>
                        )}
                    </Box>
                </VfsModal>
            )}

            {isModalOpen && popclipsDetailsToDisplay && (
                <VfsModal
                    onCloseModal={ClosePoplipDetailModal}
                    primaryAction={handleViewPopclipClick}
                    primaryActionText="View Popclip"
                    title={popclipsDetailsToDisplay?.popclip_title}
                    modalWidth="30rem"
                >
                    <Box padding="400">
                        <Grid
                            gap={{
                                xs: '30px',
                                sm: '30px',
                                md: '20px',
                                lg: '30px',
                                xl: '30px',
                            }}
                        >
                            {modalContetToDisplay.map((key: string) => (
                                <Grid.Cell
                                    key={key}
                                    columnSpan={{
                                        xs: 6,
                                        sm: 3,
                                        md: 3,
                                        lg: 6,
                                        xl: 6,
                                    }}
                                >
                                    <BlockStack gap="200">
                                        <Text
                                            variant="bodyLg"
                                            fontWeight="semibold"
                                            as="p"
                                        >
                                            {namingObject[key] || key}
                                        </Text>
                                        {currencyFields.includes(key) ? (
                                            <Text variant="bodyLg" as="p">
                                                {commaSeprateValue(
                                                    popclipsDetailsToDisplay?.[
                                                        key
                                                    ] || 0,
                                                )}{' '}
                                                {popclipsDetailsToDisplay?.currency ||
                                                    ''}
                                            </Text>
                                        ) : key == 'created_at' ? (
                                            <Text variant="bodyLg" as="p">
                                                {formatTimeDateYearInLocalTime(
                                                    popclipsDetailsToDisplay[
                                                        key
                                                    ],
                                                )}
                                            </Text>
                                        ) : (
                                            <Text variant="bodyLg" as="p">
                                                {popclipsDetailsToDisplay[key]}
                                            </Text>
                                        )}
                                    </BlockStack>
                                </Grid.Cell>
                            ))}
                        </Grid>
                    </Box>
                </VfsModal>
            )}
        </div>
    );
};

export default DashboardPopclipStatsTable;
