import { popclipsRoutes } from "@/constants/routes.constants";
import { analyticsEventStore } from "@/stores";
import { BlockStack, Box, Icon, InlineStack, Link, Text } from "@shopify/polaris";
import { ArrowDiagonalIcon, CaretDownIcon, CaretUpIcon, CheckCircleIcon, ClockIcon } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";

type WizardStepProps = {
    stepExpanded: boolean;
    isCompleted: boolean;
};

export default function WizardStep3({
    stepExpanded,
    isCompleted,
}: WizardStepProps) {
    const navigate = useNavigate();

    const addNewPopclips = () => {
        analyticsEventStore.onboardingAddFirstPopclip();
        navigate(popclipsRoutes.create);
    }

    return (
        <Box
            background={
                !isCompleted && stepExpanded ? 'bg-fill-active' : 'bg-fill'
            }
            padding="300"
            borderRadius="200"
        >
            <InlineStack align="space-between">
                <InlineStack gap="300">
                    <Icon source={CheckCircleIcon} />
                    <Text as="p" variant="bodyLg">
                        Add your first Popclip
                    </Text>
                </InlineStack>
                {!isCompleted && (
                    <InlineStack gap="500">
                        <InlineStack gap="100">
                            <Icon source={ClockIcon} />
                            <p>3 Mins</p>
                        </InlineStack>
                        <Icon
                            source={stepExpanded ? CaretUpIcon : CaretDownIcon}
                        />
                    </InlineStack>
                )}
            </InlineStack>
            {!isCompleted && stepExpanded && (
                <Box paddingBlockStart="400">
                    <BlockStack gap="400">
                        <iframe
                            style={{ width: '100%', height: '320px' }}
                            src="https://www.youtube.com/embed/eahWKX0EYk0?si=YXFvr-m6rfwvy_P4"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                        ></iframe>
                        <Text as="p" tone="subdued">
                            Add your first Popclip. Upload or import from
                            Instagram{' '}
                            <Link
                                url="https://help.videeo.live/hc/en-us/articles/24540975607700-Create-a-Popclip-Shoppable-Video"
                                target="_blank"
                            >
                                <Text as="span" fontWeight="bold" tone="magic">
                                    Learn more
                                </Text>
                            </Link>
                        </Text>
                        <InlineStack align="start">
                            <button
                                className="onboarding-wizard-modal__step-cta"
                                onClick={addNewPopclips}
                            >
                                Add Now <Icon source={ArrowDiagonalIcon} />
                            </button>
                        </InlineStack>
                    </BlockStack>
                </Box>
            )}
        </Box>
    );
}