import { PopClipsGridListing } from '@/components/popclips';
import { BlockStack, Box, InlineStack, Text } from '@shopify/polaris';
import FeatureReleaseCalloutCard from '@/components/common/FeatureReleaseCalloutCard';
import { popclipsStore, shopStore } from '@/stores';
import { useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import PopclipsCarousel2kBanner from '@/components/common/PopclipsCarousel2kBanner';
import { vfsRoutes } from '@/constants/routes.constants';
import { BaseLayout } from '../../v2/pages/Layout/BaseLayout';
import { BYPASS_GATING_FOR_DOMAINS } from '@/constants/shopify.constants';
import SocialMediaPlanUpdateModal from './SocialMediaPlanUpdateModal';
import PopclipViewsLimitModal from './PopclipViewsLimitModal';
import ManageCustomCarousels from '../../components/popclips/popclips-grid-components/ManageCustomCarousels';
import VfsModal from '../../v2/Components/VfsModal';
import { useNavigate } from 'react-router-dom';
import CarouselPublishModal from './carouselComponents/CarouselPublishModal';
import PopclipSocialMediaShareButtons from '@/components/popclips/popclips-grid-components/PopclipSocialMediaShareButtons';
import PopClipsCard from '@/components/common/PopClipsCard';
import { VfsButton } from '@/components/common/v1';
import CarouselPagePublishModal from './carouselComponents/CarouselPagePublishModal';

const PopClipsGridPage = () => {
    const [showPopclipsCarousel2kBanner, setShowPopclipsCarousel2kBanner] =
        useState(false);
    const [showCustomCarousel, setShowCustomCarousel] = useState(false);
    const [openPublishModal, setOpenPublishModal] = useState(false);
    const [showPlanLimitModal, setShowPlanLimitModal] = useState(false);
    const [showPlanUpgradeModal, setShowPlanUpgradeModal] = useState(false);
    const [showViewsLimitModal, setShowViewsLimitModal] = useState(false);
    const [showPageCarouselPublishModal, setShowPageCarouselPublishModal] =
        useState(false);

    const navigate = useNavigate();

    const {
        subscription,
        popclipCarouselEmbedEnableUrl,
        shopifyDomain,
        featureGatingData,
        isFreePlan,
    } = useSnapshot(shopStore);
    const { popclipsToShare } = useSnapshot(popclipsStore);

    useEffect(() => {
        if (BYPASS_GATING_FOR_DOMAINS.includes(shopifyDomain)) {
            setShowCustomCarousel(true);
            setShowPopclipsCarousel2kBanner(true);
        } else {
            const addonPrices = shopStore.getActivePlanAddonPrices(
                'popclips',
                true,
            );
            const maxPrice = Math.max(...addonPrices);

            setShowCustomCarousel(maxPrice >= 29.99);
            setShowPopclipsCarousel2kBanner(maxPrice < 29.99);
        }
    }, [subscription]);

    useEffect(() => {
        setShowPlanLimitModal(shopStore.shouldLimitPopclipsAccess);
        setShowViewsLimitModal(shopStore.shouldShowViewsLimitModal);
    }, [featureGatingData, featureGatingData?.popclips]);

    useEffect(
        () => () => {
            popclipsStore.popclipsToShare = null;
        },
        [],
    );

    return (
        <BaseLayout
            title="Your Popclips"
            subtitle="All Popclips - Shoppable Videos linked to your account"
            backToLink={vfsRoutes.landing}
        >
            <BlockStack gap="400">
                {!showCustomCarousel && popclipCarouselEmbedEnableUrl && (
                    <FeatureReleaseCalloutCard
                        setOpenPublishModal={() => setOpenPublishModal(true)}
                        setShowPlanUpgradeModal={setShowPlanUpgradeModal}
                    />
                )}
                <PopClipsGridListing />
                {showPopclipsCarousel2kBanner && (
                    <PopclipsCarousel2kBanner
                        modalOpen={showPopclipsCarousel2kBanner}
                        toggleHideModal={() =>
                            setShowPopclipsCarousel2kBanner(false)
                        }
                    />
                )}

                <PopClipsCard>
                    <BlockStack gap="400">
                        <Box paddingBlockStart="400">
                            <h1 className="page-heading">
                                Manage Popclips Page Reels
                            </h1>
                        </Box>
                        <Text as="p" variant="bodyMd" tone='subdued'>
                                The Popclips Carousel enhances collection and
                                product pages by seamlessly displaying relevant
                                Popclips videos. On product pages, it
                                highlights product-specific videos, enriching
                                storytelling and driving deeper customer
                                engagement.
                            </Text>
                            <InlineStack
                                gap="200"
                                align="start"
                                blockAlign="stretch"
                            >
                                <VfsButton
                                    onClick={() =>setShowPageCarouselPublishModal(true)}
                                >
                                    Add Page Reels on product pages
                                </VfsButton>
                            </InlineStack>
                    </BlockStack>
                </PopClipsCard>
                {showCustomCarousel && <ManageCustomCarousels />}
            </BlockStack>
            {showPlanUpgradeModal && (
                <SocialMediaPlanUpdateModal
                    setShowSocialPlanUpdate={setShowPlanUpgradeModal}
                    socialButtonType="POPCLIPS_BASIC_PLAN"
                />
            )}
            <CarouselPublishModal
                title="Publish Carousel"
                subHeader="Follow the steps below to publish all your clips in a carousel on your store."
                urlLink="https://youtu.be/VlMVTEtkkgU?si=JxCFhq8mMU2CzGn5"
                openPublishModal={openPublishModal}
                hidePublishModal={() => setOpenPublishModal(false)}
                carouselId=""
                step1Enable={false}
                isAllPopclipsCarousel={true}
            />
            {showViewsLimitModal && (
                <PopclipViewsLimitModal
                    hideModal={() => setShowViewsLimitModal(false)}
                    planType={
                        isFreePlan
                            ? 'free'
                            : featureGatingData?.popclips?.maxViews === '15,000'
                              ? 'basic'
                              : ''
                    }
                />
            )}
            {showPlanLimitModal && (
                <VfsModal
                    onCloseModal={() => setShowPlanLimitModal(false)}
                    title=""
                    modalWidth="37rem"
                    closeable={false}
                    crossIcon={false}
                    primaryAction={() => navigate(vfsRoutes.managePlans)}
                    primaryActionText={'Upgrade Now'}
                    primaryActionTone="success"
                    secondaryAction={() => setShowPlanLimitModal(false)}
                    secondaryActionText="Cancel"
                    header="secondary"
                >
                    <Text as="p">
                        <strong>Important:</strong> You've reached your current
                        plan's view limits of{' '}
                        {featureGatingData?.popclips?.maxViews || ''}. In order
                        to create new Popclips please upgrade.
                    </Text>
                </VfsModal>
            )}
            {popclipsToShare && (
                <VfsModal
                    title="Share Link"
                    modalWidth="40rem"
                    closeable={false}
                    crossIcon={true}
                    onCloseModal={() => (popclipsStore.popclipsToShare = null)}
                >
                    <PopclipSocialMediaShareButtons
                        clipData={popclipsToShare}
                        setIsShareLinkModalOpen={() =>
                            (popclipsStore.popclipsToShare = null)
                        }
                    />
                </VfsModal>
            )}
            { showPageCarouselPublishModal &&(
                <CarouselPagePublishModal
                    openPublishModal={showPageCarouselPublishModal}
                    hidePublishModal={() => setShowPageCarouselPublishModal(false)}
                />
            )}
        </BaseLayout>
    );
};

export default PopClipsGridPage;
