import {
    Bleed,
    BlockStack,
    Box,
    Button,
    Card,
    Icon,
    InlineStack,
    Link,
    RadioButton,
    Text,
    Thumbnail,
} from '@shopify/polaris';
import {
    ImageIcon,
    LockFilledIcon,
    PlusIcon,
    ReplaceIcon,
} from '@shopify/polaris-icons';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { shopStore } from '@/stores';
import { useGetShopifyProducts } from '@/hooks/useGetShopifyProducts';
import { BYPASS_GATING_FOR_DOMAINS } from '@/constants/shopify.constants';
import popclipsStore, { fetchLikesAndViewsPerClip } from '@/stores/popclipsStore';
import { COLLECTION, POPCLIPS } from '@/constants/popclipsFeature.constants';
import { isQaEnv } from '@/utils/helpers';

type CarouselClipSelectorProps = {
    toggleOpenModal: () => void;
    selectedClipData: any[];
    handleCarouselTypeSelect: (type: string) => void;
    carouselType: string;
    handleCollectionSelect: (id: string) => void;
    handleCollectionNameSelect: (name: string) => void;
    collectionID: any[];
    setShowPlanUpgradeModal: Dispatch<SetStateAction<boolean>>;
    isEditMode?: boolean;
    setCollectionVideoPopclips: (clipsData: any) => void;
    isCollectionPopclipsLoading: boolean;
    setIsCollectionPopclipsLoading: (isCollectionPopclipsLoading: boolean) => void;
};

export default function CarouselClipSelector({
    selectedClipData,
    handleCarouselTypeSelect,
    carouselType,
    handleCollectionSelect,
    handleCollectionNameSelect,
    collectionID,
    isEditMode,
    setCollectionVideoPopclips,
    isCollectionPopclipsLoading,
    setIsCollectionPopclipsLoading,
    setShowPlanUpgradeModal,
}: CarouselClipSelectorProps) {
    const [showCollectionButton, setShowCollectionButton] = useState(false);
    const [selectedCollection, setSelectedCollection] = useState([]);

    const { getProductsByCollectionId } = useGetShopifyProducts();

    const handleTypeSelect = (type: string) => {
        handleCarouselTypeSelect(type);
    };

    const handlePlanCheck = () => {
        const addonPrices = shopStore.getActivePlanAddonPrices(
            POPCLIPS,
            true,
        );
        const maxPrice = Math.max(...addonPrices);

        if (BYPASS_GATING_FOR_DOMAINS.includes(shopStore.shopifyDomain)) {
            setShowPlanUpgradeModal(false);
            handleTypeSelect(COLLECTION);
        } else {
            setShowPlanUpgradeModal(maxPrice < 149.99);
            if (maxPrice >= 149.99) {
                handleTypeSelect(COLLECTION);
            }
        }
    };

    const handleCollectionPicker = async () => {
        const selected = await shopify.resourcePicker({
            type: COLLECTION,
            multiple: false,
            action: 'select',
            filters: {
                hidden: false,
                variants: false,
                draft: false,
                archived: false,
            },
            selectionIds: selectedCollection.map(({ id }) => ({ id })),
        });
        if (selected?.length > 0) {
            setSelectedCollection(selected);
            const result = selected.map(({ id }) => ({
                id,
            }));
            handleCollectionSelect(result[0]?.id);
            if(popclipsStore?.carouselToEdit?.sorted_popclips_ids?.length > 0){
                popclipsStore.carouselToEdit.sorted_popclips_ids = [];
            }
            fetchProductsIdsByCollection(result[0]?.id);
        }
    };

    const fetchProductsIdsByCollection = async (
        featuredShopifyCollectionGid: string,
    ) => {
        try {
            const response = await getProductsByCollectionId(
                featuredShopifyCollectionGid,
            );
            const productArr = response?.products?.edges.map(({ node }) => ({
                id: node?.id,
                updatedAt: node?.createdAt,
            }));
            if (isEditMode && response) {
                setSelectedCollection([response]);
            }
            handleCollectionNameSelect(response?.title);

            createPopclipByCollection(productArr);
        } catch (error) {
            console.log(error);
        }
    };

    const createPopclipByCollection = async (productArr: [] = []) => {
        try {
            if (productArr?.length < 1) {
                setCollectionVideoPopclips([]);
                return;
            }
            setIsCollectionPopclipsLoading(true);
            setCollectionVideoPopclips([]);

            const URL = isQaEnv
                ? 'https://qa.popclips-service.qa.cs-api.com/api/popclips/list'
                : 'https://prod.popclips-service.cs-api.com/api/popclips/list';
            const response = await fetch(URL,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        sellerId: shopStore.popshopliveSellerId,
                        products: productArr,
                    }),
                },
            );
            const { data } = await response.json();
            const clipsArrayItems = data.items || [];
            await fetchLikesAndViewsPerClip(clipsArrayItems);
            
            let reorderedResponseClipsArrayItems = clipsArrayItems;

            if (isEditMode && popclipsStore?.carouselToEdit?.sorted_popclips_ids?.length > 0) {
                const uniqueIds = Array.from(
                    new Set([
                        ...popclipsStore.carouselToEdit.sorted_popclips_ids,
                        ...clipsArrayItems.map((item) => item.metaIdentifier),
                    ])
                );
                reorderedResponseClipsArrayItems = uniqueIds.map((id) =>
                    clipsArrayItems.find((item) => item.metaIdentifier == id)
                );
            }

            setCollectionVideoPopclips(reorderedResponseClipsArrayItems);
        } catch (error) {
            console.log(error);
        } finally {
            setIsCollectionPopclipsLoading(false);
        }
    };

    useEffect(() => {
        if (collectionID?.[0]) {
            fetchProductsIdsByCollection(collectionID[0]);
        }
    }, [collectionID]);

    useEffect(() => {
        const addonPrices = shopStore.getActivePlanAddonPrices(
            POPCLIPS,
            true,
        );
        const maxPrice = Math.max(...addonPrices);

        if (BYPASS_GATING_FOR_DOMAINS.includes(shopStore.shopifyDomain)) {
            setShowCollectionButton(true);
        } else {
            setShowCollectionButton(maxPrice >= 149.99);
        }
    }, []);

    const radioButtonsSection = (
        <Box paddingInline="200">
            <InlineStack gap="400" wrap={false}>
                {(!isEditMode || (isEditMode && carouselType === 'custom')) && (
                    <Card>
                        <RadioButton
                            label="Select By Popclip"
                            checked={carouselType === 'custom'}
                            helpText="Pick your favorite Popclips and showcase them in a carousel on any page of your website."
                            id="popclip"
                            name="carousel_type"
                            onChange={() => handleTypeSelect('custom')}
                        />
                    </Card>
                )}
                {(!isEditMode ||
                    (isEditMode && carouselType === 'collection')) && (
                    <Card
                        background={
                            !showCollectionButton
                                ? 'bg-fill-disabled'
                                : 'bg-surface'
                        }
                    >
                        {!showCollectionButton && (
                            <Bleed marginBlockEnd="600">
                                <Icon source={LockFilledIcon} tone="base" />
                            </Bleed>
                        )}
                        <div onClick={handlePlanCheck}>
                            <RadioButton
                                disabled={!showCollectionButton}
                                label="Select By Collection"
                                checked={carouselType === 'collection'}
                                helpText="Choose a collection to create a carousel of Popclips featuring products from that collection on your website."
                                id="collection"
                                name="carousel_type"
                            />
                        </div>
                    </Card>
                )}
            </InlineStack>
        </Box>
    );

    const selectedCollectionSection =
        selectedCollection?.length > 0 ? (
            <InlineStack gap="400">
                {selectedCollection &&
                    selectedCollection.map((collection) => (
                        <Card padding="200" key={collection.id}>
                            <InlineStack gap="200">
                                <Thumbnail
                                    source={
                                        collection?.image?.originalSrc
                                            ? collection?.image?.originalSrc
                                            : ImageIcon
                                    }
                                    alt={collection?.title}
                                    size="small"
                                />
                                <BlockStack>
                                    <Text as="p">{collection?.title}</Text>
                                    {collection?.productsCount ||
                                    collection.productsCount == 0 ? (
                                        <Text as="p">
                                            {collection?.productsCount ||
                                            collection.productsCount == 0
                                                ? collection?.productsCount == 0
                                                    ? ' 0 product'
                                                    : collection?.productsCount +
                                                            collection?.productsCount >
                                                        1
                                                      ? collection.productsCount +
                                                        ' products'
                                                      : ' product'
                                                : collection.productsCount +
                                                  'products'}
                                        </Text>
                                    ) : (
                                        <Text as="p">
                                            {collection?.products?.edges?.length
                                                ? collection.products.edges
                                                      .length == 250
                                                    ? '250+ products'
                                                    : collection.products.edges
                                                          .length + ' products'
                                                : '0 product'}
                                        </Text>
                                    )}
                                </BlockStack>
                            </InlineStack>
                        </Card>
                    ))}
            </InlineStack>
        ) : (
            <Box paddingBlockEnd="200">
                <InlineStack gap="100">
                    <Text as="p">
                        You currently have no collection selected. Please ensure
                        the collection you want to add is published to the
                        Videeo and Online Sales Channels.
                    </Text>
                    <Link url="https://help.videeo.live/hc/en-us/articles/23185463459092-Publish-Products-and-Collections-to-Videeo-Sales-Channel#h_01HN3JSYHK2JTRGDTP7TQ17361">
                        Learn More
                    </Link>
                </InlineStack>
            </Box>
        );
    return (
            <Box>
                <Box padding="200" >
                    {carouselType == 'collection' ? (
                        <BlockStack gap="100" inlineAlign="baseline">
                            <Text as="h1" variant="headingMd" fontWeight="bold">
                                Select Collection
                            </Text>
                            <Text as="h1" variant="bodyMd">
                                Please select a collection to create a custom
                                carousel
                            </Text>
                        </BlockStack>
                    ) : (
                        <BlockStack gap="100" inlineAlign="baseline">
                            <Text as="h1" variant="headingMd" fontWeight="bold">
                                Selected Popclips ({selectedClipData.length})
                            </Text>
                            <Text as="h1" variant="bodyMd">
                                Please add Popclips (a minimum of 3) to this
                                custom carousel
                            </Text>
                        </BlockStack>
                    )}
                </Box>
            {radioButtonsSection}
            {carouselType == 'collection' ? (
                <Box paddingBlock="400">
                    <Text as="p" fontWeight="bold">
                        Selected Collection:
                    </Text>
                    <Box
                        paddingBlock={
                            selectedCollection?.length > 0 ? '400' : '100'
                        }
                    >
                        {selectedCollectionSection}
                    </Box>
                    {collectionID?.length < 1 ? (
                        <Button
                            icon={PlusIcon}
                            variant="primary"
                            onClick={handleCollectionPicker}
                        >
                            Select Collection
                        </Button>
                    ) : (
                        <Box>
                            {showCollectionButton && (
                                <Button
                                    variant="primary"
                                    icon={ReplaceIcon}
                                    onClick={handleCollectionPicker}
                                    loading={isCollectionPopclipsLoading}
                                >
                                    Replace Collection
                                </Button>
                            )}
                        </Box>
                    )}
                </Box>
            ) : null}
        </Box>
    );
}
