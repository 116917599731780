import { billingStore } from "@/stores";
import VfsModal from "../VfsModal";
import { ATTRIBUTE_IDS } from "@/constants/planFeatureCard.constants";
import AddonStyledComponent from "../PlanSelection/AddonStyledComponent";
import { BlockStack, Box, Button, InlineStack, Text } from "@shopify/polaris";

type SelectPlanState = {
    planId: string;
    type: string;
    skuCode: string;
} | undefined;

type PopclipsPlanModalProps = {
    selectedPlan: SelectPlanState;
    onHidePlanModal: (planData: SelectPlanState) => void;
};

const featureType = 'popclips';

export default function PopclipsPlanModal({
    onHidePlanModal,
    selectedPlan,
}: PopclipsPlanModalProps) {
    const addons = billingStore.getAddonsByModule(featureType, ATTRIBUTE_IDS[featureType]);
    const addonsPlansValues = billingStore.getAddonButtonGroupValues(addons, ATTRIBUTE_IDS[featureType]);

    const getPopclipsCardType = (type: string) => {
        switch (type) {
            case 'free':
            case 'free 2025':
                return 'free';
            case 'basic':
                return 'basic';
            case 'premium':
                return 'premium';
            default:
                return '';
        }
    };

    const selectAddon = (addon: any) => {
        if (selectedPlan?.type === getPopclipsCardType(addon.name.toLowerCase())) {
            onHidePlanModal(undefined);
        } else {
            onHidePlanModal({
                planId: addon.id,
                type: getPopclipsCardType(addon.name.toLowerCase()),
                skuCode: addon.skuCode,
            });
        }
    };
    
    return (
        <VfsModal
            onCloseModal={() => onHidePlanModal(selectedPlan)}
            title="Popclips Plans"
            flexDirection="column"
            modalWidth="70rem"
        >
            <BlockStack gap="600">
                <Box
                    padding="400"
                    borderWidth="025"
                    borderRadius="500"
                    shadow="200"
                    borderColor="border-brand"
                >
                    <InlineStack align="space-between" blockAlign="center">
                        <Text as="p" variant="headingMd" fontWeight="bold">
                            Need more views? Reach out to us!
                        </Text>
                        <Button
                            variant="primary"
                            target="_blank"
                            url="https://calendly.com/videeo/initial-videeo-onboarding"
                        >
                            Contact Sales
                        </Button>
                    </InlineStack>
                </Box>
                <div className="popclips-plan-modal-container">
                    {addons.map((addon, i) => (
                        <AddonStyledComponent
                            key={addon.id}
                            addonData={addon}
                            isDisabled={false}
                            addonValue={addonsPlansValues[i].value || ''}
                            type={getPopclipsCardType(addon.name.toLowerCase())}
                            selectedPlan={selectedPlan?.type || ''}
                            onSelectPlan={() => selectAddon(addon)}
                        />
                    ))}
                </div>
            </BlockStack>
        </VfsModal>
    );
}