import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    BlockStack,
    Spinner,
    Banner,
    Box,
    Text,
    InlineStack,
    Button,
    Icon,
} from '@shopify/polaris';
import {
    analyticsEventStore,
    popclipsStore,
    shopStore,
    videoLiveSellingStore,
} from '@/stores';
import PopClipsCard from '@/components/common/PopClipsCard';
import PopClipsGridEmptyState from './PopClipsGridEmptyState';
import ClipListingCard from './ClipListingCard';
import { popclipsRoutes, vfsRoutes } from '@/constants/routes.constants';
import type { ListMediaByIdResponse } from '@/types/ListMediaByIdResponse';
import { usePopclipsPagination } from '@/hooks/usePopclipsPagination';
import { useSnapshot } from 'valtio';
import { ChevronLeftIcon, ChevronRightIcon } from '@shopify/polaris-icons';
import { AutocompleteInput } from '@/components/common';
import { VfsButton, VfsImageComponent } from '@/components/common/v1';

const POLLING_INTERVAL = 1000 * 8;

type PopclipsGridCardHeaderProps = {
    onHandleClipSearchKeyword: (value: string) => any[];
    onHandleRefreshButton: () => void;
    onHandleButton: () => void;
    isSearchingPopclips: boolean;
    isApiLoading: boolean;
    handleSettingButtonClick: () => void;
};

type PopclipsGridCardFooterProps = {
    showPagination: boolean;
    showPrevPageBtn: boolean;
    prevPageClicked: () => void;
    showNextPageBtn: boolean;
    nextPageClicked: () => void;
};

const PopClipsGridListing = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { getPaginatedData, fetchLikesAndViewsPerClip } =
        usePopclipsPagination(14);
    const videoLiveSellingSnap = useSnapshot(videoLiveSellingStore);
    let timeoutId: number;

    const [clipDataArray, setClipDataArray] = useState<ListMediaByIdResponse[]>(
        [],
    );
    const [isApiLoading, setIsApiLoading] = useState(true);
    const [isSearchingPopclips, setIsSearchingPopclips] = useState(false);
    const [isDataAvailable, setIsDataAvailable] = useState(false);
    const [shouldShowInfoBanner, setShouldShowInfoBanner] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [searchCurrentPage, setSearchCurrentPage] = useState(1);
    const [searchedClipsCount, setSearchedClipsCount] = useState(0);

    useEffect(() => {
        setIsApiLoading(true);
        const params = location.state;
        const defaultEvalKeyObj = { 1: undefined };
        const { gridCurrentPage, gridLastEvalKey } = videoLiveSellingStore;
        const loadFirstPage =
            gridCurrentPage == 1 || (params && params.goToFirstPage);

        const paginationPromise = loadFirstPage
            ? getPaginatedData()
            : getPaginatedData(false, gridLastEvalKey[gridCurrentPage]);

        paginationPromise.then((response) => {
            if (response) {
                setIsDataAvailable(response.isDataAvailable);
                setClipDataArray(response.clipsWithFeatureClips);

                if (response.lastEvaluatedKey && loadFirstPage) {
                    Object.assign(defaultEvalKeyObj, {
                        [gridCurrentPage + 1]: response.lastEvaluatedKey,
                    });
                    videoLiveSellingStore.gridLastEvalKey = defaultEvalKeyObj;
                }
                setIsApiLoading(false);
            } else if (response === null) {
                setIsApiLoading(false);
            }
        });
    }, []);

    useEffect(() => {
        if (isSearchingPopclips) return;

        const hasPendingStatus = clipDataArray.some(
            (item) => item.status !== 'processed',
        );

        if (hasPendingStatus) {
            setShouldShowInfoBanner(true);
            timeoutId = window.setTimeout(() => {
                getPaginatedData(true).then((response) => {
                    if (response) setClipDataArray(response.clipsWithFeatureClips);
                });
            }, POLLING_INTERVAL);
        } else {
            setShouldShowInfoBanner(false);
            window.clearTimeout(timeoutId);
        }

        return () => {
            if (timeoutId) {
                window.clearTimeout(timeoutId);
            }
        };
    }, [clipDataArray]);

    const nextPageClicked = () => {
        if (isSearchingPopclips) {
            setSearchCurrentPage(searchCurrentPage + 1);
            onChangeAutocompleteValue(searchKeyword, searchCurrentPage + 1);
            return;
        }

        window.clearTimeout(timeoutId);
        setIsApiLoading(true);
        scrollPageToTop();

        const { gridCurrentPage, gridLastEvalKey } = videoLiveSellingStore;
        let cursor = gridLastEvalKey
            ? gridLastEvalKey[gridCurrentPage + 1]
            : undefined;

        getPaginatedData(false, cursor).then((response) => {
            if (response) {
                setIsDataAvailable(response.isDataAvailable);
                setClipDataArray(response.clipsWithFeatureClips);
                videoLiveSellingStore.gridCurrentPage =
                    videoLiveSellingStore.gridCurrentPage + 1;

                if (response.lastEvaluatedKey) {
                    let tempVar = gridLastEvalKey;
                    if (tempVar)
                        tempVar[gridCurrentPage + 2] =
                            response.lastEvaluatedKey;

                    videoLiveSellingStore.gridLastEvalKey = tempVar;
                }
            }
            setIsApiLoading(false);
            scrollPageToTop()
        });
    };

    const prevPageClicked = () => {
        if (isSearchingPopclips) {
            setSearchCurrentPage(searchCurrentPage - 1);
            onChangeAutocompleteValue(searchKeyword, searchCurrentPage - 1);
            return;
        }

        window.clearTimeout(timeoutId);
        setIsApiLoading(true);
        scrollPageToTop();

        const { gridCurrentPage, gridLastEvalKey } = videoLiveSellingStore;
        let cursor = gridLastEvalKey
            ? gridLastEvalKey[gridCurrentPage - 1]
            : undefined;
        videoLiveSellingStore.gridCurrentPage = gridCurrentPage - 1;

        getPaginatedData(false, cursor).then((response) => {
            if (response) {
                setIsDataAvailable(response.isDataAvailable);
                setClipDataArray(response.clipsWithFeatureClips);
            }
            setIsApiLoading(false);
        });
    };

    const scrollPageToTop = () => {
        if(window){
            window.scrollTo({
                top: 0,
            behavior: 'smooth'
              });
        }
    }

    const handleRefreshData = (
        allClipsDeleted = false,
        clipMetaId?: string,
    ) => {
        if (isSearchingPopclips && clipMetaId) {
            const updatedClipsArray = clipDataArray.filter(
                ({ metaIdentifier }) => metaIdentifier !== clipMetaId,
            );
            setClipDataArray(updatedClipsArray);
            setIsDataAvailable(updatedClipsArray.length > 0);

            return;
        }
        if (allClipsDeleted) {
            if (shouldShowPrevPageBtn) {
                delete videoLiveSellingStore.gridLastEvalKey[
                    videoLiveSellingStore.gridCurrentPage
                ];
                prevPageClicked();
            } else {
                setIsDataAvailable(false);
            }
        } else {
            setIsApiLoading(true);
            getPaginatedData(true).then((response) => {
                if (response) {
                    setIsDataAvailable(response.isDataAvailable);
                    setClipDataArray(response.clipsWithFeatureClips);
                }
                setIsApiLoading(false);
            });
        }
    };

    const onChangeAutocompleteValue = (value: string, pageNo: number = 1) => {
        if (value === '') {
            setIsApiLoading(false);
            setIsSearchingPopclips(false);
            handleRefreshData();
            setSearchCurrentPage(1);
            setSearchedClipsCount(0);
            return []; // don't want suggestions
        }

        if (pageNo === 1) {
            setSearchCurrentPage(1);
            setSearchedClipsCount(0);
        }

        (async () => {
            setIsApiLoading(true);
            setIsSearchingPopclips(true);
            setSearchKeyword(value);

            const data = await popclipsStore.getSearchedPopclips(value, pageNo);

            if (data && data?.items?.length > 0) {
                const clipsArrayItems = data?.items || [];
                await fetchLikesAndViewsPerClip(clipsArrayItems);
                setSearchedClipsCount(data?.count);
                setClipDataArray(clipsArrayItems);
                setIsDataAvailable(clipsArrayItems.length > 0);
            } else {
                setClipDataArray([]);
                setIsDataAvailable(false);
            }

            setIsApiLoading(false);
        })();

        return []; // don't want suggestions
    };

    const shouldShowPrevPageBtn = useMemo(() => {
        if (isSearchingPopclips) {
            return searchCurrentPage > 1;
        } else {
            const pageToGo = videoLiveSellingSnap.gridCurrentPage - 1;
            if (pageToGo == 1) return true;
    
            return videoLiveSellingSnap.gridLastEvalKey[pageToGo] ? true : false;
        }
    }, [
        searchCurrentPage,
        isSearchingPopclips,
        videoLiveSellingSnap.gridCurrentPage,
        videoLiveSellingSnap.gridLastEvalKey,
    ]);

    const showShowNextPageBtn = useMemo(() => {
        if (isSearchingPopclips) {
            return searchedClipsCount > searchCurrentPage * 14;
        } else {
            const pageToGo = videoLiveSellingSnap.gridCurrentPage + 1;
            return videoLiveSellingSnap.gridLastEvalKey[pageToGo] ? true : false;
        }
    }, [
        searchedClipsCount,
        searchCurrentPage,
        isSearchingPopclips,
        videoLiveSellingSnap.gridCurrentPage,
        videoLiveSellingSnap.gridLastEvalKey,
    ]);

    return (
        <PopClipsCard
            cardHeader={
                <PopclipsGridCardHeader
                    isApiLoading={isApiLoading}
                    isSearchingPopclips={isSearchingPopclips}
                    onHandleRefreshButton={() => handleRefreshData(false)}
                    onHandleClipSearchKeyword={onChangeAutocompleteValue}
                    onHandleButton={() => {
                        analyticsEventStore.popclipClickCreateButton();
                        videoLiveSellingStore.resetCreatePopClip();
                        navigate(popclipsRoutes.create);
                    }}
                    handleSettingButtonClick={() =>
                        navigate(popclipsRoutes.settings)
                    }
                />
            }
            cardFooter={
                <PopclipsGridCardFooter
                    showPagination={isDataAvailable}
                    showPrevPageBtn={shouldShowPrevPageBtn}
                    prevPageClicked={prevPageClicked}
                    showNextPageBtn={showShowNextPageBtn}
                    nextPageClicked={nextPageClicked}
                />
            }
        >
            {isApiLoading ? (
                <Box paddingBlock="800">
                    <BlockStack align="center" inlineAlign="center" gap="100">
                        <Spinner size="large" />
                        <Text as="p">
                            Hang tight while we are loading your Popclips
                        </Text>
                    </BlockStack>
                </Box>
            ) : isDataAvailable ? (
                <Box paddingInline="300">
                    <BlockStack gap="400">
                        {shouldShowInfoBanner && (
                            <Banner
                                title="You can continue adding popclips while we process current uploads."
                                tone="info"
                                onDismiss={() => {
                                    setShouldShowInfoBanner(false);
                                }}
                            />
                        )}
                        {isSearchingPopclips && (
                            <Box paddingBlock="100" paddingInlineStart="200">
                                <Text as="p" tone="subdued" variant="bodyLg">
                                    Showing {searchedClipsCount}{' '}
                                    {searchedClipsCount > 1
                                        ? 'results'
                                        : 'result'}{' '}
                                    for <b>"{searchKeyword}"</b>
                                </Text>
                            </Box>
                        )}
                        <Box paddingInlineStart="200">
                            <ClipListingCard
                                clipDataArray={clipDataArray}
                                refreshData={handleRefreshData}
                            />
                        </Box>
                    </BlockStack>
                </Box>
            ) : (
                <>
                    {isSearchingPopclips ? (
                        <Box paddingBlock="800">
                            <BlockStack
                                align="center"
                                inlineAlign="center"
                                gap="200"
                            >
                                <Text as="p" fontWeight="bold">
                                    No results found for "{searchKeyword}"
                                </Text>
                                <Text as="p" tone="subdued" variant="bodySm">
                                    Please try modifying your search keyword
                                </Text>
                            </BlockStack>
                        </Box>
                    ) : (
                        <PopClipsGridEmptyState />
                    )}
                </>
            )}
        </PopClipsCard>
    );
};

const PopclipsGridCardHeader = ({
    onHandleClipSearchKeyword,
    onHandleRefreshButton,
    onHandleButton,
    isSearchingPopclips,
    isApiLoading,
    handleSettingButtonClick,
}: PopclipsGridCardHeaderProps) => {
    const gatingData = shopStore.featureGatingData?.popclips;

    return (
        <Box padding="500">
            <InlineStack gap="200" align="end" blockAlign="center">
                <div className="__flex-center-align">
                    <AutocompleteInput
                        placeholderStr="Search by Clip Title"
                        onChangeValue={onHandleClipSearchKeyword}
                        debounceTimeout={1500}
                        hideEmptyState={true}
                        shouldDisabled={isApiLoading}
                    />
                    <VfsButton
                        padding='6px'
                        variant="secondary"
                        disabled={isSearchingPopclips}
                        onClick={onHandleRefreshButton}
                    >
                        <VfsImageComponent
                            iconName="RefreshIcon"
                            color={isSearchingPopclips ? "#3e3e3e" :"#1F6DFF"}
                        />
                    </VfsButton>
                    <VfsButton
                        padding='8px 12px'
                        onClick={onHandleButton}
                        disabled={
                            gatingData?.maxViews != '-1' &&
                            gatingData?.limitAccess
                        }
                    >
                        <div
                            className="__flex-center-align"
                            style={{ gap: '4px'}}>
                            <VfsImageComponent iconName="PlusIcon" size='18px' />
                            <span>Create Popclip</span>
                        </div>
                    </VfsButton>
                    <VfsButton 
                        padding='6px'
                        onClick={handleSettingButtonClick}>
                        <VfsImageComponent iconName="SettingIcon" />
                    </VfsButton>
                </div>
            </InlineStack>
        </Box>
    );
};

const PopclipsGridCardFooter = ({
    showPagination,
    showPrevPageBtn,
    prevPageClicked,
    showNextPageBtn,
    nextPageClicked,
}: PopclipsGridCardFooterProps) => {
    return (
        <Box background="bg-surface-active" padding="300" minHeight="44px">
            {showPagination ? (
                <InlineStack gap="300" align="end" blockAlign="center">
                    {showPrevPageBtn ? (
                        <Button
                            icon={ChevronLeftIcon}
                            accessibilityLabel="Previous Page"
                            onClick={prevPageClicked}
                        />
                    ) : (
                        <Box>
                            <Icon source={ChevronLeftIcon} tone="subdued" />
                        </Box>
                    )}

                    {showNextPageBtn ? (
                        <Button
                            icon={ChevronRightIcon}
                            accessibilityLabel="Next Page"
                            onClick={nextPageClicked}
                        />
                    ) : (
                        <Box>
                            <Icon source={ChevronRightIcon} tone="subdued" />
                        </Box>
                    )}
                </InlineStack>
            ) : null}
        </Box>
    );
};

export default PopClipsGridListing;
