import { BlockStack, Box, Button, Divider, Icon, InlineStack, Text } from "@shopify/polaris";
import { CheckIcon } from "@shopify/polaris-icons";
import { supportsGlobalCurrencies } from "../../../assets/images";

const POPCLIPS_PLAN_HIGHLIGHTS = {
    free: {
        highlights: [
            'Publish videos anywhere on site (tag up to 5 products per video)',
            'Add a video carousel anywhere',
            'Seamless In-video checkout',
            'Social-like swipe up video feed',
            'Automatic video placements on product pages',
            'Unlimited video uploads',
            'Unlimited video storage',
            'Share up to 5 videos anywhere with your customers',
            'Feature any video on the Homepage',
        ],
    },
    basic: {
        highlights: [
            'Import your videos directly from Instagram',
            'Pick and choose videos to create custom video carousels',
            'Publish custom video carousels anywhere on your website',
            'Manage your custom video carousels',
            "Display video 'view count' directly on video",
            'Control view counter & carousel settings',
            'Share unlimited videos with your customers',
            'Customize the placement of video on your product description pages (coming soon!)',
            'Customize the featured video on Homepage (Coming soon!)',
        ],
    },
    premium: {
        highlights: [
            'Remove the “Powered by Videeo” branding from your Popclips',
            'Create stunning, collection-based custom video carousels to showcase tailored content',
            "Enable visitors to 'like' Popclips on your website & mobile app",
            'Display “likes count” directly on videos to drive engagement and social proof',
            'Control like counter & custom carousels',
        ],
    },
};

type PopclipsPlanAddonCardProps = {
    cardType: 'free' | 'basic' | 'premium';
    addonData: any;
    addonValue: string;
    isSelected: boolean;
    isDisabled: boolean;
    onSelectPlan: () => void;
};

export default function PopclipsPlanAddonCard({
    cardType,
    addonData,
    addonValue,
    isSelected,
    onSelectPlan,
    isDisabled,
}: PopclipsPlanAddonCardProps) {
    const popclipsData = POPCLIPS_PLAN_HIGHLIGHTS[cardType];
    if (!popclipsData) return null;
    const { highlights } = popclipsData;
    const value = addonValue ? addonValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';

    return (
        <Box
            borderRadius="400"
            borderWidth="025"
            padding="400"
            borderColor="border-disabled"
            shadow="200"
        >
            <BlockStack gap="400">
                <InlineStack align="space-between" wrap={false}>
                    <BlockStack gap="100">
                        <Text as="p" fontWeight="bold" variant="headingLg">
                            {addonData.name == 'Free 2025' ? 'Free' : addonData.name}
                        </Text>
                        <Text as="p" fontWeight="medium" variant="bodySm">
                            {value} Views / Month
                        </Text>
                    </BlockStack>
                    {Math.round(parseFloat(addonData.price[0].amount)) === 0 ? (
                        <Text as="p" fontWeight="bold" variant="heading2xl">
                            Free
                        </Text>
                    ) : (
                        <InlineStack blockAlign="center" gap="200">
                            <Text as="p" fontWeight="bold" variant="heading2xl">
                                {addonData.price[0].amount}
                            </Text>
                            <BlockStack>
                                <Text as="p" variant="bodyXs">
                                    {addonData.price[0].currencyCode}
                                </Text>
                                <Text as="p" variant="bodyXs">
                                    /mo
                                </Text>
                            </BlockStack>
                        </InlineStack>
                    )}
                </InlineStack>
                <Divider />
                <img
                    src={supportsGlobalCurrencies}
                    alt="supportsGlobalCurrencies"
                    style={{ width: '100%' }}
                />
                {cardType === 'free' && (
                    <Text as="p" fontWeight="bold" variant="bodyMd">
                        Highlights:
                    </Text>
                )}
                {cardType === 'basic' && (
                    <Text as="p" fontWeight="bold" variant="bodyMd">
                        Everything in{' '}
                        <Text as="span" tone="success">
                            Free
                        </Text>
                        , plus:
                    </Text>
                )}
                {cardType === 'premium' && (
                    <Text as="p" fontWeight="bold" variant="bodyMd">
                        Everything in{' '}
                        <Text as="span" tone="success">
                            Basic
                        </Text>
                        , plus:
                    </Text>
                )}
                <BlockStack gap="200">
                    {highlights.map((highlight: string) => (
                        <InlineStack
                            key={highlight}
                            gap="200"
                            wrap={false}
                            blockAlign="start"
                        >
                            <div style={{ width: '20px' }}>
                                <Icon source={CheckIcon} tone="emphasis" />
                            </div>
                            <Text variant="bodySm" as="p">{highlight}</Text>
                        </InlineStack>
                    ))}
                </BlockStack>
                <Divider />
                <InlineStack align="end" gap="200">
                    {isSelected ? (
                        <Button
                            variant="primary"
                            tone="success"
                            onClick={onSelectPlan}
                            disabled={isDisabled}
                        >
                            Selected
                        </Button>
                    ) : (
                        <div className={`select_planbtn_${addonData.skuCode}`}>
                            <Button
                                variant="primary"
                                onClick={onSelectPlan}
                                disabled={isDisabled}
                            >
                                Select Plan
                            </Button>
                        </div>
                    )}
                </InlineStack>
            </BlockStack>
        </Box>
    );
}
