import {
    ButtonGroup,
    Button,
    SkeletonBodyText,
    Box,
    BlockStack,
    Text,
    InlineStack,
    Icon,
} from '@shopify/polaris';
import { useState, useCallback, useEffect } from 'react';
import VfsModal from '../../../v2/Components/VfsModal';
import { DraggableResizableBox } from '@/components/common/v1';
import { popclipsStore, shopStore } from '@/stores';
import { PopclipsPositionpApiResponseTypes } from '@/types/Popclips.types';
import { POPCLIPS } from '@/constants/popclipsFeature.constants';
import { CheckIcon } from '@shopify/polaris-icons';
import { useNavigate } from 'react-router-dom';
import upgradePlan from '../../../assets/images/upgradePlan.svg';
import { vfsRoutes } from '@/constants/routes.constants';

const MINI_PLAYER = 'MINI_PLAYER';
const WEB_LEFT_BOTTOM_POSITION = { x: 0, y: 57 };
const MOBILE_LEFT_BOTTOM_POSITION = { x: 0, y: 75 };
const planBenifitsList = [
    'Import videos from Instagram and TikTok for effortless social integration',
    'Increase monthly view limits to reach more customers',
    'Show video views and likes to boost engagement',
    "Create and manage customizable video carousels",
    'Customize view and like counter settings',
];

interface PopclipsMiniplayerPositioningPropsTypes {
    showModal: boolean;
    type?: string;
    onCloseModal: () => void;
}

const PopclipsMiniplayerPositioning = ({
    showModal,
    type,
    onCloseModal,
}: PopclipsMiniplayerPositioningPropsTypes) => {
    const navigate = useNavigate();
    const [isBottomLeftButtonActive, setIsBottomLeftButtonActive] =
        useState(true);
    const [playerDefaultPosition, setPlayerDefaultPosition] = useState({
        featured_popclip_position_mobile_web: null,
        featured_popclip_position_mobile_app: null,
        miniplayer_position_mobile_web: null,
        miniplayer_position_mobile_app: null,
    });
    const [playerPosition, setPlayerPosition] = useState<{
        x: number;
        y: number;
    }>(MOBILE_LEFT_BOTTOM_POSITION);
    const [newPositionValues, setNewPositionValues] = useState<{
        x: number;
        y: number;
    }>(MOBILE_LEFT_BOTTOM_POSITION);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isMobileTab, setIsMobileTab] = useState(true);
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
    const [showPlanUpgrade, setShowPlanUpgrade] = useState(false);

    const handleFirstButtonClick = useCallback(() => {
        if (isBottomLeftButtonActive) return;
        setIsBottomLeftButtonActive(true);
    }, [isBottomLeftButtonActive]);

    const handleSecondButtonClick = useCallback(() => {
        if (showPlanUpgrade) {
            setShowSubscriptionModal(true);
            return;
        }
        if (!isBottomLeftButtonActive) return;

        setIsBottomLeftButtonActive(false);
    }, [isBottomLeftButtonActive]);

    const closeSubscriptionModal = () => {
        setShowSubscriptionModal(false);
        onCloseModal();
    };
    const handleUpgradeNow = () => {
        onCloseModal();
        navigate(vfsRoutes.managePlans);
    };

    const handleTabChange = (value: boolean) => {
        setIsMobileTab(value);
        setIsBottomLeftButtonActive(true);
    };

    const fetchPlayerPositionValues = async () => {
        setIsLoading(true);

        try {
            const resp: PopclipsPositionpApiResponseTypes =
                await popclipsStore.getPlayerPostion();
            setPlayerDefaultPosition(resp);

            const position =
                type === MINI_PLAYER
                    ? isMobileTab
                        ? resp?.miniplayer_position_mobile_app
                        : resp?.miniplayer_position_mobile_web
                    : isMobileTab
                      ? resp?.featured_popclip_position_mobile_web
                      : resp?.featured_popclip_position_mobile_app;

            if (position && !isBottomLeftButtonActive) {
                const x = Number(position['x-axis']);
                const y = Number(position['y-axis']);

                setPlayerPosition({ x, y });
            } else {
                setPlayerPosition(
                    isMobileTab
                        ? MOBILE_LEFT_BOTTOM_POSITION
                        : WEB_LEFT_BOTTOM_POSITION,
                );
            }
        } catch (error) {
            console.error('Error fetching player position:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const savePositionPoints = async () => {
        if ((!isBottomLeftButtonActive && showPlanUpgrade) || isSaving) return;
        setIsSaving(true);
        const feature =
            type == MINI_PLAYER
                ? isMobileTab
                    ? 'miniplayerPositionMobileApp'
                    : 'miniplayerPositionMobileWeb'
                : isMobileTab
                  ? 'featuredPopclipPositionMobileApp'
                  : 'featuredPopclipPositionMobileWeb';
        const resp = await popclipsStore.storePlayerPosition(feature, {
            'x-axis': newPositionValues.x,
            'y-axis': newPositionValues.y,
        });
        if (resp) {
            shopify.toast.show(
                type == MINI_PLAYER
                    ? 'Popclips miniplayer placement is saved'
                    : 'Featured popclips placement is saved',
                {
                    isError: false,
                    duration: 5000,
                },
            );
            onCloseModal();
        }
        setIsSaving(false);
    };

    useEffect(() => {
        const position =
            type === MINI_PLAYER
                ? isMobileTab
                    ? playerDefaultPosition?.miniplayer_position_mobile_app
                    : playerDefaultPosition?.miniplayer_position_mobile_web
                : isMobileTab
                  ? playerDefaultPosition?.featured_popclip_position_mobile_app
                  : playerDefaultPosition?.featured_popclip_position_mobile_web;

        if (position && !isBottomLeftButtonActive) {
            const x = Number(position['x-axis']);
            const y = Number(position['y-axis']);

            setPlayerPosition({ x, y });
        } else {
            setPlayerPosition(
                isMobileTab
                    ? MOBILE_LEFT_BOTTOM_POSITION
                    : WEB_LEFT_BOTTOM_POSITION,
            );
        }
    }, [isMobileTab, isBottomLeftButtonActive]);

    useEffect(() => {
        const addonPrices = shopStore.getActivePlanAddonPrices(POPCLIPS, true);
        const maxPrice = Math.max(...addonPrices);
        setShowPlanUpgrade(maxPrice < 29.99);
        fetchPlayerPositionValues();
    }, []);

    return (
        <>
            {showModal && !showSubscriptionModal && (
                <VfsModal
                    title=""
                    modalWidth="42rem"
                    closeable={false}
                    crossIcon={true}
                    onCloseModal={onCloseModal}
                    primaryAction={() => savePositionPoints()}
                    primaryActionText={showPlanUpgrade && !isBottomLeftButtonActive ? '' : isSaving ? 'Saving...' : 'Save' }
                    primaryActionTone="primary"
                    secondaryActionText={showPlanUpgrade && !isBottomLeftButtonActive ? '' : 'Cancel'}
                    secondaryAction={onCloseModal}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '12px',
                        }}
                    >
                        <p
                            style={{
                                fontWeight: 650,
                                fontSize: '20px',
                                lineHeight: '24px',
                            }}
                        >
                            {type == MINI_PLAYER
                                ? 'Popclip Miniplayer Position on Product Description Page'
                                : 'Featured Popclip Position'}
                        </p>
                        <p
                            style={{
                                fontWeight: 450,
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: '#616161',
                                textAlign: 'center',
                            }}
                        >
                            {type == MINI_PLAYER
                                ? 'Keep the mini-player in the bottom-left corner by default or customize its placement on your product description pages for the best experience. Drag and position it anywhere to match your site’s layout.'
                                : 'Keep the mini-player in the bottom-left corner by default or customize its placement on your homepage for the best experience. Drag and position it anywhere to match your site’s layout.'}
                        </p>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div
                                style={{
                                    width: '50%',
                                    borderBottom: '2px solid',
                                    borderColor: isMobileTab
                                        ? '#1F6DFF'
                                        : '#D7DBDF',
                                    padding: '12px 24px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleTabChange(true)}
                            >
                                Mobile
                            </div>
                            <div
                                style={{
                                    width: '50%',
                                    borderBottom: '2px solid',
                                    borderColor: isMobileTab
                                        ? '#D7DBDF'
                                        : '#1F6DFF',
                                    padding: '12px 24px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleTabChange(false)}
                            >
                                Desktop
                            </div>
                        </div>
                        <div style={{ position: 'relative' }}>
                            <ButtonGroup variant="segmented">
                                <Button
                                    pressed={isBottomLeftButtonActive}
                                    onClick={handleFirstButtonClick}
                                >
                                    Bottom Left Corner
                                </Button>
                                <Button
                                    pressed={!isBottomLeftButtonActive}
                                    onClick={handleSecondButtonClick}
                                >
                                    Custom Placement
                                </Button>
                            </ButtonGroup>
                            {showPlanUpgrade && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        right: 0,
                                        top: 0,
                                        background: 'rgba(255, 255, 255, 0.80)',

                                        backdropFilter: 'blur(0.5px)',
                                        width: '50%',
                                        textAlign: 'center',
                                        borderRadius: '0px 8px 8px 0px',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                    }}
                                    onClick={() => setShowSubscriptionModal(true)}
                                >
                                    <svg
                                        width="20"
                                        height="21"
                                        viewBox="0 0 20 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M6.25 6.93213V7.27664C4.95608 7.60967 4 8.78425 4 10.1821V14.1821C4 15.839 5.34315 17.1821 7 17.1821H13C14.6569 17.1821 16 15.839 16 14.1821V10.1821C16 8.78425 15.0439 7.60967 13.75 7.27664V6.93213C13.75 4.86106 12.0711 3.18213 10 3.18213C7.92893 3.18213 6.25 4.86106 6.25 6.93213ZM10 4.68213C8.75736 4.68213 7.75 5.68949 7.75 6.93213V7.18213H12.25V6.93213C12.25 5.68949 11.2426 4.68213 10 4.68213ZM11.5 11.9321C11.5 12.4873 11.1984 12.9721 10.75 13.2315V13.6821C10.75 14.0963 10.4142 14.4321 10 14.4321C9.58579 14.4321 9.25 14.0963 9.25 13.6821V13.2315C8.80165 12.9721 8.5 12.4873 8.5 11.9321C8.5 11.1037 9.17157 10.4321 10 10.4321C10.8284 10.4321 11.5 11.1037 11.5 11.9321Z"
                                            fill="#5B5B5B"
                                        />
                                    </svg>
                                    <span style={{ fontSize: '12px' }}>
                                        Unlock
                                    </span>
                                </div>
                            )}
                        </div>

                        <div
                            style={{
                                width: isMobileTab ? 'auto' : '100%',
                                height: '100%',
                                marginRight: isMobileTab ? '30%' : '',
                            }}
                        >
                            {isLoading ? (
                                <SkeletonBodyText />
                            ) : (
                                <DraggableResizableBox
                                    isMovable={
                                        !isBottomLeftButtonActive &&
                                        !showPlanUpgrade
                                    }
                                    isFeaturedPopclips={type != MINI_PLAYER}
                                    coordinatesPoints={playerPosition}
                                    isMobileView={isMobileTab}
                                    handlePositionChanged={(value) =>
                                        setNewPositionValues(value)
                                    }
                                />
                            )}
                        </div>
                    </div>
                </VfsModal>
            )}
            {showSubscriptionModal && (
                <VfsModal
                    title="Upgrade To Access Feature"
                    primaryActionText={'Upgrade Now'}
                    secondaryActionText="Cancel"
                    primaryAction={handleUpgradeNow}
                    primaryActionTone="success"
                    secondaryAction={() => closeSubscriptionModal()}
                    onCloseModal={() => closeSubscriptionModal()}
                    crossIcon={false}
                    closeable={false}
                    modalWidth="33rem"
                >
                    <BlockStack gap="400">
                        <Box padding="200">
                            <BlockStack inlineAlign="center" gap="200">
                                <img
                                    alt=""
                                    width="90px"
                                    height="120px"
                                    style={{
                                        objectFit: 'cover',
                                        objectPosition: 'center',
                                    }}
                                    src={upgradePlan}
                                />
                                <Text
                                    variant="headingLg"
                                    as="h5"
                                    alignment="center"
                                >
                                    Uh-oh! You currently don’t have access.
                                    Unlock More Power with our plans!
                                </Text>
                                <Text
                                    as="p"
                                    alignment="center"
                                    tone="subdued"
                                    variant="bodyLg"
                                >
                                    Upgrade to unlock the custom placement and
                                    other powerful tools that drive sales and
                                    maximize your store's impact.
                                </Text>
                            </BlockStack>
                        </Box>
                        <Box
                            borderRadius="200"
                            background="bg-fill-info-secondary"
                            padding="200"
                        >
                            <BlockStack gap="200">
                                <Text as="h5" variant="headingMd">
                                    You’re missing out on:
                                </Text>
                                {planBenifitsList.map((item, index) => (
                                    <InlineStack
                                        key={index}
                                        gap="200"
                                        wrap={false}
                                    >
                                        <Box>
                                            <Icon
                                                source={CheckIcon}
                                                tone="info"
                                            />
                                        </Box>
                                        <Text
                                            as="p"
                                            variant="bodyLg"
                                            tone="subdued"
                                        >
                                            {item}
                                        </Text>
                                    </InlineStack>
                                ))}
                            </BlockStack>
                        </Box>
                    </BlockStack>
                </VfsModal>
            )}
        </>
    );
};

export default PopclipsMiniplayerPositioning;
