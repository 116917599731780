import { BlockStack, Box } from '@shopify/polaris';
import { useSnapshot } from 'valtio';
import videoLiveSellingStore from '@/stores/videoLiveSellingStore';
import UploadVideoLogo from '/assets/images/uploadVideoLogo.png';
import ClipExcludeCheckBox from './ClipExcludeCheckBox';
import { VfsButton } from '@/components/common/v1';
import { useRef } from 'react';

const VideoPreview = ({ isEditMode }:{isEditMode: boolean| undefined}) => {
    const { videoUrl } = useSnapshot(videoLiveSellingStore);
    const popclipPreviewRef = useRef(null);

    const handleOnLoadData = () => {
        captureFrameForThumbnail();
    }

    const captureFrameForThumbnail = () => {
        const video = popclipPreviewRef.current;
        
        if (!video || isEditMode) return;

        const hiddenCanvas = document.createElement('canvas');
        hiddenCanvas.width = video.videoWidth;
        hiddenCanvas.height = video.videoHeight;
        const context = hiddenCanvas.getContext('2d');
        context.drawImage(video, 0, 0, hiddenCanvas.width, hiddenCanvas.height);
        
        videoLiveSellingStore.videoThumbnailImage = hiddenCanvas.toDataURL('image/jpeg');
        
        hiddenCanvas.toBlob((blob) => {
            if (!blob) {
                console.error('Blob creation failed');
                return;
            }
            const file = new File([blob], "custom_thumbnail_image", {
                type: 'image/jpeg',
            });
            const formData = new FormData();
            const fileName = "custom_image.jpg";
            const sanitizedName = `${fileName.slice(0, -3).replace(/[^a-zA-Z0-9]/g, '')}.${fileName.slice(-3)}`;
            formData.append('file', file, sanitizedName);

            videoLiveSellingStore.videoThumbnailFormData = formData;
        }, 'image/jpeg');
    };

    return (
        <>
            {videoUrl && videoUrl.trim() != '' ? (
                <BlockStack gap="400">
                    <video
                        muted
                        autoPlay
                        loop
                        width="368"
                        height="716"
                        controls
                        crossOrigin="anonymous"
                        ref={popclipPreviewRef}
                        style={{
                            borderRadius: '8px',
                            objectFit: 'cover',
                        }}
                        onLoadedData={handleOnLoadData}
                    >
                        <source src={videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    {!isEditMode && (<div 
                        className='__flex-center-align'
                        style={{ justifyContent: 'space-between', background: '#f5f5f5', padding:'13px 14px', borderRadius: '8px'}}
                    >
                        <div>Set current frame as thumbnail</div>
                        <VfsButton onClick={captureFrameForThumbnail} variant='secondary'>
                            Set Thumbnail
                        </VfsButton>
                    </div>)}
                    <ClipExcludeCheckBox />
                </BlockStack>
            ) : (
                <Box
                    background="bg-surface-active"
                    borderRadius="150"
                    outlineColor="input-border"
                    outlineStyle="dashed"
                    outlineWidth="025"
                >
                    <div
                        style={{
                            display: 'flex',
                            width: '368px',
                            height: '716px',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            alt="UploadVideoLogo"
                            width="180px"
                            height="180px"
                            src={UploadVideoLogo}
                        />
                    </div>
                </Box>
            )}
        </>
    );
};

export default VideoPreview;
