import { proxy } from 'valtio';
import shopStore from './shopStore';
import { ListMediaByIdResponse } from '@/types/ListMediaByIdResponse';
import { PopclipsPositionpApiResponseTypes } from '@/types/Popclips.types';

export type PopclipsCarouselItem = {
    updated_at: number;
    carousel_id: string;
    created_at: number;
    timestamp: number;
    customer_id: string;
    carousel_contents: string[];
    type_with_prefix: string;
    carousel_title: string;
    carousel_description: string;
    type: string;
    is_published: boolean;
    carousel_type: string;
    carousel_type_ids: string[];
};

interface PopclipsStoreState {
    popclipsCarousels: PopclipsCarouselItem[];
    carouselToEdit: PopclipsCarouselItem | null;
    carouselCurrentPage: number;
    carouselLastEvalKey: {
        [key: string]:
            | {
                  id: string;
                  fileTypeAndIdKey: string;
                  metaIdentifier: string;
              }
            | undefined;
    };
    clipsForEditCarousel: ListMediaByIdResponse[];
    popclipsToShare: ListMediaByIdResponse | null;
    showMiniplayerPositioningModal: boolean;
    getPopclipsCarousels: () => Promise<void>;
    saveCustomCarousel: (carouselData: any, isEdit: boolean) => Promise<{carousel_id: string}>;
    handleEditCarousel: (
        carousel: PopclipsCarouselItem,
        MGAuthFetch: any,
    ) => Promise<void>;
    getNewCarouselID: (customerId: string) => Promise<{carousel_id: string}>;
    getSearchedPopclips: (searchTerm: string, pageNo?: number) => Promise<any>;
    getSharedPopclipsMetadata: () => Promise<ShareGatingResponseTypes>;
    generateShareLink: (metaIdentifier: string) => Promise<boolean>;
    getPlayerPostion: () => Promise<PopclipsPositionpApiResponseTypes>
    storePlayerPosition: (feature: string, position: {"x-axis": number, "y-axis":number}) => Promise<boolean>;
    resetPopclipsStore: () => void;
}

interface ShareGatingResponseTypes {
    max_share: string | number;
    total_share: number;
    limit_access: boolean;
    start_date_of_share_count: string;
    type_of_date: 'subscription_created_at' | string;
    clips_shared: string[];
  };

const popclipsStore: PopclipsStoreState = proxy<PopclipsStoreState>({
    carouselCurrentPage: 1,
    carouselLastEvalKey: {},
    popclipsCarousels: [],
    clipsForEditCarousel: [],
    carouselToEdit: undefined as unknown as PopclipsCarouselItem,
    popclipsToShare: null,
    showMiniplayerPositioningModal: false,
    getPopclipsCarousels: async () => {
        if (!shopStore.popshopliveAccountCreated) return;

        try {
            const response = await fetch(
                `/api/popclips/carousels?customerId=${shopStore.shopifyDomain}`,
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                },
            );

            if (!response.ok)
                throw new Error('Error fetching popclips carousels');

            const result = await response.json();
            popclipsStore.popclipsCarousels = result.data;
        } catch (error) {
            console.error('Error fetching popclips carousels', error);
        }
    },
    handleEditCarousel: async (
        carousel: PopclipsCarouselItem,
        MGAuthFetch: any,
    ) => {
        try {
            const { data } = await MGAuthFetch('/listMediabyTypeAndMetaId', {
                method: 'POST',
                body: JSON.stringify({
                    metaIds: carousel.carousel_type_ids,
                    sellerId: shopStore.popshopliveSellerId,
                }),
            });

            popclipsStore.carouselToEdit = carousel;
            const clipsArrayItems = data || [];
            await fetchLikesAndViewsPerClip(clipsArrayItems);
            const reorderedResponseClipsArrayItems = carousel.carousel_type_ids.map((id) =>
                data.find((item) => item.metaIdentifier === id)
              );
            popclipsStore.clipsForEditCarousel = reorderedResponseClipsArrayItems;            
        } catch (error) {
            console.error('Error fetching carousel clips:', error);
        }
    },
    saveCustomCarousel: async (carouselData: any, isEdit = false) => {
        try {
            const response = await fetch('/api/popclips/carousels', {
                method: isEdit ? 'PUT' : 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(carouselData),
            });

            const result = await response.json();
            if (!response.ok) throw new Error(result.message);

            return result.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    getNewCarouselID: async (customerId) => {
        try {
            const response = await fetch(`/api/popclips/carousels?customerId=${customerId}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            const result = await response.json();
            if (!response.ok) throw new Error(result.message);

            return result.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    getSearchedPopclips: async (searchTerm: string, pageNo = 1) => {
        try {
            if (!shopStore.shopifyDomain) return;

            const searchUrl = `${shopStore.popclipsServiceApi}/search?shopifyDomain=${shopStore.shopifyDomain}&searchValue=${searchTerm}&page=${pageNo}&size=14`;
            const response = await fetch(searchUrl, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) throw new Error('Error fetching popclips');

            const result = await response.json();
            return result.data;
        } catch (error) {
            console.error('Error fetching popclips', error);
            return;
        }
    },
    getSharedPopclipsMetadata: async () => {
        try {
            const response = await fetch(`/api/shopify/shop/popclips/share-gating`,
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                },
            );

            if (!response.ok) throw new Error('Error fetching shared popclips metadata');

            const result = await response.json();
            return result.data;
        }
        catch (error) {
            console.error('Error fetching shared popclips metadata', error);
            return;
        }
    },
    generateShareLink: async (metaIdentifier: string) => {
        try {
            const response = await fetch('/api/shopify/shop/popclips/share-gating', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    metaIdentifier: metaIdentifier,
                }),
            });
            if (!response.ok) throw new Error('Error fetching shared popclips metadata');

            return true;
        }
        catch (error) {
            console.error('Error generating share link', error);
            return false;
        }
    },
    getPlayerPostion: async() =>{
        try {
            const response = await fetch(`/api/shopify/shop/${shopStore.shopifyDomain}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            })
            const {data} = await response.json();
            return data
        } catch (error) {
            return 
        }
    },
    storePlayerPosition: async (feature: string, payload: {x: number, y:number}) => {
        try {
            const response = await fetch('/api/shopify/shop-info', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    key: feature,
                    value: payload
                    }),
            })
            if (!response.ok) throw new Error('Error fetching shared popclips metadata');

            return true;
        } catch (error) {
            return false;
        }
    },
    resetPopclipsStore: () => {
        popclipsStore.carouselCurrentPage = 1,
        popclipsStore.carouselLastEvalKey = {},
        popclipsStore.popclipsCarousels = [],
        popclipsStore.clipsForEditCarousel = [],
        popclipsStore.popclipsToShare = null
    }
});

export const fetchLikesAndViewsPerClip = async (filterClipArray: any) => {
    const clipIDs = filterClipArray.map(
        ({ id, metaIdentifier }) => id + '_' + metaIdentifier,
    );

    if (clipIDs?.length < 1) {
        return;
    }

    const [views_response, likes_response] = await Promise.all([
        fetch('/api/popclips/views/fetch', {
            method: 'POST',
            body: JSON.stringify({
                customer_id: shopStore.shopifyDomain,
                clip_ids: clipIDs,
            }),
        }),
        fetch('/api/popclips/likes/fetch', {
            method: 'POST',
            body: JSON.stringify({
                customer_id: shopStore.shopifyDomain,
                clip_ids: clipIDs,
                user_id: shopStore.shopifyDomain,
            }),
        }),
    ]);

    if (views_response.status === 200) {
        const { data } = await views_response.json();
        updateFilterClipArrayWithViews(filterClipArray, data);
    }

    if (likes_response.status === 200) {
        const { data } = await likes_response.json();
        updateFilterClipArrayWithLikes(filterClipArray, data);
    }
};

const updateFilterClipArrayWithLikes = (filterClipArray: any, data: any) => {
    if (data?.length > 0) {
        data.forEach((item) => {
            const clipsFound = filterClipArray.find(
                ({ metaIdentifier, id }) =>
                    `${id}_${metaIdentifier}` === item.clip_id,
            );

            if (clipsFound) {
                clipsFound.showLikes = item?.likes || item?.likes === 0;
                clipsFound.clipLikes = item?.likes;
            }
        });
    }
};

const updateFilterClipArrayWithViews = (filterClipArray: any, data: any) => {
    if (data?.length > 0) {
        data.forEach((item) => {
            const clipsFound = filterClipArray.find(
                ({ metaIdentifier, id }) =>
                    `${id}_${metaIdentifier}` === item.clip_id,
            );
            if (clipsFound) {
                clipsFound.showViews =
                    item?.clip_total_views || item?.clip_total_views === 0;
                clipsFound.clipViews = item?.clip_total_views;
            }
        });
    }
};

export default popclipsStore;
