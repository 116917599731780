import {
    BlockStack,
    Box,
    Button,
    Card,
    InlineGrid,
    List,
    SkeletonBodyText,
    Text,
} from '@shopify/polaris';
import { useEffect, useState } from 'react';
import {
    CategoryTypes,
    SectionTypes,
    ArticleTypes,
} from '../../types/HelpCenter';
import { isQaEnv } from '@/utils/helpers';

const HelpCenterComponent = () => {
    const helpCenterApiURL =
        'https://videeosupport.zendesk.com/api/v2/help_center';
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [categories, setCategories] = useState<Array<CategoryTypes>>([]);
    const [sections, setSections] = useState<Array<SectionTypes>>([]);
    const [articles, setArticles] = useState<Array<ArticleTypes>>([]);
    const [selectedCategory, setSelectedCategory] =
        useState<CategoryTypes | null>(null);
    const [selectedSections, setSelectedSections] =
        useState<SectionTypes | null>(null);
    const [zendeskToken, setZendeskToken] = useState<string | null>(null);

    const HEADER = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${zendeskToken}`,
        },
    };
    const PER_PAGE_ITEMS = 1000;

    const fetchCategory = async () => {
        setIsLoading(true);
        try {
            const URL = `${helpCenterApiURL}/categories?per_page=${PER_PAGE_ITEMS}`;
            const resp = await fetch(URL, HEADER);
            const { categories } = await resp.json();
            if (categories?.[0]) {
                setSelectedCategory(categories[0]);
                setCategories(categories);
            }
        } catch (error) {
            console.log('Failed to fetch categories', error);
        }
        setIsLoading(false);
    };
    const fetchSections = async () => {
        if (selectedCategory?.id) {
            try {
                const URL = `${helpCenterApiURL}/sections?categories=${selectedCategory?.id}&per_page=${PER_PAGE_ITEMS}`;
                const resp = await fetch(URL, HEADER);
                const { sections } = await resp.json();
                setSections(sections);
                if (selectedSections == null && sections?.[0]) {
                    setSelectedSections(sections[0]);
                }
            } catch (error) {
                console.log('Failed to fetch sections', error);
            }
        }
    };
    const fetchArticles = async () => {
        if (selectedSections?.id) {
            try {
                const URL = `${helpCenterApiURL}/articles?sections=${selectedSections?.id}&per_page=${PER_PAGE_ITEMS}`;
                const resp = await fetch(URL, HEADER);
                const { articles } = await resp.json();
                setArticles(articles);
            } catch (error) {
                console.log('Failed to fetch articles', error);
            }
        }
    };

    const callZendeskTokenApis = async () => {
        setIsLoading(true);
        if (!isQaEnv) {
            const result = await fetch('/api/shopify/shop/zendesk/token');
            if (result.ok) {
                const response = await result.json();
                const jwt = response?.data?.zendeskToken?.jwt;
                if (jwt) {
                    setZendeskToken(jwt);
                    fetchCategory();
                }
            }
        } else {
            setZendeskToken(
                'cHJhamF5LmJhc3VAY29tbWVudHNvbGQuY29tL3Rva2VuOmdKeXlub1hHU3FaZ3dCZVZpMnBWaWhUblN6aXpFdERRNHY0SFU2M0g=',
            );
            fetchCategory();
        }
        setIsLoading(false);
    };

    useEffect(() => {
        callZendeskTokenApis();
    }, []);

    useEffect(() => {
        fetchSections();
    }, [selectedCategory]);

    useEffect(() => {
        fetchArticles();
    }, [selectedSections]);

    const renderSections = () => {
        return (
            sections &&
            sections.length > 0 &&
            selectedCategory?.id &&
            sections.map((section, index) => {
                if (selectedCategory.id === section.category_id) {
                    return (
                        <BlockStack gap="200" key={index}>
                            <Text as="p" tone="subdued">
                                {section.name}
                            </Text>
                            <List type="bullet" gap="loose">
                                {renderArticles(section.id)}
                            </List>
                        </BlockStack>
                    );
                }
            })
        );
    };

    const renderArticles = (sectionId: number) => {
        return (
            articles &&
            articles.length > 0 &&
            articles.map((article) => {
                if (sectionId === article.section_id && !article.draft)
                    return (
                        <div key={article.id}>
                            <List.Item key={article.id}>
                                <Button
                                    variant="tertiary"
                                    url={article.html_url}
                                >
                                    {article.title}
                                </Button>
                            </List.Item>
                        </div>
                    );
            })
        );
    };

    const renderButton = (category: CategoryTypes) => {
        return (
            <Box
                key={category.id}
                borderColor="border-success"
                borderRadius="200"
                borderWidth={
                    category?.id === selectedCategory?.id ? '050' : '0'
                }
            >
                <BlockStack key={category.id}>
                    <Button
                        size="large"
                        fullWidth
                        onClick={() => setSelectedCategory(category)}
                    >
                        {category.name}
                    </Button>
                </BlockStack>
            </Box>
        );
    };
    return (
        <Box width="100%" paddingBlockStart="600">
            {isLoading ? (
                <SkeletonBodyText />
            ) : (
                <BlockStack gap="400">
                    <InlineGrid
                        gap="400"
                        columns={shopify.environment.mobile ? 2 : 4}
                    >
                        {categories &&
                            categories.length > 0 &&
                            categories.map((categorie) =>
                                renderButton(categorie),
                            )}
                    </InlineGrid>
                    {selectedCategory && selectedCategory.id && (
                        <Card>
                            <BlockStack gap="800">
                                {renderSections()}
                            </BlockStack>
                        </Card>
                    )}
                </BlockStack>
            )}
        </Box>
    );
};

export default HelpCenterComponent;
