import { BaseLayout } from "./Layout/BaseLayout";
import { BlockStack, Box, Button, Divider, InlineStack, Link, Spinner, Text, TextField } from "@shopify/polaris";
import CreateAccountLayout from "./Layout/CreateAccountLayout";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { analyticsEventStore, shopStore, videoLiveSellingStore } from "@/stores";
import { useSnapshot } from "valtio";
import { getSanitizedPhoneNumber } from "@/utils/sanitize";
import { useVFSAuthFetch } from "@/hooks/useAuthenticatedFetch";
import { vfsRoutes } from "@/constants/routes.constants";
import '../../assets/css/AnimatedCheckmark.css';
import { isQaEnv } from "@/utils/helpers";

export default function VerifyOTPPage() {
    const navigate = useNavigate();
    const vfsAuthFetch = useVFSAuthFetch();
    const { confirmationCode, confirmationCodeErr } = useSnapshot(
        videoLiveSellingStore,
        { sync: true },
    );

    const [showTimer, setShowTimer] = useState(false);
    const [countdownTime, setCountdownTime] = useState(0);
    const [apiLoading, setApiLoading] = useState(false);
    const [isInvalidCode, setIsInvalidCode] = useState('');
    const [accountCreateSuccess, setAccountCreateSuccess] = useState(false);

    useEffect(() => {
        videoLiveSellingStore.confirmationCode = '';
        videoLiveSellingStore.confirmationCodeErr = '';

        if (!videoLiveSellingStore.userAuth) {
            shopify.toast.show('Something went wrong while trying to create account. Please try again.',
                {
                    isError: true,
                    duration: 5000,
                },
            );
            console.error('User Auth not found');
            navigate(vfsRoutes.createAccount);
        } else {
            startCountdownTimer();
            const handleBeforeUnload = (e: BeforeUnloadEvent) => {
                e.preventDefault();
            };
            window.addEventListener('beforeunload', handleBeforeUnload);
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }
    }, []);

    useEffect(() => {
        if (confirmationCode.length === 6) verifyFireabaseOTP();
    }, [confirmationCode]);

    const handleChange = (value: string) => {
        setIsInvalidCode('');
        if (value.length < 7) videoLiveSellingStore.setConfiramtionCode(value);
        if (videoLiveSellingStore.confirmationCodeErr != '') return;
    };

    const verifyFireabaseOTP = async () => {
        if (!videoLiveSellingStore.userAuth) return;

        try {
            setApiLoading(true);
            const userAuthDetails = await videoLiveSellingStore.userAuth.confirm(confirmationCode);
            videoLiveSellingStore.userAuthDetails = userAuthDetails;
            shopStore.popshopliveSellerId = videoLiveSellingStore.userAuthDetails?.user.uid;

            try {
                if (!isQaEnv) {
                    await vfsAuthFetch('/app-config/metadata/seed');
                }
                await startCreatingPSLAccount();
                analyticsEventStore.accountVerificationOtpConfirmation();
                handleAccountCompletion();
            } catch (error) {
                shopify.toast.show(
                    'An error occured during account creation. Please contact support.',
                    {
                        isError: true,
                        duration: 5000,
                    },
                );
            }
        } catch (error) {
            let errorMessage =
                'Something went wrong while trying to verify OTP.';

            switch (error.code) {
                case 'auth/invalid-verification-code':
                    errorMessage = 'Invalid confirmation code';
                    break;
                case 'auth/code-expired':
                    errorMessage = 'Confirmation code has expired';
                    break;
            }

            setIsInvalidCode(errorMessage);
            console.error(error);
            setApiLoading(false);
        }
    };

    const resendFirebaseOTP = async () => {
        const { countryCode, phoneNumber } = videoLiveSellingStore;
        shopify.toast.show(
            'Re-sending new OTP to phone number. Please wait...',
            {
                isError: false,
                duration: 5000,
            },
        );

        try {
            const firebaseAuth = await shopStore.getFirebaseAuth();
            if (!firebaseAuth) return;

            const { RecaptchaVerifier, signInWithPhoneNumber } = await import(
                'firebase/auth'
            );
            const recaptchaInstance = new RecaptchaVerifier(
                firebaseAuth,
                'recaptcha-container',
                { size: 'invisible' },
            );

            videoLiveSellingStore.userAuth = await signInWithPhoneNumber(
                firebaseAuth,
                getSanitizedPhoneNumber(`${countryCode}${phoneNumber}`),
                recaptchaInstance,
            );

            startCountdownTimer();
        } catch (error) {
            let errorMessage = 'Something went wrong while trying to re-send OTP.';

            switch (error.code) {
                case 'auth/invalid-phone-number':
                    errorMessage = 'Invalid phone number.';
                    break;
                case 'auth/too-many-requests':
                    errorMessage =
                        'You have reached the maximum number of OTP requests. Please try again later.';
                    break;
            }

            shopify.toast.show(errorMessage, { isError: true, duration: 5000 });
            console.error(error);
        }
    };

    const startCountdownTimer = useCallback(() => {
        setShowTimer(true);
        setCountdownTime(60);
        let countdownInterval = setInterval(() => {
            setCountdownTime((prev) => {
                if (prev === 0) {
                    clearInterval(countdownInterval);
                    setShowTimer(false);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
    }, []);

    const startCreatingPSLAccount = async () => {
        const firebaseUID = shopStore.popshopliveSellerId;
        const ownerName = shopStore.ownerName;
        const firstName = ownerName.split(' ')[0];
        const lastName = ownerName.split(' ')[1] ?? '';
        const { countryCode, phoneNumber, email } = videoLiveSellingStore;

        try {
            const response = await fetch(
                `${shopStore.config?.legacyPopclipsSignup.apiUrl}/pslSellerAccountCreator`,
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        firstName: firstName,
                        lastName: lastName,
                        phoneNumber: getSanitizedPhoneNumber(
                            `${countryCode}${phoneNumber}`,
                        ),
                        username: firebaseUID?.substring(0, 8),
                        email: email,
                        bio: firebaseUID?.substring(0, 6),
                        avatar: 'https://placehold.co/120x120',
                        uid: firebaseUID,
                        shopifyDomain: shopStore.shopifyDomain,
                        store: {
                            passcode: '',
                            storeName: shopStore.shopHandle,
                            sellerAddress: {
                                city: '',
                                line1: '',
                                postalCode: '',
                                primaryPhone: '',
                                state: '',
                                country: '',
                                line2: '',
                            },
                        },
                    }),
                },
            );

            if (!response.ok) {
                throw new Error('Failed to create PSL seller account.');
            }
        } catch (error) {
            shopify.toast.show(
                'Failed to create popshop.live account. Please contact support.',
                {
                    isError: true,
                    duration: 5000,
                },
            );
            console.error(error);

            navigate(vfsRoutes.createAccount);
        } finally {
            setApiLoading(false);
        }
    };

    const handleAccountCompletion = () => {
        setAccountCreateSuccess(true);

        setTimeout(() => {
            shopStore.popshopliveAccountCreated = true;
        }, 2000);
    }
    
    return (
        <BaseLayout title="Verify OTP" isLoading={false}>
            <CreateAccountLayout
                cssStyle={
                    accountCreateSuccess
                        ? { marginTop: '2rem', marginBottom: '7rem' }
                        : { marginBottom: '5rem', marginTop: '2rem' }
                }
            >
                {accountCreateSuccess ? (
                    <AccountCreateSuccess />
                ) : (
                    <BlockStack gap="400">
                        <h4 className="card-body-heading">
                            Congratulations and Welcome to the Videeo Family!
                        </h4>
                        {/* <p className="card-body-subheading">
                            Enter your email and verify your phone number to
                            create your account and unlock all the powerful
                            features of our mobile video commerce platform.
                        </p> */}
                        <BlockStack gap="200">
                            <Text as="p" fontWeight="bold" alignment="center">
                                Verify a One Time Password (OTP)
                            </Text>
                            <InlineStack gap="400">
                                <Text as="p" fontWeight="semibold">
                                    <Text
                                        as="span"
                                        tone="subdued"
                                        fontWeight="regular"
                                    >
                                        We've sent a OTP to
                                    </Text>{' '}
                                    {videoLiveSellingStore.countryCode}{' '}
                                    {videoLiveSellingStore.phoneNumber}
                                </Text>
                                <Link
                                    url={vfsRoutes.createAccount}
                                    removeUnderline
                                >
                                    <Text as="span" fontWeight="bold">
                                        Change
                                    </Text>
                                </Link>
                            </InlineStack>
                        </BlockStack>
                        <TextField
                            label="Enter OTP"
                            type="number"
                            helpText={
                                <Box paddingBlockStart="200">
                                    {showTimer ? (
                                        <Text
                                            as="p"
                                            fontWeight="semibold"
                                            tone="subdued"
                                        >
                                            Resend OTP in 00:
                                            {countdownTime
                                                .toString()
                                                .padStart(2, '0')}{' '}
                                            Seconds
                                        </Text>
                                    ) : (
                                        <Link
                                            onClick={resendFirebaseOTP}
                                            removeUnderline
                                        >
                                            <Text
                                                as="span"
                                                fontWeight="semibold"
                                                tone="magic"
                                            >
                                                Resend OTP
                                            </Text>
                                        </Link>
                                    )}
                                </Box>
                            }
                            autoComplete="off"
                            value={confirmationCode}
                            onChange={handleChange}
                            onFocus={() => {
                                videoLiveSellingStore.confirmationCodeErr = '';
                            }}
                            error={confirmationCodeErr || isInvalidCode}
                            readOnly={apiLoading}
                        />
                        <Divider />
                        <InlineStack blockAlign="center" align="space-between">
                            <Box
                                id="recaptcha-container"
                                paddingBlockStart="200"
                            ></Box>
                            <InlineStack gap="400" blockAlign="center">
                                {apiLoading ? (
                                    <Spinner size="small" />
                                ) : (
                                    <Button
                                        variant="primary"
                                        onClick={verifyFireabaseOTP}
                                        disabled={
                                            confirmationCode.length < 6 ||
                                            apiLoading
                                        }
                                    >
                                        Verify
                                    </Button>
                                )}
                            </InlineStack>
                        </InlineStack>
                    </BlockStack>
                )}
            </CreateAccountLayout>
        </BaseLayout>
    );
}

const AccountCreateSuccess = () => {
    return (
        <div className="wrapper">
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
        </div>
    )
}