import { useState, useEffect } from 'react';
import { BlockStack, Box, InlineStack, Button, Spinner, Text } from '@shopify/polaris';
import {
    ProductListCard,
    ClipsFormCard,
    VideoPreview,
} from '@/components/popclips';
import { useNavigate, useParams } from 'react-router-dom';
import { popclipsRoutes } from '@/constants/routes.constants';
import { analyticsEventStore, shopStore, videoLiveSellingStore } from '@/stores';
import { useUploadClip } from '@/hooks/useUploadClip';
import { useUpdateClip } from '@/hooks/useUpdateClip';
import { useSnapshot } from 'valtio';
import PopClipsCard from '@/components/common/PopClipsCard';
import { BaseLayout } from '../../v2/pages/Layout/BaseLayout';
import { useMediaGalleryAuthFetch } from '@/hooks/useAuthenticatedFetch';
import { areArraysEqual } from '@/utils/helpers';
import SocialMediaPlanUpdateModal from './SocialMediaPlanUpdateModal';
import { VfsButton } from '@/components/common/v1';
import { PlanUpgradeAlertModal } from '@/components/common/PlanUpgradeAlertModal';
import { POPCLIPS } from '@/constants/popclipsFeature.constants';
import VfsModal from '../../v2/Components/VfsModal';
import { featuredPopclipImg } from '../../assets/images';
import PopclipsMiniplayerPositioning from '@/components/popclips/popclips-grid-components/PopclipsMiniplayerPositioning';
type CreatePopClipPageProps = {
    editMode?: boolean;
};

const CreatePopClipPage = ({ editMode }: CreatePopClipPageProps) => {
    const param = useParams();
    const navigate = useNavigate();
    const { uploadClip } = useUploadClip();
    const { updateClip } = useUpdateClip();
    const mgFetch = useMediaGalleryAuthFetch();
    const gatingData = shopStore.featureGatingData?.popclips;
    const { getStartedStatus, featuredPopclipId } = useSnapshot(shopStore);

    const {
        editClipData,
        savingPopclip,
        clipTitle,
        clipSourceUrl,
        clipSourceType,
        shopifySelectedProducts,
        isClipExcluded,
        videoUrl,
        isFeatured,
        showPopclipsPositionModal
    } = useSnapshot(videoLiveSellingStore);
    const [isApiLoading, setIsApiLoading] = useState(false);
    const [showSocialPlanUpdate,setShowSocialPlanUpdate]=useState(false);
    const [showFeaturePopclipIntro, setShowFeaturePopclipIntro] = useState(false);
    const [showOverrideFeaturePopclip, setShowOverrideFeaturePopclip] = useState(false);
    const [socialButtonType,setSocialButtonType]=useState('');
    const [showPlanUpgradeModal, setShowPlanUpgradeModal] = useState(false);
    const [showWaterMark, setShowWaterMark] = useState(false);
    const [showFirstPopclipCreateSuccessModal, setShowFirstPopclipCreateSuccessModal] = useState(false);

    useEffect(() => {
        const addonPrices = shopStore.getActivePlanAddonPrices(
            POPCLIPS,
            true,
        );
        const maxPrice = Math.max(...addonPrices);
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            e.preventDefault();
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        (async () => {
            if (editMode) {
                setIsApiLoading(true);

                const result = await mgFetch('/listMediabyTypeAndMetaId', {
                    method: 'POST',
                    body: JSON.stringify({
                        fileCategory: 'shopifyClips',
                        metaIds: [param.id],
                        sellerId: shopStore.popshopliveSellerId
                    }),
                });

                if (!result.data || result.data.length === 0) {
                    shopify.toast.show('Clip not found.', {
                        isError: true,
                        duration: 5000,
                    });
                    navigate(popclipsRoutes.list);
                    return;
                }

                const clipFound = result.data[0];
                analyticsEventStore.popclipClickEditButton(clipFound.metaIdentifier);

                videoLiveSellingStore.editClipData = clipFound;
                videoLiveSellingStore.clipTitle = clipFound.metaData.videoTitle;
                videoLiveSellingStore.videoUrl = `${clipFound.cdnUrl}${clipFound.metaData.processedURL}`;
                videoLiveSellingStore.clipSourceUrl = clipFound.metaData?.clipSourceUrl || '';
                videoLiveSellingStore.clipSourceType = clipFound.metaData?.clipSourceType || 'device';
                videoLiveSellingStore.isFeatured = videoLiveSellingStore.isFeaturedPopclip(clipFound.metaIdentifier);
                videoLiveSellingStore.isClipExcluded = clipFound.metaData.hasOwnProperty('display') && clipFound.metaData.display.length === 0;
                videoLiveSellingStore.videoThumbnailImage = clipFound?.metaData?.customThumbnailImageURL 
                    ? `${clipFound.cdnUrl}${clipFound.metaData.customThumbnailImageURL}` 
                    : clipFound?.metaData?.defaultThumbnailImageURL 
                        ? `${clipFound.cdnUrl}${clipFound.metaData.defaultThumbnailImageURL}`
                        : '';
                    
                setIsApiLoading(false);
            }
        })();
        setShowWaterMark(maxPrice < 149.99)
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
     useEffect(() => {
         if (gatingData?.maxViews != '-1' && gatingData?.limitAccess) {
             navigate(popclipsRoutes.list);
         }
     }, []);

    const handleSaveClick = async () => {
        if (videoLiveSellingStore.clipTitle.trim() == '') {
            shopify.toast.show('Clip Title cannot be empty.', {
                isError: true,
                duration: 5000,
            });
            videoLiveSellingStore.hasClipTitleErr();
            return;
        }
        if (!videoLiveSellingStore.videoUrl) {
            shopify.toast.show('Please add a video to create a new popclip.', {
                isError: true,
                duration: 5000,
            });
            return;
        }
        if (videoLiveSellingStore.shopifySelectedProducts.length == 0) {
            shopify.toast.show(
                'Please select at least one product to create a new popclip.',
                {
                    isError: true,
                    duration: 5000,
                },
            );
            return;
        }
        if (!videoLiveSellingStore.videoThumbnailImage) {
            shopify.toast.show(
                'Please select a custom thumbnail image to create a new popclip.',
                {
                    isError: true,
                    duration: 5000,
                },
            );
            return;
        }
        const shouldShowOverrideModal = !editMode ? featuredPopclipId?.length > 0 : featuredPopclipId?.length > 0 && featuredPopclipId !== editClipData?.metaIdentifier;
        if (shouldShowOverrideModal && isFeatured) {
            setShowOverrideFeaturePopclip(true);
            return;
        }

        await savePopClip();
    };

    const savePopClip = async () => {
        try {
            setShowOverrideFeaturePopclip(false);
            videoLiveSellingStore.savingPopclip = true;
            setIsApiLoading(true);
            const startTime = new Date().getTime();
            
            const { data } = await (!editMode ? handleCreateNewPopclips() : updateClip());
            const popclipId = editMode ? data?.item?.metaIdentifier : data?.clip?.metaIdentifier;

            const isUnFeaturingPopclip = editMode ? featuredPopclipId == popclipId && !isFeatured : false;
            if (isUnFeaturingPopclip) {
                await videoLiveSellingStore.saveFeaturePopclips('');
                shopStore.featuredPopclipId = '';
            } else if (isFeatured) {
                await videoLiveSellingStore.saveFeaturePopclips(popclipId);
                shopStore.featuredPopclipId = popclipId;
            }

            const endTime = new Date().getTime();
            const timeTaken = endTime - startTime;
            if (!data) throw new Error('Error in saving popclip');

            handleEventCalls(data?.item, timeTaken, popclipId);

            if (!getStartedStatus.events.STEP_ADD_YOUR_FIRST_POPCLIP) {
                setShowFirstPopclipCreateSuccessModal(true);
                shopStore.updateGetStartedStatus('STEP_ADD_YOUR_FIRST_POPCLIP');
            } else {
                goToGridPage(!editMode);
            }
        } catch (error) {
            console.log(error);
            shopify.toast.show(
                'Something went wrong while trying to upload clip. Please contact support.',
                {
                    isError: true,
                    duration: 5000,
                },
            );
        } finally {
            videoLiveSellingStore.savingPopclip = false;
            setIsApiLoading(false);
        }
    };

    const handleCreateNewPopclips = async () => {
        const { dropzoneFile, videoThumbnailFormData } = videoLiveSellingStore;
        if (dropzoneFile && dropzoneFile.get('file') && videoThumbnailFormData && videoThumbnailFormData.get('file')) {
            const file = dropzoneFile.get('file') as File;
            const thumbnailFile = videoThumbnailFormData?.get('file') as File;
            const response = await uploadClip({ fileName: file.name, customThumbnailImageName: thumbnailFile.name });

            if (!response.data) return;
            const { uploadFileUrl, clip, uploadCustomThumbnailUrl } = response.data;
            const clipPublicURL = `${clip.cdnUrl}${clip.metaData.videoURL}`;

            const isVideoStored = await storeVideoInAWS(uploadFileUrl, file);
            const isThumbnailImageStored = await storeVideoInAWS(uploadCustomThumbnailUrl,thumbnailFile);

            if (!isVideoStored && !isThumbnailImageStored) return;
            videoLiveSellingStore.setVideoUrl(clipPublicURL);

            return response;
        }

        return;
    };

    const goToGridPage = (goToFirstPage: boolean) => {
        if (goToFirstPage) {
            shopify.toast.show(
                'Your video is processing, please check back after some time',
                {
                    isError: false,
                    duration: 8000,
                },
            );
        }

        videoLiveSellingStore.editClipData = null;
        videoLiveSellingStore.dropzoneFile = null;

        setTimeout(() => {
            navigate(popclipsRoutes.list, { state: { goToFirstPage }});
        }, 300);
    };

    const handleEventCalls = (responseData, timeTaken, popclipId) => {
        analyticsEventStore.popclipsUploadedAppEvent(responseData);
        const selectedProductIds = shopifySelectedProducts.map(({id}) => id);

        if (!editMode) {
            analyticsEventStore.popclipUploadTriggered(false, {
                duration: Math.floor(timeTaken / 1000),
                popclipsID: popclipId,
                source: clipSourceType,
                sourceReelUrl: clipSourceUrl,
                newPopclipTitle: clipTitle,
                newProductList: selectedProductIds,
                newPDPVisibility: isClipExcluded,
                isFeaturedPopclip: isFeatured,
            });
        } else {
            const productsIds = editClipData?.metaData.products.map(({ shopifyProductId }) => shopifyProductId) || [];
            const previousPdpVisibility = editClipData ? editClipData.metaData.hasOwnProperty('display') && editClipData.metaData.display.length === 0 : false;

            analyticsEventStore.popclipEditExistingPopclip(true, {
                duration: Math.floor(timeTaken / 1000),
                popclipsID: popclipId,
                source: clipSourceType,
                sourceReelUrl: clipSourceUrl,
                popclipTitleUpdated:
                    editClipData?.metaData.videoTitle != clipTitle,
                previousPopclipTitle: editClipData?.metaData.videoTitle,
                newPopclipTitle: clipTitle,
                productListUpdated: !areArraysEqual(
                    selectedProductIds,
                    productsIds,
                ),
                previousProductList: productsIds,
                newProductList: selectedProductIds,
                pdpVisibilityUpdated: previousPdpVisibility.toString() != isClipExcluded.toString(),
                previousPdpVisibility,
                newPdpVisibility: isClipExcluded,
                isFeaturedPopclip: isFeatured,
            });
        }
    };

    return (
        <BaseLayout
            title={
                editMode
                    ? 'Edit Popclip (Shoppable Video)'
                    : 'Create New Popclip (Shoppable Video)'
            }
            headerAction={
                <InlineStack gap="300" align="center" blockAlign="center">
                    {isApiLoading && <Spinner size="small" />}
                    <Button
                        variant="primary"
                        submit
                        onClick={handleSaveClick}
                        disabled={isApiLoading || savingPopclip}
                    >
                        Save
                    </Button>
                </InlineStack>
            }
            backToLink={popclipsRoutes.list}
            disabledBackButton={savingPopclip}
        >
            <InlineStack gap="400" wrap={false}>
                <Box minWidth="792px">
                    <BlockStack gap="400">
                        <ClipsFormCard
                            isEditMode={editMode}
                            setShowSocialPlanUpdate={setShowSocialPlanUpdate}
                            setShowFeaturePopclipIntro={setShowFeaturePopclipIntro}
                            setSocialButtonType={setSocialButtonType}
                        />
                        <PopClipsCard>
                            {editMode && !editClipData ? (
                                <InlineStack
                                    gap="200"
                                    align="center"
                                    blockAlign="center"
                                >
                                    <Spinner size="small" />
                                    <span>Loading...</span>
                                </InlineStack>
                            ) : (
                                <ProductListCard />
                            )}
                        </PopClipsCard>
                    </BlockStack>
                </Box>
                <div style={{ position: 'relative'}}>
                    <PopClipsCard>
                        <VideoPreview isEditMode={editMode} />
                    </PopClipsCard>
                    {showWaterMark && videoUrl && videoUrl.trim() != '' && 
                    <div style={{ position: 'absolute', textAlign: 'center', width: '100%', top: '16px' }}>
                        <div style={{color: '#fff', padding: '8px', fontSize: '16px'}}>
                            Powered by Videeo.live
                        </div>
                        <VfsButton
                            variant='secondary'
                            disabled={savingPopclip}
                            onClick={() => setShowPlanUpgradeModal(true)}
                        >
                            Remove Watermark?
                        </VfsButton>
                    </div>}
                </div>
            </InlineStack>
            {showSocialPlanUpdate && <SocialMediaPlanUpdateModal setShowSocialPlanUpdate={setShowSocialPlanUpdate} socialButtonType={socialButtonType}/>}
            {showPlanUpgradeModal && 
                <PlanUpgradeAlertModal
                    isRemoveWatermarkBanner={true}
                    showPlanUpgradeModal={showPlanUpgradeModal}
                    handleCloseModal={() => setShowPlanUpgradeModal(false)}
                />
            }
            {showOverrideFeaturePopclip && (
                <VfsModal
                    title=" "
                    modalWidth="40rem"
                    closeable={false}
                    crossIcon={false}
                    onCloseModal={() => setShowOverrideFeaturePopclip(false)}
                    primaryAction={savePopClip}
                    primaryActionText="Yes, I confirm"
                    secondaryActionText="Cancel"
                    secondaryAction={() => {
                        videoLiveSellingStore.isFeatured = false;
                        setShowOverrideFeaturePopclip(false);
                    }}
                >
                    <Box paddingBlockEnd="500">
                        <BlockStack gap="300">
                            <Text as='h3' variant='headingLg' alignment='center'>Do you want to override the current featured popclip?</Text>
                            <Text as='p' variant='bodyMd' alignment='center'>This action will replace the existing Popclip with the selected one.</Text>
                        </BlockStack>
                    </Box>
                </VfsModal>
            )}
            {showFeaturePopclipIntro && (
                <VfsModal
                    title="Feature Popclip"
                    modalWidth="35rem"
                    closeable={true}
                    crossIcon={true}
                    onCloseModal={() => setShowFeaturePopclipIntro(false)}
                    primaryAction={() => setShowFeaturePopclipIntro(false)}
                    primaryActionText="Done"
                >
                    <BlockStack gap="300">
                        <img src={featuredPopclipImg} alt="Feature Popclip" />
                        <Box paddingBlockStart="400">
                            <Text as='h3' variant='headingLg' alignment='center'>Catch their eye with a Featured Popclip - Your spotlight moment!</Text>
                        </Box>
                    </BlockStack>
                </VfsModal>
            )}
            {showFirstPopclipCreateSuccessModal && (
                <VfsModal
                    title="Congrats on Uploading Your First Popclip!"
                    modalWidth="40rem"
                    closeable={false}
                    crossIcon={false}
                    onCloseModal={() => setShowFirstPopclipCreateSuccessModal(false)}
                    primaryAction={() => {
                        analyticsEventStore.popclipClickCreateButton();
                        videoLiveSellingStore.resetCreatePopClip();
                        setShowFirstPopclipCreateSuccessModal(false)
                    }}
                    primaryActionText="Upload Another Popclip"
                    secondaryActionText='Go to Popclips List'
                    secondaryAction={() => {
                        setShowFirstPopclipCreateSuccessModal(false);
                        goToGridPage(true);
                    }}
                >
                    <Box paddingBlockEnd="500" paddingInline="500">
                        <BlockStack gap="300">
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <Text as='h3' variant='headingLg' alignment='center'>Keep going!</Text>
                            <Text as='h5' variant='headingMd' alignment='center'>You're on your way to engaging your audience with Popclips</Text>
                            <Text as='h5' variant='headingMd' alignment='center'>Upload another to keep the momentum</Text>
                        </BlockStack>
                    </Box>
                </VfsModal>
            )}
            {showPopclipsPositionModal && 
                <PopclipsMiniplayerPositioning
                    showModal={showPopclipsPositionModal}
                    onCloseModal={() => videoLiveSellingStore.showPopclipsPositionModal = false}
                />
            }
        </BaseLayout>
    );
};

async function storeVideoInAWS(
    signedlink: string,
    videoFile: File,
): Promise<boolean> {
    try {
        const dataAfterUpload = await fetch(signedlink, {
            method: 'PUT',
            headers: { 'Content-Type': videoFile.type },
            body: videoFile,
        });

        return dataAfterUpload && dataAfterUpload.status == 200;
    } catch (error) {
        console.error('Error in uploadURL', error);
        return false;
    }
}

export default CreatePopClipPage;
