import { VfsButton } from '@/components/common/v1';
import { vfsRoutes } from '@/constants/routes.constants';
import { popclipsStore, shopStore } from '@/stores';
import { ListMediaByIdResponse } from '@/types/ListMediaByIdResponse';
import {
    Box,
    Button,
    InlineStack,
    SkeletonBodyText,
    Text,
} from '@shopify/polaris';
import { ClipboardIcon } from '@shopify/polaris-icons';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const PopclipSocialMediaShareButtons = ({
    clipData,
    setIsShareLinkModalOpen,
}: {
    clipData: ListMediaByIdResponse;
}) => {
    const [isCopiedShareLink, setIsCopiedShareLink] = useState(false);
    const [isLinkGenerated, setIsLinkGenerated] = useState(false);
    const [isApiLoading, setIsApiLoading] = useState(true);
    const [enableGenerateLinkButton, setEnableGenerateLinkButton] =
        useState(false);

    const navigate = useNavigate();
    const shareUrl = `https://${shopStore.shopifyDomain}/${
        clipData?.metaIdentifier ? '?meta_ids=' + clipData?.metaIdentifier : ''
    }`;

    const handleCopyButtonClicked = async () => {
        try {
            await navigator.clipboard.writeText(shareUrl);
            setIsCopiedShareLink(true);
            setTimeout(() => setIsCopiedShareLink(false), 2000);
        } catch (err) {
            shopify.toast.show('Failed to copy link', {
                isError: true,
                duration: 5000,
            });
        }
    };

    const fectSharedPopclipsData = async () => {
        setIsApiLoading(true);
        const data = await popclipsStore.getSharedPopclipsMetadata();

        setIsLinkGenerated(
            data.clips_shared.includes(clipData?.metaIdentifier),
        );
        const canGenerateLink =
            typeof data.max_share == 'number'
                ? data.total_share < data.max_share
                : data.max_share == 'Unlimited';
        setEnableGenerateLinkButton(canGenerateLink);
        setIsApiLoading(false);
    };

    const generateShareLink = async () => {
        setIsApiLoading(true);
        const response = await popclipsStore.generateShareLink(
            clipData?.metaIdentifier,
        );
        setIsLinkGenerated(response);
        setIsApiLoading(false);
    };

    useEffect(() => {
        fectSharedPopclipsData();
    }, []);

    return (
        <div style={{ width: '100%'}}>
            {isApiLoading ? (
                <SkeletonBodyText />
            ) : enableGenerateLinkButton || isLinkGenerated ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '28px',
                    }}
                >
                    <Text as="p" variant="bodyLg">
                        Boost customer engagement by sharing your Popclip link
                        anywhere – effortlessly showcase your content directly
                        on the website feed and captivate your audience!
                    </Text>
                    {isLinkGenerated ? (
                        <InlineStack
                            gap="200"
                            align="space-between"
                            wrap={false}
                        >
                            <Box
                                borderWidth="050"
                                borderRadius="100"
                                borderColor="border-magic"
                                background="bg-fill-magic-secondary"
                                padding="100"
                                paddingInline="200"
                                minWidth="31rem"
                                color="text-link"
                            >
                                <Text
                                    as="p"
                                    variant="bodyMd"
                                    fontWeight="semibold"
                                >
                                    {shareUrl}
                                </Text>
                            </Box>
                            <Button
                                size="micro"
                                icon={ClipboardIcon}
                                onClick={() => handleCopyButtonClicked()}
                            >
                                {isCopiedShareLink ? 'Copied' : 'Copy'}
                            </Button>
                        </InlineStack>
                    ) : (
                        <VfsButton
                            onClick={() => generateShareLink()}
                        >
                            Generate link
                        </VfsButton>
                    )}
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '28px',
                    }}
                >
                    <Text as="p" variant="bodyLg">
                        Oops! You've reached the maximum limit of 5 Popclip
                        shares for this month. Please try again next month, or
                        upgrade now to unlock unlimited sharing!
                    </Text>
                    <div className="vfsModal__footer">
                        <button
                            className="vfsModal__secondary__button"
                            onClick={() => setIsShareLinkModalOpen()}
                        >
                            Continue with Free Plan
                        </button>

                        <button
                            className={`vfsModal__success__button`}
                            onClick={() => navigate(vfsRoutes.managePlans)}
                        >
                            Upgrade Now
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PopclipSocialMediaShareButtons;
