import { shopStore } from "@/stores";
import { BlockStack, ProgressBar, Text } from "@shopify/polaris";
import { useSnapshot } from "valtio";

export default function OnboardingWizardHeader() {
    const { progressPercentage } = useSnapshot(shopStore);
    
    return (
        <div className="onboarding-wizard-modal__header">
            <BlockStack gap="500">
                <h1>Get started with Videeo</h1>
                <BlockStack gap="200">
                    <ProgressBar
                        progress={progressPercentage}
                        size="small"
                        animated
                        tone="highlight"
                    />
                    <Text as="p" tone="subdued">
                        {progressPercentage}% Complete
                    </Text>
                </BlockStack>
            </BlockStack>
        </div>
    );
}