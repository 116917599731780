import React, { useState, useRef, useEffect, useCallback } from 'react';
import browserView from '../../../assets/images/browserView.svg';
import OnlineStoreBrowserView from '../../../assets/images/onlineStoreBrowserView.svg';
import { popclipsMobileView } from '../../../assets/images';

const WEB_BOX_SIZE = { width: 78, height: 112 };
const WEB_CONTAINER_SIZE = { width: 572, height: 265 };
const MOBILE_BOX_SIZE = { width: 57, height: 81 };
const MOBILE_CONTAINER_SIZE = { width: 162, height: 330 };

interface DraggableResizableBoxPropsTypes {
    isMovable: boolean;
    isFeaturedPopclips: boolean;
    coordinatesPoints: { x: number; y: number };
    isMobileView: boolean;
    handlePositionChanged: (position: { x: number; y: number }) => void;
}

const DraggableResizableBox = ({
    isMovable = false,
    isFeaturedPopclips = false,
    coordinatesPoints = { x: 86, y: 57 },
    isMobileView = true,
    handlePositionChanged,
}: DraggableResizableBoxPropsTypes) => {
    const [position, setPosition] = useState<{ x: number; y: number }>(coordinatesPoints);
    const [isMoving, setIsMoving] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const isDragging = useRef(false);
    const startCoords = useRef({ x: 0, y: 0 });
    const [boxSize, setBoxSize] = useState(isMobileView ? MOBILE_BOX_SIZE : WEB_BOX_SIZE);
    const [containerArea, setContainerArea] = useState(isMobileView ? MOBILE_CONTAINER_SIZE : WEB_CONTAINER_SIZE);

    const handleMouseMove = useCallback(
        (e: MouseEvent) => {
            if (!isDragging.current) return;

            const dx = e.clientX - startCoords.current.x;
            const dy = e.clientY - startCoords.current.y;
            const { width: containerWidth, height: containerHeight } = containerArea;

            setPosition((prev) => {
                const newX = Math.min(
                    Math.max(prev.x + (dx / containerWidth) * 100, 0),
                    100 - (boxSize.width / containerWidth) * 100
                );
                const newY = Math.min(
                    Math.max(prev.y + (dy / containerHeight) * 100, 0),
                    100 - (boxSize.height / containerHeight) * 100
                );
                return { x: newX, y: newY };
            });

            startCoords.current = { x: e.clientX, y: e.clientY };
        },
        [containerArea, boxSize]
    );

    const handleMouseUp = useCallback(() => {
        isDragging.current = false;
        setIsMoving(false);

        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    }, [handleMouseMove]);

    const handleMouseDown = useCallback(
        (e: React.MouseEvent<HTMLDivElement>, type: 'drag') => {
            e.preventDefault();
            if (!isMovable) return;
            isDragging.current = type === 'drag';
            startCoords.current = { x: e.clientX, y: e.clientY };
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
            setIsMoving(true);
        },
        [handleMouseMove, handleMouseUp, isMovable]
    );

    useEffect(() => {
        handlePositionChanged(position);
    }, [position]);

    useEffect(() => {
        setBoxSize(isMobileView ? MOBILE_BOX_SIZE : WEB_BOX_SIZE);
        setContainerArea(isMobileView ? MOBILE_CONTAINER_SIZE : WEB_CONTAINER_SIZE);
        setPosition(coordinatesPoints);
    }, [isMobileView, coordinatesPoints]);

    const imageSrc = isMobileView
        ? popclipsMobileView
        : isFeaturedPopclips
        ? browserView
        : OnlineStoreBrowserView;

    const boxStyle = {
        position: 'absolute',
        left: `${position.x}%`,
        top: `${position.y}%`,
        width: `${boxSize.width}px`,
        height: `${boxSize.height}px`,
        backgroundColor: isMoving ? '#fff' : '#6C63FF',
        borderRadius: '8px',
        color: '#fff',
        cursor: isMovable ? 'move' : 'not-allowed',
        padding: '4px',
        boxShadow: '0px 4px 4px 0px #00000040',
        border: isMoving ? '1px dashed #0035A8' : 'none',
    };

    return (
        <div style={{ position: 'relative', height: isMobileView ? '374px' : '400px' }}>
            <img style={{ position: 'absolute', top: 0, left: 0 }} src={imageSrc} />
            <div
                style={{
                    position: 'absolute',
                    top: isMobileView ? 'auto' : '98px',
                    bottom: isMobileView ? '8px' : 'auto',
                    left: isMobileView ? '8px' : '22px',
                    borderRadius: isMobileView ? '0px 0px 24px 24px' : '',
                }}
            >
                <div
                    ref={containerRef}
                    style={{
                        position: 'relative',
                        overflow: 'hidden',
                        background: isMoving ? 'rgba(81, 185, 255, 0.32)' : 'transparent',
                        border: isMoving ? '2px dashed #0035A8' : 'none',
                        ...containerArea,
                    }}
                >
                    <div
                        style={boxStyle}
                        onMouseDown={(e) => handleMouseDown(e, 'drag')}
                    >
                        <div
                            style={{
                                height: '100%',
                                display: 'flex',
                                gap: '12px',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: isMoving ? '#002CDE' : 'transparent',
                                borderRadius: isMoving ? '4px' : '',
                            }}
                        >
                            <svg
                                width="28"
                                height="29"
                                viewBox="0 0 28 29"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M14 0.182129C6.26955 0.182129 0 6.4527 0 14.1844C0 21.9161 6.26955 28.1865 14 28.1865C21.7304 28.1865 28 21.9161 28 14.1844C28 6.4527 21.7304 0.182129 14 0.182129ZM19.4834 15.4426L12.4326 19.9629C11.5348 20.6883 10.0891 19.9629 10.0891 18.7047V9.66915C10.0891 8.5987 11.5348 7.87322 12.4326 8.22329L19.4834 12.7436C20.3812 13.4691 20.3812 14.9149 19.4834 15.4527V15.4426Z"
                                    fill="white"
                                />
                            </svg>

                            <div
                                style={{
                                    fontSize: isMobileView ? '8px' : '12px',
                                    fontWeight: '650',
                                    lineHeight: '120%',
                                    letterSpacing: '0px',
                                    textAlign: 'center',
                                }}
                            >
                                {isFeaturedPopclips ? 'Featured Popclip' : 'Popclip Miniplayer'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DraggableResizableBox;
