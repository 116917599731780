import {
    BlockStack,
    Box,
    Card,
    InlineGrid,
    InlineStack,
    Text,
} from '@shopify/polaris';
import { Dispatch, SetStateAction } from 'react';
import { popclipsCarouselBanner } from '../../assets/images';
import { analyticsEventStore } from '@/stores';
import { VfsButton, VfsImageComponent } from './v1';

type FeatureReleaseCalloutCardProps = {
    setOpenPublishModal: () => void;
    setShowPlanUpgradeModal:Dispatch<SetStateAction<boolean>>;
};

const FeatureReleaseCalloutCard = ({
    setOpenPublishModal,
    setShowPlanUpgradeModal,
}: FeatureReleaseCalloutCardProps) => {

    const handleCreateAllPopclipsCarousel = async () => {
        setOpenPublishModal();
        analyticsEventStore.onCreateAllPopclipsCarousel(
            "",
            '//*[@id="app"]/div[1]/div/div/div[1]/div/div[2]/div/div/div[1]/div/div/div[1]/div[2]/div/button[1]',
        );
    };

    return (
        <Card background="bg-fill-info-secondary" padding="800">
            <InlineGrid columns={['twoThirds', 'oneThird']}>
                <BlockStack gap="400">
                    <Box
                        background="bg-fill-info"
                        borderRadius="full"
                        paddingInline="100"
                        width="9rem"
                    >
                        🎉 New Feature Alert
                    </Box>
                    <Text variant="headingMd" as="h6">
                        Presenting Popclips now on Website!!
                    </Text>
                    <Text as="p">
                        Join thousands of retailers who have garnered millions
                        of views with Popclips! Upload your Popclip today and
                        make your website a destination shoppers can't resist.
                    </Text>
                    <Box>
                        <InlineStack gap="600" blockAlign="center">
                            <VfsButton
                                padding='11px 12px'
                                onClick={handleCreateAllPopclipsCarousel}
                            >
                                Create All-Popclips Carousel
                            </VfsButton>
                            <VfsButton
                                padding='7px 10px'
                                onClick={() => setShowPlanUpgradeModal(true)}
                            >
                                <div
                                    className="__flex-center-align"
                                    style={{ gap: '4px'}}>
                                        <VfsImageComponent iconName="LockIcon" />
                                        <span>Create Custom Carousel</span>
                                </div>
                            </VfsButton>
                        </InlineStack>
                    </Box>
                </BlockStack>
                <BlockStack align="center" inlineAlign="center">
                    <img
                        style={{
                            height: '8rem',
                            objectFit: 'cover',
                            objectPosition: 'center',
                        }}
                        src={popclipsCarouselBanner}
                    />
                </BlockStack>
            </InlineGrid>
        </Card>
    );
};

export default FeatureReleaseCalloutCard;
